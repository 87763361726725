<template>
  <div class="page">
    <div class="myPage">
      <div class="container">
        <!-- {{TodoList 前回ログインはどこから取得？ マイページ }} -->
        <h2 class="title page__h1 d-none d-sm-block">マイページ</h2>
        <p class="myPage__lastLogin  d-none d-sm-block">
          前回ログイン日時：{{ data_obj.last_login_date }}
        </p>

        <div class="col-12 offset-0 mb-4" v-if="!isCurrentYearUpdated">
          <!-- {{m_relation_list}} -->
          <div class="w-100 py-3 mb-3 ff_najo_sans">
            <div class="row">
              <div class="col-10 offset-1 col-md-8 offset-md-2">
                <h5 class="title07 mb-24">{{ get_term_year }}年度継続申請のお願い</h5>
                <div class="box">
                  <div class="row">
                    <div class="">
                      <p class="mb-24 text-start">
                        現在{{ get_term_year }}年度のライセンス継続をされておりません。<br>
                        継続される場合は、以下のボタンから申請手続きを行って下さい。
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="d-grid">
                        <router-link class="button04" to="/request/license"
                          ><span class="button04__text">今年度の継続申請を行う</span
                          ><i class="button04__icon fa-solid fa-chevron-right"></i
                        ></router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 offset-0 mb-4" v-else-if="data_obj?.credit_alert_250310">
          <!-- {{m_relation_list}} -->
          <div class="w-100 py-3 mb-3 ff_najo_sans">
            <div class="row">
              <div class="col-10 offset-1 col-md-8 offset-md-2">
                <h5 class="title07 mb-24">【重要】お支払い手続きのお願い</h5>
                <div class="box">
                  <div class="row">
                    <div class="">
                      <p class="mb-24 text-start">
                        平素よりMFJ会員システムをご利用いただき、誠にありがとうございます。<br>
                        このたび、当システムのお支払い処理において不具合が発生し、ライセンスNo.{{data_obj?.license_no}} {{data_obj?.name_label}}様におかれましては、クレジットカードによるお支払いが正常に完了していないにもかかわらず、次の処理に進んでしまうという問題が発生いたしました。<br>
                        そのため、本来お支払いが完了しているべきにもかかわらず、実際にはお支払いがなされていない状況となっております。<br>
                        このたびのシステムの不具合により{{data_obj?.name_1}}様には大変ご迷惑をおかけしておりますことを、深くお詫び申し上げます。<br>
                        つきましては、お手数をおかけしますが、以下の手順に沿って改めてお支払い手続きをお願いいたします。<br>
                        以下の、「ライセンス継続/追加申請」ボタンをタップし、お支払い方法選択ページへお進み下さい。<br>
                        クレジットカードまたはコンビニ決済のいずれかをご選択いただき、お支払いを完了させてください。
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="d-grid">
                        <router-link class="button04" to="/request/license"
                          ><span class="button04__text">お支払い手続きを始める</span
                          ><i class="button04__icon fa-solid fa-chevron-right"></i
                        ></router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
        <!-- 公認クラブ -->
        <div class="col-12 offset-0 mb-4" v-if="m_valid_invitation_chk">
          <!-- {{m_relation_list}} -->
          <div
            class="w-100 py-3 mb-3 ff_najo_sans"
            v-if="m_valid_invitation_list?.length > 0"
          >
            <h5 class="title07 mb-2">公認クラブへの参加招待</h5>
            <div
              v-for="invitation in m_valid_invitation_list"
              :key="invitation"
              class="row mb-2 pb-2 border-bottom"
            >
              <div class="ff_najo_sans bs_fs_7 col-9 mb-2">
                {{ invitation.c_data.name_1 }}からの招待
              </div>
              <div class="col-3">
                <div class="d-grid">
                  <button @click="go_relation(invitation.rel_token)" class="button04">
                    承認・辞退の返答はこちら
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- {{ ライセンスカード }} -->
          <!-- {{ hasParentGuardian }}<hr>
          {{ (get_assets(150, 0) || {}) }} -->
        <!-- {{get_assets(400, 0).path}} -->
        <div v-if="license_now_sort.length > 0">
          <div
            class="mb-32"
            v-for="license in license_now_sort"
            :key="license.license_id"
          >
            <div
              class="licence mb-32"
              v-if="license.sel_disp"
              :style="{
                '--license-color': getLicenseColorFromCd(license.license_cd),
              }"
            >
              <div class="licence__header text-center mb-2">
                <div class="container">
                  <!-- ロゴを中央配置 -->
                  <div class="text-center mb-2">
                    <div class="licence__logo">
                      <img
                        :src="require('@/assets/img/header_img_01.png')"
                        alt="Motorcycle Federation of Japan"
                        class="mx-auto d-block"
                      />
                    </div>
                  </div>
                  <!-- {{ license }} -->
                  <!--  -->
                  <div class="d-block align-items-center">
                    <div class="licence__logoGroup">
                      <p class="licence__year">{{ license.license_year }}</p>
                      <h3 class="licence__year w-100">MFJ Licence</h3>
                    </div>
                  </div>
                  <!-- QRコードとライセンス情報を横並び -->
                  <!-- <div class="d-flex align-items-center">
                    <div class="licence__qr" v-if="false">
                      <vue-qrcode
                        v-if="data_obj.license_no"
                        :value="data_obj.license_no"
                        :options="qr_option"
                        tag="img"
                      ></vue-qrcode>
                    </div>

                    <div class="licence__logoGroup">
                      <p class="licence__year">2024</p>
                      <h3 class="licence__year w-100">MFJ Licence</h3>
                    </div>
                  </div> -->
                </div>
              </div>

              <div class="licence__content">
                <!-- 写真と情報を含むコンテナ -->
                <div class="d-flex">
                  <!-- 写真エリア -->
                  <div class="licence__photos me-4">
                    <div
                      class="d-flex flex-row align-items-end justify-content-center"
                    >
                      <!-- 親権者写真（小さいサイズ） -->
                      <div
                        v-if="hasParentGuardian && (get_assets(150, 0) || {})?.path"
                        class="licence__photo-guardian me-2 border"
                      >
                        <img
                        :src="(get_assets(150, 0) || {}).path"

                          alt="Guardian Photo"
                        />
                      </div>
                      <!-- メイン写真（大きいサイズ） -->
                      <div class="licence__photo-main border" v-if="(get_assets(100, 0) || {})?.path">
                        <img
                          :src="(get_assets(100, 0) || {}).path"
                          alt="Main Photo"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- 情報エリア -->
                  <div class="flex-grow-1">
                    <div class="text-end title05 page__h5">
                      <span class="me-2">{{ clock_date }}</span>
                      <span>{{ clock }}</span>
                    </div>
                    <ul class="licence__ul mb-0">
                      <li class="licence__li mb-1">
                        {{ data_obj.name_1 }} {{ data_obj.name_2 }}
                      </li>
                      <li class="licence__li mb-1">
                        Licence No. {{ make_pref(data_obj.pref_label) }}-{{
                          data_obj.license_no
                        }}
                      </li>
                      <li class="licence__li mb-1 text-danger">
                        {{ get_license_label(license.license_cd, 'label') }}
                      </li>
                      <li class="licence__li mb-1">
                        有効期限：{{ license.license_term_start_date }} -
                        {{ license.license_term_end_date }}
                      </li>
                      <li class="licence__li mb-1">
                        保険期限：{{
                          (get_insurance_fixed(license.license_year) || {}).start_date_date
                        }}
                        - {{ (get_insurance_fixed(license.license_year) || {}).end_date_date }}
                      </li>
                      <!-- <li class="licence__li mb-1">
                        現在日時：{{ clock_date }}
                      </li> -->
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row g-2">
            <div
              v-for="license in license_now_sort"
              :key="license"
              class="col-12 col-sm-6 col-md-4 col-lg-3"
            >
              <div class="d-grid">
                <button
                  :class="get_license_btn_class(license.license_id)"
                  @click="attach_sel_licensse(license.license_id)"
                >
                  {{ license.short_label }} <span v-if="chk_multi_year_license(license.license_cd)">({{  license.license_year  }})</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="mb-32">
            <div class="licence mb-32">
              <div class="licence__header text-center mb-4">
                <div class="container">
                  <!-- ロゴを中央配置 -->
                  <div class="text-center mb-3">
                    <div class="licence__logo">
                      <img
                        :src="require('@/assets/img/header_img_01.png')"
                        alt="Motorcycle Federation of Japan"
                        class="mx-auto d-block"
                      />
                    </div>
                  </div>
                  
                  <div class="d-block align-items-center" v-if="false">
                    <div class="licence__logoGroup">
                      <p class="licence__year">{{ get_term_year }}</p>
                      <h3 class="licence__year w-100">MFJ Licence</h3>
                    </div>
                  </div>
                  <!-- QRコードとライセンス情報を横並び -->
                  <div class="d-flex align-items-center" v-if="false">
                    <div class="licence__qr" >
                      <vue-qrcode
                        v-if="false"
                        :value="data_obj.license_no"
                        :options="qr_option"
                        tag="img"
                      ></vue-qrcode>
                    </div>

                    <div class="licence__logoGroup">
                      <p class="licence__year">{{ get_term_year }}</p>
                      <h3 class="licence__year w-100">MFJ Licence</h3>
                    </div>
                  </div>
                </div>
              </div>

              <div class="licence__content">
                <div class="d-flex">
                  <div class="licence__photos me-4">
                    <div class="d-flex flex-row align-items-end justify-content-center">

                      <!-- 親権者写真（小さいサイズ） -->
                      <div
                        v-if="hasParentGuardian && (get_assets(150, 0) || {})?.path"
                        class="licence__photo-guardian me-2 border"
                      >
                        <img
                        :src="(get_assets(150, 0) || {}).path"

                          alt="Guardian Photo"
                        />
                      </div>
                      <div class="licence__photo-main border">
                        <img
                          :src="(get_assets(100, 0) || {}).path"
                          alt="Main Photo"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <ul class="licence__ul mb-0">
                      <li class="licence__li mb-1">
                        {{ data_obj.name_1 }} {{ data_obj.name_2 }}
                      </li>
                      <li class="licence__li mb-1">
                        Licence No. {{ make_pref(data_obj.pref_label) }}-{{ data_obj.license_no }}
                      </li>
                      <li class="licence__li mb-1 text-danger">--</li>
                      <li class="licence__li mb-1">有効期限：-</li>
                      <li class="licence__li mb-1">保険期限：-</li>
                      <!-- <li class="licence__li mb-1">
                        ログイン日時：{{ data_obj.last_login_date }}
                      </li> -->
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- PDF保存 -->
        <div class="container" v-if="get_pdf_version">
          <div class="row mt-32 mb-32">
            <h2 class="title07 text-center page__titleAndNewsTitle">
              <span class="title07__span">PDFでカードを作成</span>
            </h2>
          </div>
          <div class="row mt-32 mb-32">
            <div class="col-12 col-md-6 mb-4" v-for="term_year in license_year_list" :key="term_year.key">
              <div class="d-grid" v-if="!card_pdf_files[term_year.key]">
                <button type="button" class="button04" @click="download_card('generate', term_year.key)">{{term_year.label}}年のカード</button>
              </div>
              <div class="d-grid" v-else>
                <a :href="get_base_api + '/member/download?file=' + card_pdf_files[term_year.key]" class="button02" @click="downloaded(term_year.key)">ダウンロードする</a>
              </div>
            </div>
          </div>
        </div>
        <div class="container" v-else>
          <div class="row mt-32 mb-32">
            <div class="col-10 offset-1 col-sm-8 offset-sm-2 col-md-6 offset-md-3">
              <div class="d-grid">
                <button type="button" class="button04" @click="download_card('download', 0)">PDFでカードを保存</button>
              </div>
            </div>
          </div>
          <div class="row mt-32 mb-32">
            <div class="col-12">
              <div class="text-end" v-if="!card_pdf_file">
                ポップアップブロックでダウンロード出来ない場合はこちら
              </div>
              <div class="text-end" v-if="!card_pdf_file">
                <a @click="download_card('generate', 0)" class="button02">PDFカード生成</a>
              </div>
              <div class="text-end" v-else-if="card_pdf_file">
                <a :href="get_base_api + '/member/download?file=' + card_pdf_file" class="button02">ダウンロードする</a>
              </div>
            </div>
          </div>
        </div>
        <!-- {{ 講習未受講ライセンス }} -->
        <div
          class="col-12 offset-0 col-xl-6 mb-4"
          v-if="license_non_fixed.length > 0"
        >
          <h3 class="title07 mb-2">講習未受講ライセンス</h3>
          <div class="">
            <p class="box02 mb-32">Web講習を受講して下さい。</p>
          </div>
          <div
            class="mb-32"
            v-for="license in license_non_fixed"
            :key="license"
          >
            <div class="row mb-2 border-bottom pb-2">
              <div class="col-7">{{ license.license_label }}</div>
              <div class="col-5 seminar">
                <button
                  @click="go_seminar(license.license_id)"
                  class="button04"
                >
                  講習を受講する
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- {{ ライセンス昇格ボタン }} -->
        <div class="box02 mb-32" v-if="false">
          <h3 class="box02__title">
            <font-awesome-icon
              :icon="['fas', 'circle-exclamation']"
              class="box02__icon"
            />ライセンス昇格：
          </h3>
          <ol class="box02__list">
            <li>同年度内にロードレースフレッシュマン → 国内</li>
            <li>同年度内に競技委員・講師の等級昇格</li>
          </ol>
        </div>
        <!-- {{ TodoList DBはどこから取得する？ 申請受付状況 }} -->
        <div class="page__titleAndNews pt-32 pb-32" v-if="false">
          <div class="page__titleAndNewsLeft">
            <h2 class="title07 page__titleAndNewsTitle">
              <span class="title07__span">申請受付状況</span>
            </h2>
          </div>
          <div class="newsList page__titleAndNewsRight">
            <li class="newsList__li">
              <div class="d-flex align-items-center flex-shrink-0">
                <p class="newsList__date">2024/01/14</p>
                <p class="newsList__category">変更（ライセンス発行書）</p>
              </div>
              <p class="newsList__text">
                2024/01/14 23:52:31に申請変更を完了しました。
              </p>
            </li>
            <li class="newsList__li">
              <div class="d-flex align-items-center flex-shrink-0">
                <p class="newsList__date">2024/01/14</p>
                <p class="newsList__category">継続</p>
              </div>
              <p class="newsList__text">
                2024/01/14 23:52:31に申請変更を完了しました。
              </p>
            </li>
            <li class="newsList__li">
              <div class="d-flex align-items-center flex-shrink-0">
                <p class="newsList__date">2024/01/14</p>
                <p class="newsList__category">継続（ライセンス発行）</p>
              </div>
              <p class="newsList__text">
                2024/01/14 23:52:31に申請変更を完了しました。
              </p>
            </li>
            <li class="newsList__li">
              <div class="d-flex align-items-center flex-shrink-0">
                <p class="newsList__date">2024/01/14</p>
                <p class="newsList__category">連絡</p>
              </div>
              <p class="newsList__text">
                2024/01/14 23:52:31に申請変更を完了しました。
              </p>
            </li>
            <li class="newsList__li">
              <div class="d-flex align-items-center flex-shrink-0">
                <p class="newsList__date">2024/01/14</p>
                <p class="newsList__category">連絡（重要なお知らせ）</p>
              </div>
              <p class="newsList__text">
                2024/01/14 23:52:31に申請変更を完了しました。
              </p>
            </li>
          </div>
        </div>
        <!-- {{ TodoList DBはどこから取得する？ 新着情報 }} -->
        <div class="page__titleAndNews pt-32 pb-32">
          <div class="page__titleAndNewsLeft">
            <h2 class="title07 page__titleAndNewsTitle">
              <span class="title07__span">新着情報</span>
            </h2>
          </div>
          <div class="newsList page__titleAndNewsRight" v-if="typeof information_list === 'object' && information_list.length > 0">
            <li class="newsList__li" v-for="list in information_list.slice(0, 5)" :key="list.id">
              <div class="d-flex align-items-center flex-shrink-0">
                <p class="newsList__category newsList__category--color02">
                  <!-- カテゴリー5 -->
                  {{ list.category_type_label }}
                </p>
                <p class="newsList__date">{{ list.set_date_date_val }}</p>
              </div>
              <p class="newsList__text" @click="go_info_detail(list.id)">
                {{ list.subject }}
              </p>
            </li>
          </div>
        </div>
        <p class="text-center mb-32 pb-32" v-if="typeof information_list === 'object' && information_list.length > 0">
          <router-link class="button04" to="/information/list"
            ><span class="button04__text">もっとみる</span
            ><i class="button04__icon fa-solid fa-chevron-right"></i
          ></router-link>
        </p>
        
        <!-- {{ TodoList DBはどこから取得する？ 新着情報 }} -->
        <div class="page__titleAndNews pt-32 pb-32">
          <div class="page__titleAndNewsLeft">
            <h2 class="title07 page__titleAndNewsTitle">
              <span class="title07__span">特典情報</span>
            </h2>
          </div>
          <!-- {{ information_obj }} -->
          <div class=" page__titleAndNewsRight" v-if="typeof information_sp_list === 'object' && information_sp_list.length > 0">
            <div class="container">
              <div class="row d-flex">
                <div class="col-12 col-sm-6 col-md-6 col-lg-4" v-for="list in information_sp_list.slice(0, 5)" :key="list.id">
                  <div class="card h-100" @click="go_info_detail(list.id)">
                    <img :src="list.header_image_path" class="card-img-top">
                    <div class="card-body">
                      <h5 class="card-title">{{ list.subject }}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="newsList page__titleAndNewsRight" v-if="typeof information_sp_list === 'object' && information_sp_list.length > 0">
            <li class="newsList__li" v-for="list in information_sp_list.slice(0, 5)" :key="list.id">
              <div class="d-flex align-items-center flex-shrink-0">
                <p class="newsList__date">{{ list.set_date_date_val }}</p>
                <p class="newsList__category newsList__category--color02">
                  {{ list.category_type_label }}
                </p>
              </div>
              <p class="newsList__text" @click="go_info_detail(list.id)">
                {{ list.subject }}
              </p>
            </li>
          </div> -->
        </div>
        <!-- <p class="text-center" v-if="typeof information_sp_list === 'object' && information_sp_list.length > 0">
          <router-link class="button04" to="/information/list"
            ><span class="button04__text">もっとみる</span
            ><i class="button04__icon fa-solid fa-chevron-right"></i
          ></router-link>
        </p> -->
      </div>
    </div>
  </div>
</template>

<style scoped>
.card-img-top {
      height: 200px; /* 高さを固定 */
      object-fit: cover; /* 画像がカードに収まるように調整 */
    }
  .licence__qr {
    width: 13rem;
  }

  /* ライセンスカードの基本スタイル */
  .licence {
    position: relative;
    overflow: hidden;
    border-radius: 16px;
    padding: 16px;
    background: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  /* ヘッダー部分 */
  .licence__header {
    position: relative;
    z-index: 1;
    width: 100%;
    text-align: center;
  }

  .licence__logo {
    width: 100%;
    margin-bottom: 1rem;
  }

  .licence__logo img {
    width: 100%;
    max-width: 300px;
    height: auto;
    object-fit: contain;
    margin: 0 auto;
    display: block;
  }

  .licence__title-group {
    margin-top: 0.5rem;
  }

  .licence__title {
    font-size: 1.5rem;
  }

  /* コンテンツ部分 */
  .licence__content {
    position: relative;
    z-index: 1;
    margin-top: 1rem !important;
  }

  /* 写真エリア全体 */
  .licence__photos {
    display: flex;
    flex-shrink: 0;
    width: 30rem;
    justify-content: center;
  }

  /* 親権者写真（小さいサイズ） */
  .licence__photo-guardian {
    width: 100px;
    height: 120px;
    border-radius: 4px;
    overflow: hidden;
  }

  /* メイン写真（大きいサイズ） */
  .licence__photo-main {
    width: 220px;
    height: 280px;
    border-radius: 4px;
    overflow: hidden;
  }

  /* 共通の画像スタイル */
  .licence__photo-guardian img,
  .licence__photo-main img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  /* 斜めライン */
  .licence::after {
    content: '';
    position: absolute;
    top: -20%;
    right: -30%;
    width: 300px;
    height: 250%;
    transform: rotate(45deg);
    transform-origin: top;
    background-color: var(--license-color);
    /* opacity: 0.7; */
  }

  /* z-indexの管理 */
  .licence__header,
  .licence__content,
  .licence__photo,
  .licence__info {
    position: relative;
    z-index: 1;
  }

  /* リストのスタイル調整 */
  .licence__ul {
    list-style: none;
    padding: 0;
    font-size: 0.9rem;
  }

  .licence__li {
    margin-bottom: 0.5rem;
  }

  /* メディアクエリ - タブレット以上 */
  @media (min-width: 768px) {
    .licence {
      padding: 24px;
    }

    .licence__logo img {
      max-width: 600px;
    }

    .licence__title {
      font-size: 2rem;
    }

    .licence__photo-guardian {
      width: 120px;
      height: 150px;
    }

    .licence__photo-main {
      width: 300px;
      height: 370px;
    }

    .licence__ul {
      font-size: 1rem;
    }

    .licence__qr {
      margin-right: 10rem;
    }
  }

  /* レイアウト調整 - スマホ向け */
  @media (max-width: 767px) {
    .licence__content .d-flex {
      flex-direction: column;
      justify-content: center;
    }

    .licence__photos {
      margin-bottom: 1rem;
      width: 100%;
      justify-content: center;
    }

    .licence__photos .d-flex {
      justify-content: center;
    }

    .licence__photo-guardian {
      width: 120px;
      height: 150px;
    }

    .licence__photo-main {
      width: 160px;
      height: 200px;
    }

    .licence__li {
      margin-bottom: 0.2rem;
      font-size: 0.9rem;
    }

    .licence__year {
      font-size: 1.8rem;
    }

    /* 斜めライン */
    .licence::after {
      content: '';
      position: absolute;
      top: 10%;
      right: -50%;
      width: 100px;
      height: 150%;
      transform: rotate(30deg);
      transform-origin: top;
    }
  }
</style>

<script>
  import { mapState } from 'vuex'
  import config from '../../const'
  import VueQrcode from '@chenfengyuan/vue-qrcode'
  // import BsPageTitle from '../molecules/BsPageTitle.vue'
  // // import BsButton from '../atoms/BsButton.vue'
  // import BsSelect from '../atoms/BsSelect.vue'
  // import BsDashBoardNav from './BsDashBoardNavView.vue'
  // import BsPageNav from '../../molecules/BsPageNav.vue'
  import Common from '../../common/common'
  // // import Checkin from './Checkin/CheckinView.vue'
  // import axios from 'axios';
  // import config from '../../const'

  export default {
    name: 'BsDashBoardView',
    data: function () {
      return {
        pref_obj: [],
        license_type_obj: [],
        license_category_obj: [],
        license_class_obj: [],
        event_cd_obj: [],
        license_cd_obj: [],
        data_obj: {},
        selected_license_id: 0,
        clock: '',
        m_relation_obj: [],
        BASE_ASSETS_URL: config.BASE_ASSETS_URL,
        qr_option: {
          width: 150, // QRコードの幅
          height: 150, // QRコードの高さ
          colorDark: '#000000', // QRコードの色
          colorLight: '#ffffff', // 背景色
          correctLevel: 'H', // 誤り訂正レベル（L,M,Q,H）
        },
        information_category_type_obj: [],
        information_obj: [],
        information_sp_obj: [],
        term_year_obj: [],
        card_pdf_file: '',
        card_pdf_files: ['', ''],
      }
    },
    props: {},
    components: {
      VueQrcode,
      // BsPageTitle,
      // // BsButton,
      // BsSelect,
      // // Checkin,
      // BsDashBoardNav,
      // CheckinListView,
      // CheckoutListView,
      // DriveListView,
      // LodgeListView,
      // PaddockIndicatorView,
    },
    computed: {
      isCurrentYearUpdated: function(){
        if(
          Array.isArray(this.valid_license_list) && this.valid_license_list.length > 0
        ){
          return true
        }
        return false
      },
      license_year_list: function(){
        const tmp_year = (Array.isArray(this.term_year_obj))? [...this.term_year_obj]: false
        return (tmp_year)? tmp_year.filter(tmp => {
          if(
            Array.isArray(this.valid_license_list) 
            && this.valid_license_list.find(license => (license.license_year == tmp.label && license.fixed==1))
          ){
            return true
          }
          return false
        }): []
      },
      // pdfダウンロード新バージョン(テストかどうかを確認,リリース時にtrueに変える)
      get_pdf_version: function(){
        return (this.get_base_env!='production')? true: true
      },
      get_term_year: function(){
          return (
              typeof this.term_year_obj === 'object' 
              && typeof this.term_year_obj[0] === 'object'
              && this.term_year_obj[0]['label']
          )? parseInt(this.term_year_obj[0]['label']): parseInt(Common.getNowYear()); 
      },
      make_pref: function () {
        return function (pref_label) {
          if (pref_label == '東京都') {
            return '東京'
          } else if (
            typeof pref_label === 'string' &&
            pref_label.endsWith('府')
          ) {
            return pref_label.replace('府', '')
          } else if (
            typeof pref_label === 'string' &&
            pref_label.endsWith('県')
          ) {
            return pref_label.replace('県', '')
          }

          return pref_label
        }
      },
      ...mapState({}),
      information_category_type_list: function () {
        return this.information_category_type_obj
      },
      information_list: function () {
        return this.information_obj
      },
      information_sp_list: function () {
        return this.information_sp_obj
      },
      m_valid_invitation_chk: function () {
        // 既に別の参加がある
        if(
          typeof this.data_obj === 'object' && 
          Array.isArray(this.data_obj?.club_relation) && 
          this.data_obj?.club_relation?.find(realtion => realtion.stat == 1 && realtion.license_year >= this.get_term_year)
        ){
          return false
        }
        // 招待がない
        else if(
          !Array.isArray(this.m_valid_invitation_list)
        ){
          return false
        }
        else{
          return true
        }
      },
      m_valid_invitation_list: function () {
        return (Array.isArray(this.data_obj?.club_invitation))? this.data_obj?.club_invitation?.filter(invitation => invitation?.stat == 0 && invitation?.reject_flg == 0 && invitation?.license_year >= this.get_term_year): []
      },
      m_relation_list: function () {
        return this.m_relation_obj
      },
      get_insurance_fixed: function () {
        // data_obj.insurance が存在し、配列であることを確認
        const insurance_list = this.data_obj?.insurance || []
        return function(year) {
          if (!Array.isArray(insurance_list)) {
            return {}
          }
          return insurance_list.find((insurance) => 
            insurance.fixed == 1 && insurance.sports_year == year
          ) || {}
        }
      },
      chk_multi_year_license: function(){
        const license_list = this.license_now_sort
        return function(license_cd){
          return (license_list?.filter(license => license.license_cd == license_cd)?.length > 1)? true: false
        }
      },
      chk_license_btn_class: function () {
        const license_now_sort = this.license_now_sort
        return function (license_id) {
          if (
            license_now_sort.find(
              (license) => license.id == license_id && license.sel_disp == true
            )
          ) {
            return true
          }
          return false
        }
      },
      get_license_btn_class: function () {
        const chk = this.chk_license_btn_class
        return function (license_id) {
          const isActive = chk(license_id)
          return `checkbox02__label me-3 mb-3${isActive ? '' : ' active'}`
        }
      },
      get_assets: function () {
        const form_obj = this.data_obj
        return function (type_cd, sort_key) {
          if (
            typeof form_obj === 'object' &&
            Array.isArray(form_obj.member_assets)
          ) {
            const filtered_assets = form_obj.member_assets.filter(
              (asset) => asset.type == type_cd
            )
            return filtered_assets[sort_key] || {}
          }
          return {}
        }
      },
      valid_license_list: function(){
        if(
          this.data_obj?.member_license
        ){
          return this.data_obj?.member_license?.filter(license => parseInt(license.license_year) >= parseInt(this.get_term_year))
        }
        return []
      },
      license_now_sort: function () {
        console.log('license_now_sort', this.data_obj)
        // const license_list = 
        if (
          typeof this.valid_license_list === 'object' 
        ) {
          return this.valid_license_list
            .filter((license) => {
              if (
                license.fixed == 1 &&
                (license.license_to_id == 0 || license.license_to_id == null)
              ) {
                return true
              }
              return false
            })
            .map((license, index) => {
              license.sel_disp =
                this.selected_license_id == license.id
                  ? true
                  : this.selected_license_id == 0 && index == 0
                  ? true
                  : false
              return license
            })
            .sort((a, b) => {
              if(parseInt(a.license_category) < parseInt(b.license_category)){
                return -1
              } else {
                return 1
              }
            })
        } else {
          return []
        }
      },
      license_non_fixed: function () {
        if (
          typeof this.data_obj === 'object' &&
          typeof this.data_obj.member_license === 'object' && 
          Array.isArray(this.data_obj.member_license)
        ) {
          const chk_license = []
          return [...this.data_obj.member_license]
          .sort((a, b) => {
            if (parseInt(a.license_year) < parseInt(b.license_year)) {
              return -1
            } else {
              return 1
            }
          })
          .filter((license) => {
            if (
              !chk_license.includes(parseInt(license.license_cd)) && 
              license.fixed == 0 &&
              (license.license_to_id == 0 || license.license_to_id == null)
            ) {
              chk_license.push(parseInt(license.license_cd))
              return true
            }
            return false
          })
        } else {
          return []
        }
      },
      // css
      license_bg_class: function () {
        const base = 'w-100 border border-dark rounded-3 py-3 px-3 mb-3'
        return function (license) {
          if (license.license_class == 1) {
            return 'bg_color_1 ' + base
          } else if (license.license_class == 2) {
            return 'bg_color_2 ' + base
          } else if (license.license_class == 3) {
            return 'bg_color_3 ' + base
          }
        }
      },
      license_text_class: function () {
        return function (license) {
          if (license.license_class == 1) {
            return 'text-white'
          } else if (license.license_class == 2) {
            return 'text-white'
          } else if (license.license_class == 3) {
            return 'text-black'
          }
        }
      },
      license_btn_class: function () {
        return function (license) {
          if (license.license_class == 1) {
            return 'bg-danger text-white'
          } else if (license.license_class == 2) {
            return 'bg_mfj_blue text-white'
          } else if (license.license_class == 3) {
            return 'bg-warning text-dark'
          }
        }
      },
      event_cd_list: function () {
        const lists = [...this.event_cd_obj]
        return lists
          .map((list) => {
            list.key = list.event_cd
            return list
          })
          .sort((a, b) => {
            if (parseInt(a.sort) < parseInt(b.sort)) {
              return -1
            } else {
              return 1
            }
          })
      },
      license_cd_list: function () {
        const lists = [...this.license_cd_obj]
        return lists
          .map((list) => {
            list.key = list.license_cd
            // list.class_label = this.get_list_param('license_class', list.license_class, 'label');
            // list.category_label = this.get_category_label('license_category', list.license_category, 'label');
            return list
          })
          .sort((a, b) => {
            if (parseInt(a.sort) < parseInt(b.sort)) {
              return -1
            } else {
              return 1
            }
          })
      },
      license_class_list: function () {
        const lists = [...this.license_class_obj]
        return lists
      },
      license_category_list: function () {
        const lists = [...this.license_category_obj]
        return lists
      },
      get_license_data: function () {
        const lists = this.license_cd_list
        return function (key) {
          if (typeof lists.find((list) => list.id == key) === 'object') {
            return lists.find((list) => list.id == key)
          }
          return key
        }
      },
      get_license_label: function () {
        const get_func = this.get_license_data
        return function (key, label) {
          const data = get_func(key)
          if (
            typeof data === 'object' &&
            (typeof data[label] === 'string' || typeof data[label] === 'number')
          ) {
            return data[label]
          }
          return key
        }
      },
      get_pref_label: function () {
        const pref_list = this.pref_list
        return function (key) {
          if (
            typeof pref_list.find((pref) => (pref.pref_cd == key || ('0' + pref.pref_cd == key))) === 'object'
          ) {
            return pref_list.find((pref) => (pref.pref_cd == key || ('0' + pref.pref_cd == key))).label
          }
          return key
        }
      },
      pref_list: function () {
        const lists = [...this.pref_obj]
        return lists.sort((a, b) => {
          if (parseInt(a.sort) < parseInt(b.sort)) {
            return -1
          } else {
            return 1
          }
        })
      },
      hasParentGuardian() {
        return (
          this.data_obj &&
          this.data_obj.member_parent &&
          Object.keys(this.data_obj.member_parent).name_1 !== '' &&
          Object.keys(this.data_obj.member_parent).name_2 !== ''
        )
      },
      getLicenseColorFromCd() {
        return (licenseCd) => {
          // const paddedLicenseCd = String(licenseCd).padStart(3, '0')
          const licenseInfo = this.license_cd_obj.find(
            (l) => l.id === licenseCd
          )
          if (!licenseInfo) {
            return '#00a99d'
          }

          return licenseInfo.color_cd || '#00a99d'
        }
      },
    },

    async created() {
      await this.loadLicenseCdList()
      await this.loadEventCdList()
      await this.loadLicenseClassList()
      await this.loadLicenseCategoryList()
      await this.loadLicenseTypeObj()
      await this.loadUserObj()
      await this.loadApplicationCheck()
      await this.loadTermYear()
      await this.first_select()
      if(this.get_base_env != 'development') await this.tick()
      await this.loadClubRelationObj()
      await this.loadConfigObj('information_category_type')
      await this.loadInformationList()
      await this.loadInformationSpList()
    },

    methods: {
      async loadTermYear(){
          const params = {};
          await this.basicPosts_S(
              config.BASE_API_URL + '/term_year/lists',
              params
          )
          .then(
              async (response) => {
                  console.log('loadTermYear response', response);
                  this.term_year_obj = (response.data && response.data.body)? response.data.body: [];
              }
          )
          .catch(
              async (err) => {
              console.log(err);
              this.term_year_obj = [];
          })
          .finally(async () => {
              this.$forceUpdate();
          });
      },
      async downloaded(year_add){
        if(this.card_pdf_files[year_add]){
          this.card_pdf_files[year_add] = ''
        }
      },
      async download_card(flg, year_add){
        const params = {};
        // params['member_token'] = this.$store.state.auth.userId
        params['member_token'] =  this.$store.state.auth.token
        if(this.get_pdf_version != false && this.term_year_obj[year_add] && this.term_year_obj[year_add].label){
          params['term_year'] = this.term_year_obj[year_add].label
        }else{
          params['term_year'] = this.get_term_year
        }
        params['pagegroup'] = 'mfj_member_data.id'
        console.log('download_card params', params);
        await this.basicPosts_S(config.BASE_API_URL + '/member/output_card', params)
          .then(
              (response) => {
                  console.log('download_card then', response);
                  if(flg == 'generate'){
                    this.card_pdf_file = response?.data?.body
                    if(this.get_pdf_version) this.card_pdf_files[year_add] = response?.data?.body
                  }else{
                    window.open(config.BASE_API_URL + '/member/download?file=' + response.data.body, '_blank');
                  }
              })
          .catch(
              (err) => {
              Promise.reject(err);
              console.log('output error ', err);
              }
        );
      },
      attach_sel_licensse(license_id) {
        console.log('attach_sel_licensse', license_id)
        console.log('this.selected_license_id', this.selected_license_id)
        this.selected_license_id = license_id
      },
      go_info_detail(info_id){
        this.$router.push({
          path: '/information/data',
          query: {
            no: info_id,
          },
        })
      },
      go_seminar(license_id) {
        const path = '/license/seminar'
        console.log('go_seminar', path)
        this.$router.push({
          path: path,
          query: {
            tkn: license_id,
          },
        })
      },
      go_relation(rel_token) {
        const path = '/join/club'
        console.log('go_relation', path)
        this.$router.push({
          path: path,
          query: {
            tkn: rel_token,
          },
        })
      },
      async first_select() {
        console.log('first_select this.license_now_sort', this.license_now_sort)
        if (
          typeof this.license_now_sort === 'object' &&
          Object.keys(this.license_now_sort).length > 0
        ) {
          console.log(
            'first_select this.license_now_sort',
            Object.values(this.license_now_sort)[0]
          )
          this.attach_sel_licensse(Object.values(this.license_now_sort)[0].id)
          // this.select_license_id(Object.values(this.license_now_sort)[0].id)
        }
      },
      async tick() {
        const date = new Date() // Dateオブジェクトを作る
        let h = date.getHours() // 時
        let m = date.getMinutes() // 分
        let s = date.getSeconds() // 秒

        if (h < 10) h = '0' + h // 10未満であれば頭に0を付ける
        if (m < 10) m = '0' + m
        if (s < 10) s = '0' + s
        this.clock_date = Common.getDatePeriod(0)
        this.clock = h + ':' + m + ':' + s // 文字列を結合する

        // 100ミリ秒経ったら1度だけ実行する
        setTimeout(() => {
          this.tick() // tick関数を実行する
        }, 100)
      },
      async loadConfigObj(param) {
        const params = {}
        await this.basicPosts_S(
          config.BASE_API_URL + '/config/' + param,
          params
        )
          .then(async (response) => {
            console.log('loadConfigObj response', response)
            this[param + '_obj'] =
              response.data && response.data.body ? response.data.body : []
          })
          .catch(async (err) => {
            console.log(err)
            this[param + '_obj'] = []
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async loadInformationSpList() {
        const params = {
          pagelimit: 10,
          pagelimit_key: 10,
          p: 0,
          pagegroup: 'mfj_information_data.id',
          pageorder: 'mfj_information_data.set_date',
          pagesort: 'DESC'
        }
        params['stat'] = [1]
        // params['public_type'] = 1
        params['category_type'] = 3
        params['target_type'] = 1

        try {
          const response = await this.basicPosts_S(
            `${config.BASE_API_URL}/information/lists`,
            params
          )
          console.log('loadInformationList response', response)
          this.information_sp_obj = response.data?.body || []
          console.log('this.information_sp_obj', this.information_sp_obj)
        } catch (err) {
          console.error('Load information error:', err)
          this.information_sp_obj = []
        } finally {
          this.$forceUpdate()
        }
      }
      ,async loadInformationList() {
        const params = {
          pagelimit: 10,
          pagelimit_key: 10,
          p: 0,
          pagegroup: 'mfj_information_data.id',
          pageorder: 'mfj_information_data.set_date',
          pagesort: 'DESC'
        }
        params['stat'] = [1]
        // params['public_type'] = 1
        params['category_types'] = [1,2]
        params['target_type'] = 1

        try {
          const response = await this.basicPosts_S(
            `${config.BASE_API_URL}/information/lists`,
            params
          )
          console.log('loadInformationList response', response)
          this.information_obj = response.data?.body || []
          console.log('this.information_obj', this.information_obj)
        } catch (err) {
          console.error('Load information error:', err)
          this.information_obj = []
        } finally {
          this.$forceUpdate()
        }
        
      },
      async loadLicenseCdList() {
        const params = {}
        params['stat'] = [1]
        await this.basicPosts_S(
          config.BASE_API_URL + '/license_cd/lists',
          params
        )
          .then(async (response) => {
            console.log('loadLicenseCdList response', response)
            this.license_cd_obj =
              response.data && response.data.body ? response.data.body : []
          })
          .catch(async (err) => {
            console.log(err)
            this.license_cd_obj = []
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async loadEventCdList() {
        const params = {}
        params['stat'] = [1]
        await this.basicPosts_S(config.BASE_API_URL + '/event_cd/lists', params)
          .then(async (response) => {
            console.log('loadEventCdList response', response)
            this.event_cd_obj =
              response.data && response.data.body ? response.data.body : []
          })
          .catch(async (err) => {
            console.log(err)
            this.event_cd_obj = []
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async loadLicenseClassList() {
        const params = {}
        await this.basicPosts_S(
          config.BASE_API_URL + '/config/license_class',
          params
        )
          .then(async (response) => {
            console.log('loadLicenseClassList response', response)
            this.license_class_obj =
              response.data && response.data.body ? response.data.body : []
          })
          .catch(async (err) => {
            console.log(err)
            this.license_class_obj = []
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async loadLicenseCategoryList() {
        const params = {}
        params['stat'] = [1]
        await this.basicPosts_S(
          config.BASE_API_URL + '/config/license_category',
          params
        )
          .then(async (response) => {
            console.log('loadLicenseCategoryList response', response)
            this.license_category_obj =
              response.data && response.data.body ? response.data.body : []
          })
          .catch(async (err) => {
            console.log(err)
            this.license_category_obj = []
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async loadLicenseTypeObj() {
        const params = {}
        params['id'] = this.targetid
        await this.basicPosts_S(
          config.BASE_API_URL + '/license_type/lists',
          params
        )
          .then(async (response) => {
            console.log('loadLicenseCdObj', response)
            this.license_type_obj =
              response.data && response.data.body ? response.data.body : []
          })
          .catch(async (err) => {
            console.log(err)
            this.license_type_obj = []
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async loadPrefList() {
        const params = {}
        params['stat'] = [1]
        await this.basicPosts_S(
          config.BASE_API_URL + '/area_pref/lists',
          params
        )
          .then(async (response) => {
            console.log('loadPrefList response', response)
            this.pref_obj =
              response.data && response.data.body ? response.data.body : []
          })
          .catch(async (err) => {
            console.log(err)
            this.pref_obj = []
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async loadUserObj() {
        const params = {}
        params['stat'] = [1]
        params['id'] = this.$store.state.auth.userId
        params['pagegroup'] = 'mfj_member_data.id'
        await this.basicPosts_S(config.BASE_API_URL + '/member/lists', params)
          .then(async (response) => {
            console.log('loadUserObj', response)
            this.data_obj =
              response.data && response.data.body && response.data.body[0]
                ? response.data.body[0]
                : {}
          })
          .catch(async (err) => {
            console.log(err)
            this.data_obj = {}
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async loadApplicationCheck(){
        const params = {}
        params['stat'] = [1]
        params['application_check_types'] = [423, 426, 430]
        params['application_types'] = [1000, 1300]
        params['m_id'] = this.$store.state.auth.userId
        // params['pagegroup'] = 'mfj_member_data.id'
        await this.basicPosts_S(config.BASE_API_URL + '/member_application/charge_chk2', params)
          .then(async (response) => {
            console.log('loadApplicationCheck', response)
            const regist_token =
              response.data && response.data.body && response.data.body[0]
                ? response.data.body
                : false
            if(
              parseInt(regist_token) == 1300
            ){
              console.log('regist_token', regist_token)
              this.$router.push({
                path: '/request/license'
              })
            }
            else if(
              regist_token
            ){
              console.log('regist_token', regist_token)
              this.$router.push({
                path: '/regist/form',
                query: {
                  token: regist_token
                }
              })
            }
          })
          .catch(async (err) => {
            console.log(err)
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async loadClubRelationObj() {
        const params = {}
        params['stat'] = [0, 1]
        params['reject_flg'] = 0
        params['m_id'] = this.$store.state.auth.userId
        console.log('loadClubRelationObj params', params)
        await this.basicPosts_S(
          config.BASE_API_URL + '/club_m_relation/lists',
          params
        )
          .then(async (response) => {
            console.log('loadClubRelationObj', response)
            this.m_relation_obj =
              response.data && response.data.body ? response.data.body : []
          })
          .catch(async (err) => {
            console.log(err)
            this.m_relation_obj = []
          })
          .finally(async () => {
            console.log('loadClubRelationObj finally')
            this.$forceUpdate()
          })
      },
    },
  }
</script>
