<template>
    <section class="py-8">
        <!-- ローディング表示を追加 -->
        <div class="loading-overlay" v-if="isLoading">
            <div class="spinner-border text-danger" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div v-if="mfj_phase_year_term_001" class="container pt-5">
            <div class="row">
                <div class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2">
                    <div class="box">
                        現在年度切り替え中です。<br>
                        しばらくしてお申し込み下さい。
                    </div>
                </div>
            </div>
        </div>
        <div class="container" v-else-if="mfj_user_chk">
            <div class="row justify-content-center">
                <div class="col-md-8 col-lg-6 w-100 mb-32">
                    <div class="text-end">
                    <a :href="get_base_asset + '/manual/mfj_member_add_250203.pdf'">ライセンス 追加・継続の手続き</a>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-10 offset-sm-1">
                    <div class="page">
                        <div class="container">
                            <h2 class="title page__h1">ライセンス継続/追加申請</h2>
                            <div class="mb-12">
                                <div class="col text-end">
                                    申請日:{{ get_now_date?.date }}({{ get_term_year }}年度)
                                </div>
                                <!-- {{ page_mode }} -->
                            </div>
                            <div class="box box--center mb-24">
                                <p v-if="(this.application_obj || {}).application_check_type == 0">
                                {{ message }}
                                </p>
                                <p v-else-if="
                                (this.application_obj || {}).application_check_type == 100
                                ">
                                現在申請中です。<br>
                                {{ get_message }}
                                </p>
                                <p v-else-if="
                                (this.application_obj || {}).application_check_type == 200
                                ">
                                現在継続追加申請を確認中です。
                                </p>
                                <p v-else-if="
                                (this.application_obj || {}).application_check_type == 400
                                ">
                                決済を行って下さい。
                                </p>
                                <p v-else-if="
                                (this.application_obj || {}).application_check_type == 500
                                ">
                                ライセンス継続もしくは追加の申請手続きが完了いたしました。<br>
                                マイページのHOMEページでライセンスをご確認下さい。
                                </p>
                                <p v-else-if="
                                (this.application_obj || {}).application_check_type == 600
                                ">
                                差し戻しがあります。内容を確認して再申請をお願いします。
                                </p>
                                <p v-else-if="success_mode">
                                    ライセンス継続もしくは追加の申請手続きが完了いたしました。<br>
                                    マイページのHOMEページでライセンスをご確認下さい。
                                </p>
                                <p v-else-if="cash_unpaid_mode">
                                    コンビニでのお支払い後、マイページのHOMEでライセンスをご確認下さい。
                                </p>
                                <p v-else>申請フォームです</p>
                            </div>
                            <div v-if="remand_mode">
                                <div class="mb-5" v-if="typeof communication_obj === 'object' && Object.keys(communication_obj).length > 0">
            <div class="alert alert-danger wysiwyg">
              <h3>申請内容について</h3>
              <p>
                申請内容に不備がありました。
              </p>
              <p>
                {{ communication_obj?.comment }}
              </p>
              <div class="" v-if="is_application_comment_item">
                <p>
                  以下の項目の申請内容をご確認下さい。
                </p>
                <template v-for="item in communication_obj.items" :key="item">
                  <span class="me-2" v-if="item.item_comment">
                    <font-awesome-icon
                        :icon="['fas', 'circle-check']"
                        class="me-1"
                      /> <span class="fw-bold">{{ get_app_list_label(item.application_check_cd) }}</span>
                  </span>
                </template>
              </div>
              <div class="row d-none">
                <template v-for="item in communication_obj.items" :key="item">
                  <div class="col-6" v-if="item.item_comment">
                    <div class="border-bottom border-danger mb-2 pb-2">
                      <font-awesome-icon
                        :icon="['fas', 'circle-check']"
                        class="me-2"
                      />
                      <span class="fw-bold">{{ get_app_list_label(item.application_check_cd) }}</span>
                    </div>
                    <div>
                      <font-awesome-icon
                        :icon="['fas', 'comment']"
                        class="me-2"
                      />
                      {{ item.item_comment }}
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
                            </div>
                            <form>
                                <template v-if="license_mode">
                                    <template v-if="is_valid_license">
                                        <h3 class="title02 page__h2">保有ライセンス</h3>
                                        <div class="row mb-40">
                                            <div class="col-6 col-sm-6 col-md-4 mb-12" v-for="license in is_valid_license" :key="license">
                                                <div class="page__inputGroup d-block flex-wrap">
                                                    <div>
                                                        <span class="text-center w-100 checkbox02__label me-3 mb-3 ">
                                                            [{{ license.license_year }}]
                                                            {{ license.license_label }}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <div class="mb-40">
                                        <h3 class="title02 page__h2">
                                            年度選択
                                        </h3>
                                        <!-- <div class="row mb-12" v-if="!selected_year || selected_year.length ==0 ">
                                            <div class="col-12">
                                                <p class="alert alert-warning">年度を選択していただくと、継続できるライセンスが表示されます。</p>
                                            </div>
                                        </div> -->
                                        <div class="row mb-40">
                                            <div class="col-6 col-sm-6 col-md-4 mb-2" v-for="year in race_sports_year_list" :key="year">
                                                <div class="page__inputGroup d-block flex-wrap">
                                                    <div>
                                                        <button type="button" 
                                                            :class="['checkbox02__label', 'text-center', 'w-100', 'me-3', 'mb-3', { active: !selected_year.includes(year.key) }]"
                                                            id="input011" 
                                                            @click="attach_license_sel_year(year.key)"
                                                        >
                                                        {{year.label}}年度
                                                        </button>
                                                    </div>
                                                    <div class="text-center"  v-if="get_base_env=='development'">
                                                        <span>競技年齢:</span>
                                                        {{ get_sports_age(year.key) }}歳
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6 col-sm-6 col-md-4 mb-2" v-if="race_sports_year_list.length >= 2 && !is_valid_license_chk">
                                                <div class="page__inputGroup d-block flex-wrap">
                                                    <div>
                                                        <button 
                                                            :key="year" type="button"  
                                                            :class="['checkbox02__label', 'text-center', 'w-100', 'me-3', 'mb-3', { active: !selected_package_flg }]"
                                                            id="input011"
                                                            @click="attach_license_sel_package()"
                                                        >
                                                        パッケージ
                                                        </button>
                                                    </div>
                                                    <div class="text-center" v-if="get_base_env=='development'">
                                                        <span>競技年齢:</span>
                                                        <span v-if="get_sports_age(0)==get_sports_age(1)">
                                                            {{ get_sports_age(1) }}歳
                                                        </span>
                                                        <span v-else>
                                                            {{ get_sports_age(0) }}歳-{{ get_sports_age(1) }}歳</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- {{ article_variant_filter_lc_and_year_and_acate(1) }} -->
                                    <div class="mb-40">
                                        <h3 class="title02 page__h2">継続ライセンス選択</h3>
                                        <!-- {{ chk_obtainable }}<hr>{{ get_past_valid_license }}<hr>{{ is_valid_license_past }} -->
                                        <template v-if="chk_obtainable">
                                            <div class="container mb-3 pb-3 border-bottom" v-for="license in obtainable_license" :key="license">
                                                <template v-if="chk_article_variant_filter_obtainable(license.license_cd)">
                                                    <div class="" v-for="tmp in article_variant_filter_obtainable(license.license_cd)" :key="tmp">
                                                        <h4 class="title04 page__h4">{{ license.license_label }}</h4>
                                                        <div class="w-100">
                                                            <!-- <p>{{ number_format(tmp.default_price) }}円</p> -->

                                                            <span class="d-block" v-if="selected_package_flg==0"
                                                                >{{ number_format(tmp.default_price) }}円</span
                                                            >
                                                            <div class="" v-else-if="selected_package_flg==1 && typeof get_selected_variants(tmp.license_id) === 'object'">
                                                                <div class="col" v-for="selval in get_selected_variants(tmp.license_id)" :key="selval">
                                                                <span>({{ get_year_label(selval.sports_year_add)}}) {{number_format(get_variant(selval.variant_id, selval.sports_year_add)?.default_price)}} 円</span>
                                                                </div>
                                                            </div>
                                                            <div class="" v-else-if="selected_package_flg==1">
                                                                <div class="col" v-for="(variant_id, y_k) in tmp.package_variant_ids" :key="variant_id">
                                                                <span>({{ get_year_label(y_k)}}) {{number_format(get_variant(variant_id, y_k)?.default_price)}} 円</span>
                                                                </div>
                                                            </div>

                                                            <p>{{ tmp.description }}</p>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col">
                                                                <button 
                                                                    type="button"  
                                                                    :class="['checkbox02__label', 'me-3', 'mb-3', { active: !chk_selected_variants(tmp.variant_id) }]"
                                                                    id=""
                                                                    @click="sel_license(tmp.license_id, tmp.license_category, tmp.category, false)" 
                                                                    :disabled="(chk_license_sel_variant(tmp.license_id, tmp.license_event_cd) == 10) ? 'disabled' : false"
                                                                >
                                                                    継続する
                                                                </button>
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>
                                            </div>
                                        </template>
                                        <div v-else class="container">
                                            <!-- <div class="row" v-if="!selected_year || selected_year.length == 0">
                                                <div class="alert alert-warning">
                                                    年度を選択していただくと、継続できるライセンスが表示されます。
                                                </div>
                                            </div> -->
                                            <div class="row">
                                                <div class="alert alert-warning">
                                                    現在、継続申請できるライセンスはありません。<br>
                                                    継続できるライセンスが違う年度の場合があります。違う年度を選択して下さい。
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-40">
                                        <h3 class="title02 page__h2">追加可能ライセンス選択</h3>
                                        <template v-for="license_category in license_category_obj" :key="license_category">
                                            <div class="row" v-if="typeof article_variant_filter_add(license_category.key) === 'object' && article_variant_filter_add(license_category.key).length > 0">
                                                <h5 class="title02 mb-2 border-bottom pb-2" style="font-size: 1.1rem;">{{ license_category.label }}</h5>
                                                <div class="col-12 col-md-6 mb-2 pb-2" v-for="tmp in article_variant_filter_add(license_category.key)" :key="tmp.license_id">
                                                    <div class="d-grid">
                                                        <button 
                                                            type="button"  
                                                            :class="['checkbox02__label', 'me-3', 'mb-3', { active: !chk_selected_variants(tmp.variant_id) }]"
                                                            id="input021"
                                                            @click="sel_license(tmp.license_id, license_category.key, tmp.category, false)" 
                                                            :disabled="(chk_license_sel_variant(tmp.license_id, tmp.license_event_cd) == 10) ? 'disabled' : false"
                                                        >
                                                            <!-- {{tmp.variant_id}}: -->
                                                            {{ tmp.license_label }}
                                                            <!-- /{{tmp.category}} -->
                                                            <!-- ・{{ tmp.default_price }}/{{ tmp.license_event_cd }}・{{ chk_license_sel_variant(tmp.license_id, tmp.license_event_cd) }} -->
                                                        </button>
                                                        <!-- {{tmp.manage_label}} -->

                                                    </div>
                                                    <div>
                                                        <span class="d-block" v-if="selected_package_flg==0"
                                                            >{{ number_format(tmp.default_price) }}円</span
                                                        >
                                                        <div class="row" v-else-if="selected_package_flg==1 && typeof get_selected_variants(tmp.license_id) === 'object'">
                                                            <div class="col" v-for="selval in get_selected_variants(tmp.license_id)" :key="selval">
                                                            <span>({{ get_year_label(selval.sports_year_add)}}) {{number_format(get_variant(selval.variant_id, selval.sports_year_add)?.default_price)}} 円</span>
                                                            </div>
                                                        </div>
                                                        <div class="row" v-else-if="selected_package_flg==1">
                                                            <div class="col" v-for="(variant_id, y_k) in tmp.package_variant_ids" :key="variant_id">
                                                            <span>({{ get_year_label(y_k)}}) {{number_format(get_variant(variant_id, y_k)?.default_price)}} 円</span>
                                                            </div>
                                                        </div>
                                                        <p>{{ tmp.description }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div v-else></div> -->
                                        </template>
                                    </div>
                                </template>
                                <div v-if="remand_mode || form_mode || confirm_mode" class="">
                                    <div class="mb-40" v-if="chk_license_continue">
                                        <h3 class="title02 page__h2">顔写真</h3>
                                        <div v-if="form_mode">
                                            <p v-if="(get_assets(100, 0) || {}).path" class="alert alert-warning">変更する場合はアップロードして下さい。</p>
                                            <p v-else class="alert alert-warning">顔写真のアップロードして下さい。</p>
                                        </div>
                                        <div class="row mb-32">
                                            <div class="col-3">
                                                <div class=""  v-if="(app_form_obj || {}).path3">
                                                    <img :src="(app_form_obj || {}).path3" class="w-100">
                                                </div>
                                                <div class=""  v-else-if="(get_assets(100, 0) || {}).path">
                                                    <img :src="(get_assets(100, 0) || {}).path" class="w-100">
                                                </div>
                                            </div>
                                            <div class="col-md-6" v-if="!confirm_mode">
                                                <div class="page__inputGroup">
                                                    <div class="page__inputGroupCol" v-if="!confirm_mode || !chk_app_checked('m_photo')">
                                                        <div class="label page__inputGroupLabel d-block mb-1">
                                                            写真
                                                        </div>
                                                        <button v-if="!confirm_mode || !chk_app_checked('m_photo')" type="button" @click="clickUpFileBtn(100, 3)" class="file page__inputGroupInput photo">
                                                            &nbsp;ファイルを選択
                                                            <font-awesome-icon
                                                                :icon="['fas', 'chevron-right']"
                                                                class="mx-2 photo__icon"
                                                            />
                                                        </button>
                                                        <input type="file" class="form-control" :id="'file_up_input_100_path3'" @change="selectedFile($event, 100, 3, false)"
                                                        accept="image/*"
                                                        style="display: none;">
                                                        <div v-if="get_target_com_msg('m_photo')" class="py-2">
                                                            <p class="box text-start">{{ get_target_com_msg('m_photo') }}</p>
                                                        </div>
                                                    </div>
                                                    <!-- エラーメッセージの表示 -->
                                                    <div v-if="errorMessage" class="text-danger mt-2">
                                                        <p>{{ errorMessage }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="chk_parent_accept" class="my-32">
                                            <div v-if="false">
                                                <p class="box">
                                                    未成年者の申請は参加許諾書が必要です。MFJ事務局宛にお送り下さい。
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-40" v-if="chk_license_add && chk_license_join_3hour">
                                        <h5 class="title02 page__h2">サーキットライセンスについて</h5>
                                        <template v-for="variant in get_selected_variants_filter(
                                                        {join_3hour: 1}, {category: 4}, 1)" :key="variant">
                                            <h5  class="title03 page__h3">{{ variant.license_label }}</h5>
                                            <div class="row mb-2 border-bottom border-dotted">
                                                <div class="col-12 col-sm-12 col-md-3 mb-2">
                                                    <div class="input-group">
                                                        <span class="">公認サーキット</span
                                                        ><span class="required-label">*</span>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-12 col-md-6 mb-2">
                                                    <div class="input-group">
                                                        <input 
                                                            v-if="confirm_mode" 
                                                            :value="get_list_label(variant.join_3hour_circuit_id, circuit_list)" 
                                                            :readonly="input_readonly('m_rd_3hour')" 
                                                            class="input page__inputGroupInput" 
                                                            id="input011" 
                                                        >
                                                        <bs-select
                                                            v-else
                                                            :options="circuit_list"
                                                            :nullOption="true"
                                                            v-model="variant.join_3hour_circuit_id">
                                                        </bs-select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-2 border-bottom border-dotted">
                                                <div class="col-12 col-sm-12 col-md-3 mb-2">
                                                    <div class="input-group">
                                                        <span class="">ライセンス番号</span
                                                        ><span class="required-label" v-if="!chk_3hour_circuit_mfj(variant.join_3hour_circuit_id)">*</span>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-12 col-md-6 mb-2">
                                                    <div class="input-group">
                                                        <input class="input page__inputGroupInput" id="input012" v-model="variant.join_3hour_circuit_license_no" :readonly="input_readonly('m_rd_3hour')" type="text">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-2 border-bottom border-dotted">
                                                <div class="col-12 col-sm-6 col-md-3 mb-2">
                                                    <div class="input-group">
                                                        <span class="">ライセンス証</span
                                                        ><span class="required-label" v-if="!chk_3hour_circuit_mfj(variant.join_3hour_circuit_id)">*</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 col-sm-6 col-md-4 mb-2">
                                                    <div class="input-group">
                                                        <div class="" v-if="(app_form_obj || {}).path10">
                                                            <img
                                                                :src="(app_form_obj || {}).path10"
                                                                class="w-100"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-6 col-sm-6 col-md-5 mb-2">
                                                    <div class="input-group" v-if="!confirm_mode || !chk_app_checked('path10')">
                                                        <button v-if="!confirm_mode || !chk_app_checked('path10')" type="button" @click="clickUpFileBtn(100, 10)" class="file page__inputGroupInput photo">
                                                            &nbsp;ファイルを選択
                                                            <font-awesome-icon
                                                                :icon="['fas', 'chevron-right']"
                                                                class="mx-2 photo__icon"
                                                            />
                                                        </button>
                                                        <input type="file" class="form-control" :id="'file_up_input_100_path10'" @change="selectedFile($event, 100, 10, false)" accept="image/*" style="display: none;">
                                                        <div v-if="get_target_com_msg('path10')" class="py-2">
                                                            <p class="box text-start">{{ get_target_com_msg('path10') }}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-2 border-bottom border-dotted">
                                                <div class="col-12 col-sm-6 col-md-3 mb-2">
                                                    <div class="input-group">
                                                        <span class="">サーキットライセンス講習会専用申請書、3時間走行証明書、競技会成績証明書</span
                                                        ><span class="required-label" v-if="chk_3hour_circuit_mfj(variant.join_3hour_circuit_id)">*</span>
                                                    </div>
                                                </div>
                                                <div class="col-6 col-sm-6 col-md-4 mb-2">
                                                    <div class="input-group">
                                                        <div class="" v-if="(app_form_obj || {}).path11">
                                                            <img
                                                                :src="(app_form_obj || {}).path11"
                                                                class="w-100"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-6 col-sm-6 col-md-5 mb-2">
                                                    <div class="input-group" v-if="!confirm_mode || !chk_app_checked('path11')">
                                                        <button v-if="!confirm_mode || !chk_app_checked('path11')" type="button" @click="clickUpFileBtn(100, 11)" class="file page__inputGroupInput photo">
                                                            &nbsp;ファイルを選択
                                                            <font-awesome-icon
                                                                :icon="['fas', 'chevron-right']"
                                                                class="mx-2 photo__icon"
                                                            />
                                                        </button>
                                                        <input type="file" class="form-control" :id="'file_up_input_100_path11'" @change="selectedFile($event, 100, 11, false)" accept="image/*" style="display: none;">
                                                        <div v-if="get_target_com_msg('path11')" class="py-2">
                                                            <p class="box text-start">{{ get_target_com_msg('path11') }}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                    <div class="mb-40" v-if="chk_license_add && chk_license_join_seminar">
                                        <div class="row pb-2 mb-2 border-bottom border-secondary">
                                            <div class="col-12 mb-2">
                                                <div class="title02 page__h2">
                                                    講習会の受講について
                                                </div>
                                            </div>
                                            <div class="col-12 mb-2">
                                                <div class="row">
                                                    <div class="box box--center mb-24">
                                                        講習会の受講について、web講習、対面講習のいずれかを選択して下さい
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 mb-2">
                                                <div class="row" v-for="license_id in get_license_selected_variants_filter(
                                                    {
                                                        join_seminar_web: 1,
                                                        join_seminar_circuit: 1,
                                                    }
                                                    )"
                                                    :key="license_id">
                                                    <div
                                                        class="row mb-2"
                                                        v-for="variant in get_selected_variants_filter(
                                                        {
                                                            join_seminar_web: 1,
                                                            join_seminar_circuit: 1,
                                                        },
                                                        {
                                                            license_id: license_id,
                                                            category: 4,
                                                        },
                                                        1
                                                        )"
                                                        :key="variant"
                                                    >
                                                        <div class="mb-3">
                                                            {{ variant.license_label }}
                                                        </div>
                                                        <div class="page__inputGroup" v-if="form_mode || remand_mode">
                                                            <div class="page__inputGroupCol align-items-baseline">
                                                                <p class="page__inputGroupInner align-items-baseline">
                                                                    <button 
                                                                        type="button" 
                                                                        :class="['checkbox02__label', 'me-3', 'mb-3', { active: (variant || {}).join_seminar_type =='web'? false: true }]" 
                                                                        v-if="variant.join_seminar_web==1" 
                                                                        @click="attach_join_seminar_type(variant.variant_id, 'web')"
                                                                    >
                                                                    Web講習会を受講する場合はこちら
                                                                    </button>
                                                                    <span class="mr-3" v-if="variant.join_seminar_web==1 && variant.join_seminar_circuit==1">or</span>
                                                                    <button 
                                                                        type="button" 
                                                                        :class="['checkbox02__label', 'me-3', 'mb-3', 'ms-3', { active: (variant || {}).join_seminar_type =='circuit'? false: true }]" 
                                                                        v-if="variant.join_seminar_circuit==1" 
                                                                        @click="attach_join_seminar_type(variant.variant_id, 'circuit')"
                                                                    >
                                                                    対面講習会を受講済はこちら
                                                                    </button>
                                                                </p>
                                                            </div>
                                                            <div v-if="variant.join_seminar_type=='circuit'" class="page__inputGroupCol">
                                                                <p class="page__inputGroupInner">
                                                                    <label class="label page__inputGroupLabel" for="input014">受講した施設名</label>
                                                                    <input class="input page__inputGroupInput" type="text" v-model="variant.join_seminar_place" id="input014">

                                                                </p>
                                                            </div>
                                                            <div v-if="variant.join_seminar_type=='circuit'" class="page__inputGroupCol pb-3">
                                                                <p class="page__inputGroupInner">
                                                                    <label class="label page__inputGroupLabel" for="input014">受講日</label>
                                                                    <input class="file page__inputGroupInput" type="date" v-model="variant.join_seminar_date" id="input014">
                                                                </p>
                                                            </div>
                                                            <div class="input-group mb-2 pb-3" v-if="variant.join_seminar_type=='web'">
                                                                <p>
                                                                    Web講習会を受講される場合は、申請承認、お支払いの後、マイページから講習会動画を受講して下さい。
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div v-else-if="confirm_mode">
                                                            <div class="page__inputGroupCol align-items-baseline">
                                                                <p class="page__inputGroupInner align-items-baseline">
                                                                    <button 
                                                                        type="button" 
                                                                        :class="['checkbox02__label', 'me-3', 'mb-3', { active: false}]" 
                                                                        v-if="(variant || {}).join_seminar_type =='web'" 
                                                                        disabled="disabled"
                                                                    >
                                                                    Web講習会を受講予定
                                                                    </button>
                                                                    <button 
                                                                        type="button" 
                                                                        :class="['checkbox02__label', 'me-3', 'mb-3', { active: false}]" 
                                                                        v-if="(variant || {}).join_seminar_type =='circuit'" 
                                                                        disabled="disabled"
                                                                    >
                                                                    対面講習会を受講済
                                                                    </button>
                                                                </p>
                                                            </div>
                                                            <div v-if="variant.join_seminar_type=='circuit'" class="page__inputGroupCol">
                                                                <p class="page__inputGroupInner">
                                                                    <label class="label page__inputGroupLabel" for="input014">受講した施設名</label>
                                                                    <input 
                                                                        :value="get_list_label(variant.join_seminar_place, circuit_list)" 
                                                                        readonly="readonly" 
                                                                        class="input page__inputGroupInput" 
                                                                        id="input011" 
                                                                        disabled="disabled"
                                                                    >
                                                                    <!-- <input class="file page__inputGroupInput" type="text" v-model="variant.join_seminar_place" id="input014" disabled="disabled"> -->
                                                                </p>
                                                            </div>
                                                            <div v-if="variant.join_seminar_type=='circuit'" class="page__inputGroupCol pb-3">
                                                                <p class="page__inputGroupInner">
                                                                    <label class="label page__inputGroupLabel" for="input014">受講日</label>
                                                                    <input class="file page__inputGroupInput" type="date" v-model="variant.join_seminar_date" id="input014" disabled="disabled" readonly="readonly" >
                                                                </p>
                                                            </div>
                                                            <div class="input-group mb-2 pb-3" v-if="variant.join_seminar_type=='web'">
                                                                <p>
                                                                    Web講習会を受講される場合は、申請承認、お支払いの後、マイページから講習会動画を受講して下さい。
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-12" v-if="false">
                                                <div class="row mb-2 border-bottom border-dotted" v-for="variant in get_selected_variants_filter(
                                                    {join_seminar_web: 1,
                                                    join_seminar_circuit: 1
                                                    }, {}, 1)" :key="variant">
                                                    <div class="mb-3">
                                                        {{ variant.license_label }}
                                                    </div>
                                                    <div class="page__inputGroup" v-if="form_mode || remand_mode">
                                                        <div class="page__inputGroupCol align-items-baseline">
                                                            <p class="page__inputGroupInner align-items-baseline">
                                                                <button 
                                                                    type="button" 
                                                                    :class="['checkbox02__label', 'me-3', 'mb-3', { active: (variant || {}).join_seminar_type =='web'? false: true }]" 
                                                                    v-if="variant.join_seminar_web==1" 
                                                                    @click="attach_join_seminar_type(variant.variant_id, 'web')"
                                                                >
                                                                Web講習会を受講する
                                                                </button>
                                                                <span class="mr-3" v-if="variant.join_seminar_web==1 || variant.join_seminar_circuit==1">or</span>
                                                                <button 
                                                                    type="button" 
                                                                    :class="['checkbox02__label', 'me-3', 'mb-3', 'ms-3', { active: (variant || {}).join_seminar_type =='circuit'? false: true }]" 
                                                                    v-if="variant.join_seminar_circuit==1" 
                                                                    @click="attach_join_seminar_type(variant.variant_id, 'circuit')"
                                                                >
                                                                対面講習会を受講済
                                                                </button>
                                                            </p>
                                                        </div>
                                                        <div v-if="variant.join_seminar_type=='circuit'" class="page__inputGroupCol">
                                                            <p class="page__inputGroupInner">
                                                                <label class="label page__inputGroupLabel" for="input014">受講した施設名</label>
                                                                <bs-select
                                                                    :options="circuit_list"
                                                                    :nullOption="true"
                                                                    v-model="variant.join_seminar_place">
                                                                </bs-select>
                                                            </p>
                                                        </div>
                                                        <div v-if="variant.join_seminar_type=='circuit'" class="page__inputGroupCol pb-3">
                                                            <p class="page__inputGroupInner">
                                                                <label class="label page__inputGroupLabel" for="input014">受講日</label>
                                                                <input class="file page__inputGroupInput" type="date" v-model="variant.join_seminar_date" id="input014">
                                                            </p>
                                                        </div>
                                                        <div class="input-group mb-2 pb-3" v-if="variant.join_seminar_type=='web'">
                                                            <p>
                                                                Web講習会を受講される場合は、申請承認、お支払いの後、マイページから講習会動画を受講して下さい。
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div v-else-if="confirm_mode">
                                                        <div class="page__inputGroupCol align-items-baseline">
                                                            <p class="page__inputGroupInner align-items-baseline">
                                                                <button 
                                                                    type="button" 
                                                                    :class="['checkbox02__label', 'me-3', 'mb-3', { active: false}]" 
                                                                    v-if="(variant || {}).join_seminar_type =='web'" 
                                                                    disabled="disabled"
                                                                >
                                                                Web講習会を受講予定
                                                                </button>
                                                                <button 
                                                                    type="button" 
                                                                    :class="['checkbox02__label', 'me-3', 'mb-3', { active: false}]" 
                                                                    v-if="(variant || {}).join_seminar_type =='circuit'" 
                                                                    disabled="disabled"
                                                                >
                                                                対面講習会を受講済
                                                                </button>
                                                            </p>
                                                        </div>
                                                        <div v-if="variant.join_seminar_type=='circuit'" class="page__inputGroupCol">
                                                            <p class="page__inputGroupInner">
                                                                <label class="label page__inputGroupLabel" for="input014">受講した施設名</label>
                                                                <input 
                                                                    :value="get_list_label(variant.join_seminar_place, circuit_list)" 
                                                                    readonly="readonly" 
                                                                    class="input page__inputGroupInput" 
                                                                    id="input011" 
                                                                    disabled="disabled"
                                                                >
                                                                <!-- <input class="file page__inputGroupInput" type="text" v-model="variant.join_seminar_place" id="input014" disabled="disabled"> -->
                                                            </p>
                                                        </div>
                                                        <div v-if="variant.join_seminar_type=='circuit'" class="page__inputGroupCol pb-3">
                                                            <p class="page__inputGroupInner">
                                                                <label class="label page__inputGroupLabel" for="input014">受講日</label>
                                                                <input class="file page__inputGroupInput" type="date" v-model="variant.join_seminar_date" id="input014" disabled="disabled" readonly="readonly" >
                                                            </p>
                                                        </div>
                                                        <div class="input-group mb-2 pb-3" v-if="variant.join_seminar_type=='web'">
                                                            <p>
                                                                Web講習会を受講される場合は、申請承認、お支払いの後、マイページから講習会動画を受講して下さい。
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="cash_unpaid_mode">
                                    <h5>コンビニお支払い情報</h5>
                                    <div>
                                        <ul v-if="has_receipt_data" class="list-group">
                                        <li
                                            v-for="(value, key) in receipt"
                                            :key="key"
                                            class="list-group-item d-flex justify-content-between"
                                        >
                                            <span class="fw-bold text-capitalize" v-if="key=='konbiniCode'">コンビニ</span>
                                            <span class="fw-bold text-capitalize" v-else-if="key=='receiptNumber'">お客様番号</span>
                                            <span class="fw-bold text-capitalize" v-else-if="key=='confirmationNumber'">確認番号</span>
                                            <span class="text-muted">{{ value }}</span>
                                        </li>
                                        </ul>
                                        <h2>各社のご案内</h2>
                                        <ul>
                                        <li v-for="(link, index) in links" :key="index">
                                            <a :href="link.href" target="_blank">{{ link.text }}</a>
                                        </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="container px-0" v-else-if="!success_mode && !check_mode">
                                    <cart-view
                                        :selected_variants="selected_variants"
                                        :race_sports_year="race_sports_year_obj"
                                        :draft_orders="draft_order_obj"
                                    ></cart-view>
                                </div>
                                
                                <div class="container px-0" v-if="charge_mode">  
                                    <!-- 支払い方法 -->
                                    <div class="mb-3">
                                        <!-- {{ (sales_obj || {}).charge_order_id }}<br>

                                        {{ (sales_obj || {}).charge_challenge }}<br>
                                        {{ get_order }}<br>
                                        {{ get_merchant }} -->
                                        <div class="container mb-4 pb-4 border-bottom">
                                            <div class="row">
                                                <div class="col" v-for="type in charge_type_list" :key="type">
                                                <div class="d-grid">
                                                    <button
                                                    type="button"
                                                    :class="get_class_charge_type(type.key)"
                                                    @click="sel_charge_type(type.key)"
                                                    >
                                                    {{ type.label }}で支払う
                                                    </button>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="container">
                                            <!-- {{ get_order }} -->
                                            <div class="row" v-show="selected_charge_type == 'creditcard'">
                                                <h3 class="title02 page__h2">クレジットカード</h3>
                                                <div class="col-10 offset-1 col-lg-8 offset-lg-2 mb-10">
                                                <div class="row">
                                                    <div class="col-12 col-sm-6 col-md-8 mb-10 text-center">
                                                    ご利用可能カードブランド
                                                    </div>
                                                    <div class="col-12 col-sm-6 col-md-4 mb-10 text-center">
                                                    <img :src="assets_url + '/base/creditcard_checkout_logo.png'" class="w-75">
                                                    </div>
                                                </div>
                                                <!-- <img :src="(data_obj || {}).path3" class="w-100" /> -->
                                                </div>
                                                <div class="col-10 offset-1 col-lg-8 offset-lg-2">
                                                    <!-- {{ credit_result_obj }} -->
                                                    <label for="card-number">カード番号:</label>
                                                    <div id="card-number" class="card-form__div" />
                                                    <label for="expiry-month">有効期限:</label>
                                                    <div id="expiry-month" class="card-form__div" />
                                                    <label for="security-code">セキュリティコード:</label>
                                                    <div id="security-code" class="card-form__div" />
                                                    <!-- <div class="row mb-5">
                                                    <div class="col-4">カード名義人</div>
                                                    <div class="col-8">
                                                        <input
                                                        type="text"
                                                        v-model="credit_card_obj.holdername"
                                                        class="form-control"
                                                        />
                                                    </div>
                                                    </div> -->
                                                </div>
                                            </div>
                                            <div class="row" v-show="selected_charge_type == 'cash'">
                                                <h3 class="title02 page__h2">コンビニ支払い</h3>
                                                <div class="col-10 offset-1 col-lg-8 offset-lg-2">
                                                    <KonbiniForm :on-submit="charge_cash" :ini_payer="get_payer" />
                                                    <h2>コンビニ支払い情報</h2>
                                                    <ul v-if="has_receipt_data" class="list-group">
                                                    <li
                                                        v-for="(value, key) in receipt"
                                                        :key="key"
                                                        class="list-group-item d-flex justify-content-between"
                                                    >
                                                        <span class="fw-bold text-capitalize">{{ key }}</span>
                                                        <span class="text-muted">{{ value }}</span>
                                                    </li>
                                                    </ul>
                                                    <h2>各社のご案内</h2>
                                                    <ul>
                                                    <li v-for="(link, index) in links" :key="index">
                                                        <a :href="link.href" target="_blank">{{ link.text }}</a>
                                                    </li>
                                                    </ul>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="license_mode" class="d-flex flex-wrap mt-32 justify-content-center column-gap-24">
                                    <button class="button03 mb-3 license__btn" type="button" @click="back">
                                        <i class="button03__icon fa-solid fa-chevron-left"></i>
                                        <font-awesome-icon
                                            :icon="['fas', 'chevron-left']"
                                            class="button03__icon"
                                        />
                                        <span class="button03__text">戻る</span>
                                    </button>
                                    <button class="button04 mb-3 license__btn" type="button" @click="go_app" v-if="typeof selected_variants === 'object' && selected_variants.length > 0">
                                        <span class="button04__text">選択したライセンスで申請を続ける</span>
                                        <i class="button04__icon fa-solid fa-chevron-right"></i>
                                        <font-awesome-icon
                                            :icon="['fas', 'chevron-right']"
                                            class="button04__icon"
                                        />
                                    </button>
                                    <button v-else class="button03 mb-3 license__btn" disabled="disabled">
                                        <span class="button04__text">ライセンスを選択して下さい</span>
                                    </button>
                                </div>
                                <div v-else-if="form_mode || remand_mode" class="d-flex flex-wrap mt-32 justify-content-center column-gap-24">
                                    <button class="button03 mb-3 license__btn" type="button" @click="back_mode('sel_license')" v-if="!remand_mode">
                                        <i class="button03__icon fa-solid fa-chevron-left"></i>
                                        <font-awesome-icon
                                            :icon="['fas', 'chevron-left']"
                                            class="button03__icon"
                                        />
                                        <span class="button03__text">ライセンス選択に戻る</span>
                                    </button>
                                    <button class="button04 mb-3 license__btn" type="button" 
                                        v-if="chk_confirm_btn"
                                    @click="go_confirm">
                                        <span class="button04__text">確認する</span>
                                        <i class="button04__icon fa-solid fa-chevron-right"></i>
                                        <font-awesome-icon
                                            :icon="['fas', 'chevron-right']"
                                            class="button04__icon"
                                        />
                                    </button>
                                    <button v-else class="button03 mb-3 license__btn" disabled="disabled">
                                        <span class="button04__text">確認する</span>
                                    </button>
                                </div>
                                <div v-else-if="confirm_mode" class="d-flex flex-wrap mt-32 justify-content-center column-gap-24">
                                    <button class="button03 mb-3 license__btn" type="button" @click="back_mode('remand')" v-if="(this.application_obj || {}).application_check_type == 600">
                                        <i class="button03__icon fa-solid fa-chevron-left"></i>
                                        <font-awesome-icon
                                            :icon="['fas', 'chevron-left']"
                                            class="button03__icon"
                                        />
                                        <span class="button03__text">入力画面に戻る</span>
                                    </button>
                                    <button class="button03 mb-3 license__btn" type="button" @click="back_mode('form')" v-else>
                                        <i class="button03__icon fa-solid fa-chevron-left"></i>
                                        <font-awesome-icon
                                            :icon="['fas', 'chevron-left']"
                                            class="button03__icon"
                                        />
                                        <span class="button03__text">入力画面に戻る</span>
                                    </button>
                                    <button class="button04 mb-3 license__btn" type="button" @click="go_update">
                                        <span class="button04__text" v-if="(this.application_obj || {}).application_check_type == 600">再申請する</span>
                                        <span class="button04__text" v-else>申請する</span>
                                        <i class="button04__icon fa-solid fa-chevron-right"></i>
                                        <font-awesome-icon
                                            :icon="['fas', 'chevron-right']"
                                            class="button04__icon"
                                        />
                                    </button>
                                </div>
                                <div v-else-if="charge_mode && selected_charge_type == 'creditcard'" class="d-flex flex-wrap mt-32 justify-content-center column-gap-24">
                                    <!-- <button class="button03 mb-3 license__btn" type="button" @click="back_mode('form')">
                                        <i class="button03__icon fa-solid fa-chevron-left"></i>
                                        <font-awesome-icon
                                            :icon="['fas', 'chevron-left']"
                                            class="button03__icon"
                                        />
                                        <span class="button03__text">入力画面に戻る</span>
                                    </button> -->
                                    <button class="button04 mb-3 license__btn" type="button" @click="go_charge">
                                        <span class="button04__text">支払う</span>
                                        <i class="button04__icon fa-solid fa-chevron-right"></i>
                                        <font-awesome-icon
                                            :icon="['fas', 'chevron-right']"
                                            class="button04__icon"
                                        />
                                    </button>
                                    <div class="">
                                        <div class="box ">
                                            <h5 class="title06 page__h6">クレジットカード決済ができない件について</h5>
                                            <p class=" text-start">
                                            MFJでは、みなさまに安心してWEB会員サービスをご利用いただくために「3Dセキュア（本人認証サービス）」（以下、3Dセキュア）を導入しております。<br>
                                            3Ｄセキュアとは、カード決済の際に、必要に応じて「本人確認パスワード＊」等の入力により本人確認を行うものです。<br>
                                            この3Dセキュアの設定がお済でないクレジットカードはお使いいただけない場合がございます。<br>
                                            その場合は、他のクレジットカードをご利用いただくか、コンビニ決済をご利用いただけますようお願いいたします。<br>
                                            ※3Dセキュアにつきましては、各クレジットカード会社へお問合せ頂きますようお願いいたします。
                                            </p>
                                        </div>
                                        </div>
                                </div>
                                <div v-else-if="success_mode" class="d-flex flex-wrap mt-32 justify-content-center column-gap-24">
                                    <button class="button03 mb-3 license__btn" type="button" @click="go_dashboard()">
                                        <i class="button03__icon fa-solid fa-chevron-left"></i>
                                        <font-awesome-icon
                                            :icon="['fas', 'chevron-left']"
                                            class="button03__icon"
                                        />
                                        <span class="button03__text">HOMEへ戻る</span>
                                    </button>
                                </div>
                                <div v-else-if="!check_mode" class="d-flex flex-wrap mt-32 justify-content-center column-gap-24">
                                    <button class="button03 mb-3 license__btn" type="button" @click="go_dashboard()">
                                        <i class="button03__icon fa-solid fa-chevron-left"></i>
                                        <font-awesome-icon
                                            :icon="['fas', 'chevron-left']"
                                            class="button03__icon"
                                        />
                                        <span class="button03__text">戻る</span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="container pt-5">
            <div class="row">
                <div class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2">
                    <div class="box">
                        招待選手はライセンス追加・継続申請はできません
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>
.photo {
    text-align: left;
    font-weight: 700;
}
.photo__icon {
    position: absolute;
    top: 30%;
    right: 20px;
}
@media (max-width: 767.98px) {
    .license__btn {
        width: 100%;
    }
}
.card-form__div {
    border: 1px solid #ccc;
    border-radius: 5px;
}
</style>

<script>
import { mapState } from 'vuex'
import { usePaymentPage } from '@ootbteam/mulpay-pinia'
// import BsPageTitle from '../../molecules/BsPageTitle.vue'
// import BsMsgAlert from '../../atoms/BsMsgAlert.vue'
// import BsSelect from '../../atoms/BsSelect.vue'
// import BsCheckBox from '../../atoms/BsCheckBox.vue'
// import '../../assets/bootstrap/css/sc.css';
import CartView from './CartView.vue'
import BsSelect from '../../atoms/BsSelect.vue'
import Common from '../../../common/common'
import config from '../../../const'
import moment from "moment"
import axios from 'axios'
import KonbiniForm from '@/components/forms/KonbiniForm.vue'
import {
  cardInputStyle,
  cvcInputStyle,
  expiryInputStyle
} from '@/utils/cardstyle'

export default {
    name: 'LicenseView',
    data: function () {
        return {
            targetid: this.$store.state.auth.userId,
            btnLabel: '',
            data_obj: {},
            form_obj: {},
            app_form_obj: {},
            draft_order_obj: [],
            change_login_id: '',
            confirm_login_id: '',
            change_mode_value: false,
            page_mode: 'sel_license',
            base_message: '読込み中です',
            message: '',
            pref_obj: [],
            request_type_cd_obj: [],
            identify_type_cd_obj: [],
            application_obj: {},
            application_type_obj: [],
            application_check_type_obj: [],
            member_document_cd_obj: [],
            license_category_obj: [],
            race_sports_year_obj: [],
            term_year_obj: [],
            term_date_obj: [],
            now_date_obj: [],
            circuit_obj: [],
            selected_package_flg: 0,
            // select license用の選択内容保存変数
            license_sel_frm_mode: 'year',
            selected_year: [],
            chk_license_category: {
                1: false,   //競技
                2: false,   //ピットクルー
                4: false,   //役員
                5: false,   //講師
                3: false,   //エンジョイ
            },
            selected_variants: [],
            have_variants: [],
            have_license: [],
            selected_variants_license_category: {
                1: [],
                2: [],
                3: [],
                4: [],
                5: [],
            },
            selected_variants_insurance: [], //保険
            selected_variants_commission: [], //手数料

            selected_license_category_no: '',

            article_variant_obj: [],
            article_obj: [],

            receipt: undefined,
            links: [
            {
                code: 'SEVEN_ELEVEN',
                text: 'セブンイレブン',
                href: 'https://static.mul-pay.jp/customer-convenience-store/seveneleven/index.html',
            },
            {
                code: 'LAWSON',
                text: 'ローソンの「Loppi」を使ってお支払い（お客様番号が11桁の場合）',
                href: 'https://static.mul-pay.jp/customer-convenience-store/lawson/11.html',
            },
            {
                code: 'LAWSON',
                text: 'ローソンの「Loppi」を使ってお支払い（お客様番号が14桁の場合）',
                href: 'https://static.mul-pay.jp/customer-convenience-store/lawson/14.html',
            },
            {
                code: 'FAMILYMART',
                text: 'ファミリーマートの「マルチコピー機」を使ってお支払い（第1番号11桁の場合）',
                href: 'https://www.family.co.jp/services/application/receipt.html',
            },
            {
                code: 'FAMILYMART',
                text: 'ファミリーマートの「マルチコピー機」を使ってお支払い（第2番号12桁の場合）',
                href: 'https://www.family.co.jp/services/application/receipt.html',
            },
            {
                code: 'MINISTOP',
                text: 'ミニストップの「Loppi」を使ってお支払い（お客様番号が11桁の場合）',
                href: 'https://static.mul-pay.jp/customer-convenience-store/ministop/11.html',
            },
            {
                code: 'MINISTOP',
                text: 'ミニストップの「Loppi」を使ってお支払い（お客様番号が14桁の場合）',
                href: 'https://static.mul-pay.jp/customer-convenience-store/ministop/14.html',
            },
            // {
            //     code: 'DAILYYAMAZAKI',
            //     text: 'デイリーヤマザキの「POSレジ」を使ってお支払い',
            //     href: 'https://static.mul-pay.jp/customer-convenience-store/dailyyamazaki/index.html',
            // },
            {
                code: 'SEICOMART',
                text: 'セイコーマートの「POSレジ」を使ってお支払い',
                href: 'https://static.mul-pay.jp/customer-convenience-store/seicomart/',
            },
            ],

            license_cd_obj: [],

            sales_obj: {},
            communication_obj: {},
            communication_checked: [],
            now_year: parseInt(Common.getNowYear()),

            // 支払い
            merchant_obj: {},
            charge_btn_disabled: false,
            selected_charge_type: 'creditcard',
            charge_type_list: [
                {
                    key: 'creditcard',
                    label: 'クレジットカード'
                },
                {
                    key: 'cash',
                    label: 'コンビニ'
                },
            ],
            credit_card_obj: {
                cardno: '222222222222',
                expire_y: '2027',
                expire_m: '4',
                securitycode: '123',
                holdername: 'TEST TARO',
                tokennumber: '1'
            },
            BASE_ASSETS_URL: config.BASE_ASSETS_URL,
            assets_url: config.BASE_ASSETS_URL,
            errorMessage: '',
            year: new Date().getFullYear(),
            isLoading: false,
        }
    },
    setup() {
      const {
        handlePaymentPageProps,
        setPaymentPayload,
        setCardPaymentPayload,
        cardPayment,
        cashPayment,
        generateSimpleRandomString,
        setElements,
        clear,
      } = usePaymentPage()

      return {
        handlePaymentPageProps,
        setPaymentPayload,
        setCardPaymentPayload,
        cardPayment,
        cashPayment,
        generateSimpleRandomString,
        setElements,
        clear,
      }
    },
    components: {
        BsSelect,
        CartView,
        KonbiniForm,
    //   BsMsgAlert,
        // BsCheckBox,
    },
    methods: {
        async go_app(){
            this.isLoading = true
            try {
                await new Promise(resolve => setTimeout(resolve, 500))

                // selectの中で追加があるか確認
                Common.moveToTop();
                this.page_mode = 'form';
                this.message = '申請に必要な情報を入力・選択して下さい。'
                // スクロールしてページの先頭に移動
                window.scrollTo({ top: 0, behavior: 'smooth' });
            } finally {
            this.isLoading = false
            }
        },
        async go_confirm(){
            this.isLoading = true
            try {
                await new Promise(resolve => setTimeout(resolve, 500))

                const result = this.selected_variants;

                // 継続申請の場合
                if (result.length > 0 && result[0].category === "5") {
                    // 顔写真のパスを取得
                    const photoPath = this.app_form_obj.path3 || this.get_assets(100, 0).path;
                    // 顔写真が選択されているかチェック
                    if (!photoPath) {
                        this.errorMessage = '顔写真を選択してください。';
                        return;
                    }
                } 
                // エラーがない場合、確認ページに遷移
                this.page_mode = 'confirm';
                this.errorMessage = '';

                // スクロールしてページの先頭に移動
                Common.moveToTop();
                // window.scrollTo({ top: 0, behavior: 'smooth' });
            } finally {
            this.isLoading = false
            }
        },
        async go_update(){
            this.isLoading = true
            try {
                await new Promise(resolve => setTimeout(resolve, 500))

                Common.moveToTop();
                await this.update_app();
                // this.page_mode = 'check';
            } finally {
            this.isLoading = false
            }
        },
        async attach_join_seminar_type(variant_id, seminar_type){
            const variants = [...this.selected_variants];
            const idx = (typeof variants === 'object')? variants.findIndex(vr => (vr.variant_id == variant_id)): false;
            console.log('attach_join_seminar_type', variant_id, variants, idx, seminar_type);
            if(idx !== false){
                variants[idx].join_seminar_type = seminar_type;
            }
            console.log('attach_join_seminar_type2', variants, idx);
            this.selected_variants = variants;
        },

        async update_sales_order_id(to_type){
            const sales_id = (
            (this.sales_obj || {}).id
            )? (this.sales_obj || {}).id: false
            if(!sales_id) {
                alert('データがありません')
                this.selected_charge_type = 'creditcard'
                return 
            }
            
            const order_id = (
            (this.sales_obj || {}).charge_order_id
            )? (this.sales_obj || {}).charge_order_id: false
            if(!order_id) {
                alert('決済IDがありません')
                this.selected_charge_type = 'creditcard'
            return 
            }
            let up_order_id = order_id
            const chk_p_1 = order_id.slice(20)
            const chk_p_2 = (chk_p_1)? chk_p_1.indexOf('C'): false
            // クレジット->コンビニ
            if(
                to_type == 'cash'
                && this.selected_charge_type == 'creditcard'
            ){
                if(
                    chk_p_2 !== false && chk_p_2 !== -1
                    // order_id.includes('-')
                ){
                    let splitPos = chk_p_2 + 20;
                    up_order_id = order_id.slice(0, splitPos) + 'K'
                    // const tmps = order_id.split('-')
                    // up_order_id = tmps[0] + 'K'
                }
                else if(
                    !order_id.endsWith('K')
                ){
                    up_order_id = order_id + 'K'
                }
            }
            // コンビニ->クレジット
            else if(
                to_type == 'creditcard'
                && this.selected_charge_type == 'cash'
            ){
                if(
                    chk_p_2 !== false && chk_p_2 !== -1
                    // order_id.includes('-')
                ){
                    let splitPos = chk_p_2 + 20;
                    up_order_id = order_id.slice(0, splitPos)
                    // const tmps = order_id.split('-')
                    // up_order_id = tmps[0]
                }
                else if(
                    order_id.endsWith('K')
                ){
                    up_order_id = order_id.slice(0, -1)
                }

                // count
                if(
                    (this.sales_obj || {}).charge_challenge > 0
                ){
                    up_order_id += 'C' + (this.sales_obj || {}).charge_challenge
                }
            }
            else{
                this.selected_charge_type = to_type
                return
            }

            this.selected_charge_type = to_type
            // sales update
            const sales_url_path =
            sales_id > 0
                ? '/sales/edit/' + sales_id
                : false
            const sales_params = {
                charge_type: (to_type=='cash')? 'KONBINI': 'CREDIT',
                charge_order_id: up_order_id,
            }
            const chk = (sales_url_path)? await this.nonloginPosts_I(
                config.BASE_API_URL + sales_url_path,
                sales_params
            ): false
            if(chk){
                return up_order_id
            }
            return chk
        },
        async sel_charge_type(charge_type) {
            this.isLoading = true
            try {
                await new Promise(resolve => setTimeout(resolve, 500))
                
                await this.update_sales_order_id(charge_type)
                await this.loadApplicationObj()
                await this.setSalesObj()
            } finally {
            this.isLoading = false
            }
        },
        async go_charge(){
            this.isLoading = true
            try {
                await new Promise(resolve => setTimeout(resolve, 500))

                if(
                    this.selected_charge_type == 'creditcard'
                ){
                    await this.charge_credit()
                }
                else if(
                    this.selected_charge_type == 'cash'
                ){
                    await this.charge_cash()
                }
            } finally {
            this.isLoading = false
            }
        },
        async charge_cash({ name, nameKana, phoneNumber, code }) {   
            this.charge_btn_disabled = true
            // 
            const payer_tmp = {...this.get_payer}
            payer_tmp.name = name
            payer_tmp.nameKana = nameKana
            payer_tmp.phones = [
                {
                    type: 'MOBILE',
                    countryCode: '81',
                    number: phoneNumber,
                },
            ]
            const payload = {
                payer: payer_tmp,
                merchant: this.get_merchant,
                order: this.get_order,
                cashOptions: {
                    konbiniCode: code,
                    // 支払い期限日数
                    paymentPeriod: '10',
                },
            }
            console.log('testtest payload', payload)
            // バリデーション制約上の必要な情報を設定
            await this.setPaymentPayload(payload).catch(alert)

            /**
             * コンビニ決済の支払い完了通知は、決済代行会社からリクエストが送信（以下、webhook）されるため、情報の引き継ぎが課題になる。
             * `webhook`は、ステートレスなリクエストになるため、
             * コンビニ決済の要求時に必要な情報をAPIのリクエストに乗せて、データベースに更新する。
             */
            const formToken = ''
            const formTokenId = (this.application_obj || {}).id
            const formSalesId = (this.sales_obj || {}).id
            console.log('formToken formTokenId formSalesId', formToken, formTokenId, formSalesId)
            await this.cashPayment(
                formToken,
                formTokenId,
                formSalesId,
                async (payload) => {
                    // コンビニ支払い番号を表示
                    console.log('testtest payload cashPayment', payload)
                    const { cashResult } = payload
                    this.receipt = cashResult.konbiniPaymentInformation
                    console.log('testtest receipt', this.receipt)
                    const chk = await this.update_sales_receipt(formSalesId)
                    if(chk){
                        await this.update_app_check(420)
                    }
                }
            ).catch(async (error) => {
                alert(error)
                await this.update_app_check(400)
                // await this.loadRegistData()
            })
        },
        async charge_credit() {
            this.charge_btn_disabled = true
            // バリデーション制約上の必要な情報を設定
            await this.setCardPaymentPayload({
            payer: this.get_payer,
            merchant: this.get_merchant,
            order: this.get_order,
            }).catch(alert)
            await this.update_app_check(403)
            await this.cardPayment((payload) => {
                console.log(
                'selected_charge_type=creditcard cardPayment payload ',
                payload
                )
                const { redirectInformation, orderReference } = payload

                // リロード問題を回避するための暫定対処コード
                if(!orderReference?.accessId) {
                //   alert(payload)
                    alert(payload + '\n3Dセキュア決済が失敗しました。カード情報をご確認下さい。')
                  return;
                }

                localStorage.setItem('accessId', orderReference.accessId)
                localStorage.setItem('formTokenId', (this.application_obj || {}).id)
                localStorage.setItem('formSalesId', (this.sales_obj || {}).id)
                location.href = redirectInformation.redirectUrl

            }).catch(async (error) => {
                let error_json = error?.message
                console.log('error', error_json);
                if(
                    this.is_json(error_json)
                ){
                    const errorCode = []
                    const parsedError = JSON.parse(error_json)
                    if(parsedError){
                        for(const key in parsedError){
                            if(parsedError[key].legacyCode && !errorCode.includes(parsedError[key].legacyCode)){
                                errorCode.push(parsedError[key].legacyCode)
                            }
                        }
                    }
                    const error_msg = 'カード決済エラーです。' + (Array.isArray(errorCode) && errorCode.length > 0)? 'エラーコード:' + errorCode.join(","): ''
                    alert(error_msg + 'カード情報を確認して下さい。')
                    // console.log(errorCode)
                }else{
                    alert('カード決済エラーです。カード情報を確認して下さい。')
                }
                // console.log(error)
                if(
                    this.get_base_env == 'production'
                ){
                    // await this.update_app_check(400)
                }else{
                    // await this.charge_challenge_countup((this.sales_obj || {}).id)
                }
                // await this.loadApplicationObj()
            })
        },

        async member_license_update() {
            const member = { ...this.data_obj }

            const up_license =
            typeof member.member_license === 'object' ? [...member.member_license] : []
            const up_insurance = 
            typeof member.insurance === 'object' ? [...member.insurance]: []
            const join_license_tmp = []

            const sales_obj = (
                typeof (this.application_obj || {}).sales === 'object' &&
                Object.keys(this.application_obj.sales).length > 0
            )? { ...(this.application_obj || {}).sales }
            : {};
            // licenseが正しいかチェックをする予定
            let fix_flg = false
            if (typeof sales_obj.sales_orders === 'object') {
                sales_obj.sales_orders.forEach((order) => {
                    const start = moment().add(1, 'd').format('YYYY-MM-DD')
                    const start_num = parseInt(moment().add(1, 'd').format('YYYYMMDD'))
                    // const start_MD = moment().add(1, 'd').format('MMDD')
                    const end = moment({
                        years: parseInt(order.sports_year) + 1,
                        months: 2,
                        days: 31,
                    }).format('YYYY-MM-DD')
                    if (order.license_id > 0) {
                        const seminar_type = this.draft_order_obj?.find(draft => (
                            draft.application_id == order.application_id && draft.variant_id == order.variant_id)
                            )
                        ? this.draft_order_obj?.find(draft => (
                            draft.application_id == order.application_id && draft.variant_id == order.variant_id))?.join_seminar_type
                        : false
                        
                        const license_start = 
                            parseInt(order.sports_year + '0401') > start_num
                            ? order.sports_year + '-04-01'
                            : start
                        const fixed = (order.join_3hour == 1)
                            ? 1
                            : (seminar_type == 'circuit')
                            ? 1
                            : (seminar_type == 'web')
                            ? 0
                            : (seminar_type != 'circuit' && seminar_type != 'web')
                            ? 1
                            : 1
                        fix_flg = (fixed==1)? true: fix_flg
                        console.log('member_license_update order', order)
                        console.log('member_license_update draft_order_obj', this.draft_order_obj)
                        console.log('member_license_update fixed', seminar_type, fixed, order.license_id)

                        up_license.push({
                            id: 0,
                            stat: 1,
                            m_id: member.id,
                            fixed: fixed,
                            payment_flg: this.selected_charge_type == 'creditcard' ? 1 : 0,
                            license_cd: order.license_id,
                            license_year: order.sports_year,
                            license_term_start_date: license_start,
                            license_term_end_date: end,
                        })
                        join_license_tmp.push(parseInt(order.license_id))
                        // 基本的にピットクルーが付帯ライセンスなので、講習はなしと考えて良いはず
                        if (
                            order.sub_license_id &&
                            parseInt(order.sub_license_id) > 0 &&
                            !join_license_tmp.includes(parseInt(order.sub_license_id))
                        ) {
                            up_license.push({
                            id: 0,
                            stat: 1,
                            m_id: member.id,
                            // PITピットクルーは講習なしなのでfixさせる
                            fixed: 1,
                            payment_flg:
                                this.selected_charge_type == 'creditcard' ? 1 : 0,
                            license_cd: order.sub_license_id,
                            license_year: order.sports_year,
                            license_term_start_date: license_start,
                            license_term_end_date: end,
                            })
                            join_license_tmp.push(parseInt(order.sub_license_id))
                        }
                    }
                    else if (order.license_id == 0 && order.article_category == 1) {
                        // 保険期間は、fix_flgが1の場合は決済日(この処理実行日)の翌日がスタートとなる
                        const insurance_start = 
                            parseInt(order.sports_year + '0401') > start_num
                            ? order.sports_year + '-04-01'
                            : start
                        up_insurance.push({
                            id: 0,
                            stat: 1,
                            // fixed: (fix_flg)? 1: 0,
                            fixed: 1,
                            m_id: member.id,
                            insurance_type: order.variant_sort,
                            sports_year: order.sports_year,
                            start_date: insurance_start,
                            end_date: end,
                        })
                    }
                })
            } 
            // const conscon = true
            // if(
            //     conscon
            // ){
            //     return 
            // }
            member.member_license = up_license
            member.insurance = up_insurance

            await this.basicPosts_I(
                config.BASE_API_URL + '/member/edit/' + member.id,
                member
            )
            .then(async (response) => {
                console.log('member_license_update response', response)
            })
            .catch(async (err) => {
                console.log(err)
            })
            .finally(async () => {
                this.$forceUpdate()
            })
        },
        async update_sales_receipt(formSalesId) {
            // sales update
            const sales_url_path =
            formSalesId > 0
                ? '/sales/konbini_set/' + formSalesId
                : false
            const sales_params = {
                sales_type: 'request',
                charge_type: 'KONBINI',
                cash_konbini_code: this.receipt?.konbiniCode,
                cash_receipt_number: this.receipt?.receiptNumber,
                cash_confirmation_number: this.receipt?.confirmationNumber,
            }
            const chk = (sales_url_path)? await this.basicPosts_I(
                config.BASE_API_URL + sales_url_path,
                sales_params
            ): false
            return chk
        },
        async charge_challenge_countup(formSalesId){
            const sales_url_path =
            formSalesId > 0
                ? '/sales/challenge_countup/' + formSalesId
                : false
            const sales_params = {}
            const chk = (sales_url_path)? await this.basicPosts_I(
                config.BASE_API_URL + sales_url_path,
                sales_params
            ): false
            console.log(chk)
            await this.update_app_check(400)
        },
        async update_app_check(check_type) {
            console.log('update_app_check check_type', check_type)
            const post_url_path =
                this.application_obj.id > 0
                ? '/member_application/edit/' + this.application_obj.id
                : '/member_application/add'

            const params = {
                m_id: (this.data_obj || {}).id ? (this.data_obj || {}).id : 0,
                application_check_type: check_type,
            }
            await this.basicPosts_I(config.BASE_API_URL + post_url_path, params)
                .then(async (response) => {
                console.log('update_app_check', response)
                })
                .catch(async (err) => {
                    console.log(err)
                })
                .finally(async () => {
                    // await this.go_app();
                    if (check_type != 403 && check_type != 500) await this.loadApplicationObj()
                    this.charge_btn_disabled = false
                    this.$forceUpdate()
                })
        },
        async update_app_finish(charge_type) {
            const post_url_path =
                this.application_obj.id > 0
                ? '/member_application/edit/' + this.application_obj.id
                : '/member_application/add'
            // orderのvariantにcategory:4(追加),category:5(継続)
            const params = {
                m_id: this.$store.state.auth.userId,
                application_check_type: (charge_type=='cash')? 420: 
                (this.chk_license_continue && !this.chk_license_add)? 500:
                (!this.chk_license_continue && this.chk_license_add && !this.chk_order_license_join_seminar_web)? 500:
                (!this.chk_license_continue && this.chk_license_add && this.chk_order_license_join_seminar_web)? 440:
                500
            }
            await this.basicPosts_I(config.BASE_API_URL + post_url_path, params)
            .then(async (response) => {
                console.log('update_app_finish', response)
            })
            .catch(async (err) => {
                console.log(err)
            })
            .finally(async () => {
            // await this.go_app();
                await this.loadApplicationObj()
                this.charge_btn_disabled = false
                this.$forceUpdate()
            })
        },
        async attach_have_license(){
            if(
                this.selected_year.length > 0
            ){
                const license_list = (typeof (this.data_obj || {}).member_license === 'object')? [...(this.data_obj || {}).member_license]: []
                const tmps = []
                this.selected_year.map(year_add => {
                    tmps.push(parseInt(this.get_term_year) + parseInt(year_add))
                })
                const lc_tmp = license_list.filter(license => tmps.includes(parseInt(license.license_year)))
                                .map(license => {
                                    if(
                                        parseInt(license.license_year) > parseInt(this.get_term_year)
                                    ){
                                        license.year_add = 1
                                    }else{
                                        license.year_add = 0
                                    }
                                    return license
                                })
                this.have_license = [...lc_tmp]
            }
        },
        async attach_license_sel_package() {
            // パッケージの状態をトグルする
            // if (this.selected_variants.includes('package')) {
            //     // パッケージが選択されている場合は解除
            //     this.selected_variants.splice(this.selected_variants.indexOf('package'), 1);
            //     this.selected_package_flg = 0; // フラグをリセット
            // } else {
            //     // パッケージが選択されていない場合は追加
            //     this.selected_variants.push('package');
            //     this.selected_package_flg = 1; // フラグをセット
            // }
            this.selected_variants = []
            this.have_variants = []
            // 年の選択状態をリセット
            if (this.selected_year.length >= 2) {
                this.selected_year = [];
                this.selected_package_flg = 0; // フラグをリセット
            } else {
                this.selected_year = [0, 1]; // 必要に応じて年を設定
                this.selected_package_flg = 1; // フラグをセット
            }
            await this.attach_have_license()
        },
        async attach_license_sel_year(year_add){
            this.selected_variants = [];
            this.selected_package_flg = 0;
            // if(
            //     this.selected_year.includes(parseInt(year_add))
            // ){
            //     this.selected_year.splice(
            //         this.selected_year.indexOf(parseInt(year_add)), 1
            //     );
            //     await this.del_commission_sel_year(year_add);
            // }
            // else{
            //     this.selected_year.push(parseInt(year_add));
            //     // await this.attach_commission_sel_year(year_add);
            // }
            // this.selected_year = year;
            if(
                this.selected_year.includes(parseInt(year_add))
            ){
                this.selected_year = [];
                // this.selected_year.splice(
                //     this.selected_year.indexOf(parseInt(year_add)), 1
                // );
                // await this.del_commission_sel_year(year_add);
            }
            else{
                this.selected_year = [parseInt(year_add)];
                // await this.attach_commission_sel_year(year_add);
            }
            await this.attach_have_license()

            // this.selected_year = year;
        },
        async attach_commission_sel_year(year_add){
            if(
                typeof this.get_article_variant_commision === 'object'
                && this.get_article_variant_commision.find(variant => variant.sports_year_add == year_add)
            ){
                const variant_id = this.get_article_variant_commision.find(variant => variant.sports_year_add == year_add).variant_id;
                await this.sel_variant_year(variant_id, year_add, false);
            }
        },
        async del_commission_sel_year(year_add){
            if(
                typeof this.get_article_variant_commision === 'object'
                && this.get_article_variant_commision.find(variant => variant.sports_year_add == year_add)
            ){
                const variant_id = this.get_article_variant_commision.find(variant => variant.sports_year_add == year_add).variant_id;
                await this.sel_variant_year(variant_id, year_add, false);
            }
        },
        async sel_variant_year(variant_id, year_key, auto_flg){

            console.log('sel_license sel_variant_year variant_id', variant_id);
            const variant = this.article_variant_obj.find(vr => vr.id == variant_id);
            console.log('sel_license sel_variant_year variant', variant);
            const variant_insurance_flg = variant?.insurance_flg
            ? variant.insurance_flg
            : 0
            const draft_orders = (typeof this.draft_order_obj === 'object')? this.draft_order_obj.find(draft_order => draft_order.variant_id == variant_id): false;
            const sports_year_ = (parseInt(this.get_list_label(year_key, this.race_sports_year_obj)) > 2000)
            ? this.get_list_label(year_key, this.race_sports_year_obj)
            : (draft_orders)
            ? draft_orders.sports_year
            : this.get_list_label(year_key, this.race_sports_year_obj)
            const tmp_variant = {
                        variant_id: variant_id,
                        price: variant.default_price,
                        license_id: variant.license_id,
                        license_event_cd: variant.license_event_cd,
                        license_label: variant.license_label,
                        article_label: variant.article_label,
                        sub_label: variant.sub_label,
                        description: variant.description,
                        sports_year_add: variant.sports_year_add,
                        category: variant.category,
                        license_category: variant.license_category,
                        join_3hour: variant.join_3hour,
                        join_seminar_web: variant.join_seminar_web,
                        join_seminar_circuit: variant.join_seminar_circuit,
                        join_parent_frm: variant.join_parent_frm,
                        join_parent_accept: variant.join_parent_accept,
                        movie_no: variant.movie_no,
                        movie_sec_num: variant.movie_sec_num,
                        sports_year: sports_year_,

                        draft_order_id: (draft_orders)? draft_orders.id: 0,
                        
                        join_3hour_circuit_id: (draft_orders)? draft_orders.join_3hour_circuit_id: '',
                        join_3hour_circuit_license_no: (draft_orders)? draft_orders.join_3hour_circuit_license_no: '',
                        join_3hour_circuit_license_file: (draft_orders)? draft_orders.join_3hour_circuit_license_file: '',

                        join_3hour_circuit_chk_req_date: (draft_orders)? draft_orders.join_3hour_circuit_chk_req_date: '',
                        join_3hour_circuit_chk_req_manage_id: (draft_orders)? draft_orders.join_3hour_circuit_chk_req_manage_id: '',
                        join_3hour_circuit_chk_ans_signature: (draft_orders)? draft_orders.join_3hour_circuit_chk_ans_signature: '',
                        join_3hour_circuit_chk_ans_data_1: (draft_orders)? draft_orders.join_3hour_circuit_chk_ans_data_1: '',
                        join_3hour_circuit_chk_ans_data_2: (draft_orders)? draft_orders.join_3hour_circuit_chk_ans_data_2: '',
                        join_3hour_circuit_chk_ans_date: (draft_orders)? draft_orders.join_3hour_circuit_chk_ans_date: '',

                        join_discount_check_file: (draft_orders)? draft_orders.join_discount_check_file:'',
                        join_seminar_type: (draft_orders)? draft_orders.join_seminar_type:'',
                        join_seminar_place: (draft_orders)? draft_orders.join_seminar_place:'',
                        join_seminar_date: (draft_orders)? draft_orders.join_seminar_date:'',
            }
            
            // 保険必要が既に入ってるかチェック
            const before_insurance_flg = (
                (
                    typeof (this.data_obj || {}).insurance === 'object' && 
                    (this.data_obj || {}).insurance.find(insurance => insurance.sports_year == this.get_list_label(year_key, this.race_sports_year_obj))
                ) 
                // || this.selected_variants.find((sel_var) => sel_var.insurance_flg != 0 && sel_var.sports_year_add == year_key) 
                || this.selected_variants.find(sel_var => sel_var.category == 1 && sel_var.sports_year_add == year_key
                )
            )? true
            : false

            let push_flg = false

            if(
                variant.license_id > 0 && 
                this.get_license_sel_variant(variant_id, year_key)
            ){
                const idx = this.selected_variants.findIndex(vr => (vr.variant_id == variant_id && vr.sports_year_add == year_key));
                this.selected_variants.splice(idx, 1);
                const idxx = this.have_variants.findIndex(vr => (vr.variant_id == variant_id && vr.sports_year_add == year_key));
                this.have_variants.splice(idxx, 1);
                console.log('sel_license sel_variant_year1 splice 1', variant_id);
                // 消す
                this.selected_variants = [];
                this.have_variants = [];
            } else if (variant.license_id > 0 && this.get_license_sel_variant_f(variant_id, year_key, variant.license_id)) {
                const idx = this.selected_variants.findIndex(vr => (vr.variant_id == variant_id && vr.sports_year_add == year_key));
                this.selected_variants.splice(idx, 1);
                const idxx = this.have_variants.findIndex(vr => (vr.variant_id == variant_id && vr.sports_year_add == year_key));
                this.have_variants.splice(idxx, 1);
                console.log('sel_license sel_variant_year1 splice2', variant_id);
                // 消す
                this.selected_variants = [];
                this.have_variants = [];
                
            }
            else{
                console.log('sel_license sel_variant_year1 push0', tmp_variant, this.selected_variants);
                this.selected_variants.push(
                    tmp_variant
                )
                if(
                    !this.have_variants.find(hv => hv.variant_id == variant_id && parseInt(hv.sports_year_add) == parseInt(year_key))
                ){
                    this.have_variants.push(
                        tmp_variant
                    )
                }
                push_flg = auto_flg? false: true
                console.log('sel_license sel_variant_year1 push1', this.selected_variants, this.have_variants);
            }


            console.log(
                'sel_license sel_variant_year before_insurance_flg', before_insurance_flg
            )
            console.log(
                'sel_license sel_variant_year variant_insurance_flg', variant_insurance_flg
            )
            if (push_flg == true && !before_insurance_flg && variant_insurance_flg == 1) {
                await this.attach_insurance()
                await this.attach_commission()
            }else{
                console.log('attach insurance commission は自動追加しないフラグ', auto_flg);
            }
        },
        async attach_insurance() {
            // variantの保険部分取得
            // 競技ライセンスには保険・手数料が必須となるため
            const variants = this.article_variant_filter_lc_and_year_and_acate(1)
            console.log('attach_insurance variants', variants)
            await Promise.all(
                this.selected_year.map(async (year) => {
                    await Promise.all(
                    variants
                        .filter((variant) => variant.sports_year_add == year)
                        .map(async (variant) => {
                        return await this.sel_variant_year(
                            variant.variant_id,
                            variant.sports_year_add,
                            false
                        )
                        })
                    )
                })
            )
            // 親権者がいる場合
            if(this.parent_frm_disp){   
                // 親権者の保険を取得
                const parent_variants = this.get_article_variant_insurance
                .filter((variant) => {
                    if (
                    this.selected_year.includes(
                        parseInt(variant.sports_year_add)
                    ) &&
                    (variant.over_age == 0 ||
                        (variant.over_age >= 15 && variant.over_age <= 64)
                    ) &&
                    (variant.under_age == 0 ||
                        (variant.under_age >= 15 && variant.over_age <= 64)
                    ) &&
                    parseInt(this.get_now_date_num) >=
                        parseInt(variant.start_date_num) &&
                    parseInt(this.get_now_date_num) <=
                        parseInt(variant.end_date_num) &&
                    ((parseInt(this.selected_package_flg) == 0 &&
                        parseInt(variant.special_cd) != 100) ||
                        (parseInt(this.selected_package_flg) == 1 &&
                        parseInt(variant.special_cd) == 100))
                    ) {
                    return true
                    }
                    return false
                })
                // 親権者チェック
                await Promise.all(
                    this.selected_year.map(async (year) => {
                    await Promise.all(
                        parent_variants
                        .filter((variant) => variant.sports_year_add == year)
                        .map(async (variant) => {
                            return await this.sel_variant_year(
                            variant.variant_id,
                            variant.sports_year_add,
                            true
                            )
                        })
                    )
                    })
                )
            }
        },
        async attach_commission() {
            // variantの手数料部分取得
            // 競技ライセンスには保険・手数料が必須となるため
            const variants = this.article_variant_filter_lc_and_year_and_acate(2)
            console.log('attach_commission variants', variants)
            await Promise.all(
                this.selected_year.map(async (year) => {
                    await Promise.all(
                    variants
                        .filter((variant) => variant.sports_year_add == year)
                        .map(async (variant) => {
                        return await this.sel_variant_year(
                            variant.variant_id,
                            variant.sports_year_add,
                            false
                        )
                        })
                    )
                })
            )
            // 親権者がいる場合
            if(this.parent_frm_disp){   
            // 親権者の手数料を取得
                const parent_variants = this.get_article_variant_commision
                .filter((variant) => {
                    if (
                    this.selected_year.includes(
                        parseInt(variant.sports_year_add)
                    ) &&
                    (variant.over_age == 0 ||
                        (variant.over_age > 8 && variant.over_age <= 64)
                    ) &&
                    (variant.under_age == 0 ||
                        (variant.under_age > 8 && variant.over_age <= 99)
                    ) &&
                    parseInt(this.get_now_date_num) >=
                        parseInt(variant.start_date_num) &&
                    parseInt(this.get_now_date_num) <=
                        parseInt(variant.end_date_num) &&
                    ((parseInt(this.selected_package_flg) == 0 &&
                        parseInt(variant.special_cd) != 100) ||
                        (parseInt(this.selected_package_flg) == 1 &&
                        parseInt(variant.special_cd) == 100))
                    ) {
                    return true
                    }
                    return false
                })
                // 親権者チェック
                await Promise.all(
                    this.selected_year.map(async (year) => {
                    await Promise.all(
                        parent_variants
                        .filter((variant) => variant.sports_year_add == year)
                        .map(async (variant) => {
                            console.log('va', variant)
                            return await this.sel_variant_year(
                            variant.variant_id,
                            variant.sports_year_add,
                            true
                            )
                        })
                    )
                    })
                )
            }
        },
        // 2025-02-16 chk_flgは使ってない
        async sel_license(license_id, license_category, article_category, chk_flg) {
            console.log('sel_license ', license_id, license_category, article_category, chk_flg)
            // 年を選択している場合の処理
            if (typeof this.selected_year === 'object' && this.selected_year.length > 0) {
                // console.log('sel_license license_id', license_id);
                const years = [...this.selected_year];
                const variants = [...this.article_variant_filter_lc_and_year_and_lcate(license_category, article_category)].filter(variant => variant.license_id == license_id);
                // console.log('sel_license years', years);
                let chk_off_alt = false
                let chk_off = true
                await Promise.all(years.map(async (year) => {
                    await Promise.all(variants.filter(variant => variant.sports_year_add == year).map(async (variant) => {
                        if(
                            this.get_license_sel_variant(variant.variant_id, variant.sports_year_add)
                        ){
                            chk_off_alt = true
                        }
                    }));
                }));
                if(chk_off_alt){
                    if(
                        !window.confirm('選択したライセンスを解除する場合、選択中のライセンスは一度クリアされます。よろしいですか？')
                    ){
                        chk_off = false
                    }else{
                    this.selected_variants = []
                    this.have_variants = []
                    return true
                    }
                }
                if(!chk_off){
                    console.log('aaaaaaxaaaaaa');
                    return
                }
                
                if (this.selected_package_flg > 0) {
                    await Promise.all(variants.map(async (variant) => {
                            await Promise.all(variant.package_variant_ids.map(
                                async (variant_id, index_y) => {
                                return await this.sel_variant_year(variant_id, index_y, false)
                                }
                            )
                            )
                        })
                    )
                    } else {
                    // 年ごとにバリアントを処理
                    await Promise.all(years.map(async (year) => {
                        await Promise.all(variants.filter(variant => variant.sports_year_add == year).map(async (variant) => {
                            return await this.sel_variant_year(variant.variant_id, variant.sports_year_add, false);
                        }));
                    }));
                }
                // license_category
                // これを追加する予定2025-02-12
                if(
                    parseInt(license_category) == 2 && 
                    typeof this.article_variant_filter_lc_and_year_and_lcate(3, 4) === 'object'
                    && this.article_variant_filter_lc_and_year_and_lcate(3, 4).length > 0 
                    && this.article_variant_filter_lc_and_year_and_lcate(3, 4)[0]
                ){
                    // console.log('this.article_variant_filter_lc_and_year_and_lcate(3, article_category)', this.article_variant_filter_lc_and_year_and_lcate(3, article_category));
                    await this.sel_license(this.article_variant_filter_lc_and_year_and_lcate(3, 4)[0].license_id, 3, 4, true)
                }
                if(
                    (parseInt(license_category) == 4 || parseInt(license_category) == 5)
                    && this.selected_variants.find(variant => parseInt(variant.license_category) == 3)
                ){
                    const enjoy = {...this.selected_variants.find(variant => parseInt(variant.license_category) == 3)}
                    const tmps = this.selected_variants.filter(variant => parseInt(variant.license_category) != 3)
                    // 保険必要なライセンスが残っているならtrue
                    const tmpss = (tmps.find(variant => variant.insurance_flg == 1))? tmps: tmps.filter(tmp => ![1, 2].includes(parseInt(tmp.category)))
                    this.selected_variants = (tmpss)? tmpss: []
                    await this.sel_license(enjoy.license_id, enjoy.license_category, enjoy.category)
                    // await this.sel_license(enjoy.license_id, enjoy.license_category, 3)
                }
            }
        },

        async cancel(){
            if(
                window.confirm('変更を取りやめます。よろしいですか？')
            ){
                const post_url_path = (this.application_obj.id > 0)? '/member_application/edit/' + this.application_obj.id: '/member_application/add';
                const params = {
                    m_id: this.$store.state.auth.userId,
                    stat: 9
                };
                await this.basicPosts_I(
                    config.BASE_API_URL + post_url_path,
                    params
                )
                .then(
                    async (response) => {
                    console.log('update', response);
                    }
                )
                .catch(
                    async (err) => {
                    console.log(err);
                })
                .finally(async () => {
                    await this.loadApplicationObj();
                    this.change_mode_value = false;
                    this.message = '変更申請をキャンセルしました';
                    this.$forceUpdate();
                });
            }
        },
        async get_address(){
            const postal_cd = this.form_obj.postal_cd;
            await this.basicGets_O(
                'https://zipcloud.ibsnet.co.jp/api/search?zipcode=' + postal_cd
            )
            .then(res => {
                if(
                typeof res === 'object'
                && typeof res.status === 'number'
                && res.status == 200 
                && typeof res.data === 'object'
                && typeof res.data.results === 'object'
                && typeof res.data.results[0] === 'object'
                ){
                    this.form_obj.pref_cd = (parseInt(res.data.results[0].prefcode) < 10)? '0' + res.data.results[0].prefcode: res.data.results[0].prefcode;
                    this.form_obj.address_1 = res.data.results[0].address2 + res.data.results[0].address3;
                    this.form_obj.address_2 = '';
                }
                else{
                alert('電話番号データがありません');
                }
            })
            .catch(error => {
                console.log(error);
                alert('住所データがありません.');
            })
            .finally(() =>{
                this.$forceUpdate();
            });
        },
        // async selectedFile(e, type_cd, path) {
        //     e.preventDefault();
        //     const files = [...e.target.files];
        //     // let ups = [];
        //     files.forEach(async (file) => {
        //         const form = new FormData();
        //         form.append('file', file);
        //         await this.basicPosts_R(
        //         config.BASE_API_URL + '/upload/member_assets',
        //         form
        //         )
        //         .then(res => {
        //         console.log(res);
        //         if(res && res.status == 200){
        //             this.app_form_obj['path' + path] = (res.data.body.file_path)? res.data.body.file_path: '';

        //         }
        //         })
        //         .catch(error => {
        //         console.log(error);
        //         })
        //         .finally(() =>{
        //             document.getElementById('file_up_input_' + type_cd + '_path' + path).value = '';
        //             this.$forceUpdate();
        //         });
        //     });
        // },
        async selectedFile(e, type_cd, path, variant_id) {
            e.preventDefault()
            const files = [...e.target.files]
            if(files.length === 0) return;
            // let ups = [];
            files.forEach(async (file) => {
                const form = new FormData()
                form.append('file', file)
                await this.basicPosts_R(
                    config.BASE_API_URL + '/upload/member_assets',
                    form
                )
                .then((res) => {
                    console.log(res)
                    if (
                        res &&
                        res.status == 200 &&
                        path != false &&
                        variant_id == false
                    ) {
                        this.app_form_obj['path' + path] = res.data.body.file_path
                        ? res.data.body.file_path
                        : ''
                    } else if (
                        res &&
                        res.status == 200 &&
                        path == false &&
                        variant_id != false
                    ) {
                        const v_index = this.selected_variants.findIndex(
                        (variant) => variant.variant_id == variant_id
                        )
                        const tmp_variant = v_index
                        ? this.selected_variants[v_index]
                        : false
                        if (tmp_variant) {
                        tmp_variant[type_cd] = res.data.body.file_path
                            ? res.data.body.file_path
                            : ''
                        this.selected_variants[v_index] = tmp_variant
                        }
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
                .finally(() => {
                    if (path != false && variant_id == false) {
                        document.getElementById(
                        'file_up_input_' + type_cd + '_path' + path
                        ).value = ''
                    } else if (path == false && variant_id != false) {
                        document.getElementById(
                        'file_up_input_' + type_cd + '_variant_' + variant_id
                        ).value = ''
                    }
                    e.target.value = '';
                    this.$forceUpdate()
                })
            })
        },
        async clickUpFileBtn(type, path){
            document.getElementById('file_up_input_' + type + '_path' + path).click();
        },
        async loadConfigObj (param) {
            const params = {};
            await this.basicPosts_S(
                config.BASE_API_URL + '/config/' + param,
                params
            )
            .then(
                async (response) => {
                    console.log('loadConfigObj response', response);
                    this[param + '_obj'] = (response.data && response.data.body)? response.data.body: [];
                }
            )
            .catch(
                async (err) => {
                console.log(err);
                this[param + '_obj'] = [];
            })
            .finally(async () => {
                this.$forceUpdate();
            });
        },
        async loadMerchantObj(target_merchant) {
            await this.basicPosts_S(
            config.BASE_API_URL + '/merchant/' + target_merchant,
            {}
            )
            .then(async (response) => {
                console.log('loadMerchantObj', response)
                this.merchant_obj =
                response.data && response.data.body ? response.data.body : {}
            })
            .catch(async (err) => {
                console.log(err)
                this.merchant_obj = {}
            })
            .finally(async () => {
                this.$forceUpdate()
            })
        },
        async loadPrefList () {
            const params = {};
            params['stat'] = [1];
            await this.basicPosts_S(
                config.BASE_API_URL + '/area_pref/lists',
                params
            )
            .then(
                async (response) => {
                    console.log('loadPrefList response', response);
                    this.pref_obj = (response.data && response.data.body)? response.data.body: [];
                }
            )
            .catch(
                async (err) => {
                console.log(err);
                this.pref_obj = [];
            })
            .finally(async () => {
                this.$forceUpdate();
            });
        },
        async loadUserObj(){
            const params = {};
            params['stat'] = [1];
            params['id'] = this.$store.state.auth.userId;
            // params['pagegroup'] = 'mfj_member_data.id';
            await this.basicPosts_S(
                config.BASE_API_URL + '/member/lists',
                params
            )
            .then(
                async (response) => {
                console.log('loadUserObj', response);
                this.data_obj = (response.data && response.data.body && response.data.body[0])? response.data.body[0]: {};
                const member_license_tmp = (this.data_obj?.member_license)? [...this.data_obj?.member_license]: []
                // const member_license = member_license_tmp.filter(license => parseInt(license.license_year) >= this.get_term_year - 0)
                const member_license = member_license_tmp
                this.data_obj.member_license = member_license
                }
            )
            .catch(
                async (err) => {
                        console.log(err);
                        this.data_obj = {};
                    })
            .finally(async () => {
                await this.setUserObj();
                this.$forceUpdate();
            });
        },
        async setUserObj(){
            // have_licenseにいれるのはattach_have_licenseに集約
            // const tmps = (typeof (this.data_obj || {}).member_license === 'object')? [...(this.data_obj || {}).member_license]: []
            // this.have_license = tmps.filter(license => license.license_year == this.get_term_year)
            this.form_obj = {
                name_1: (this.data_obj || {}).name_1,
                name_2: (this.data_obj || {}).name_2,
                yomi_1: (this.data_obj || {}).yomi_1,
                yomi_2: (this.data_obj || {}).yomi_2,
                birth_date: (this.data_obj || {}).birth_date,
                postal_cd: (this.data_obj || {}).postal_cd,
                pref_cd: (this.data_obj || {}).pref_cd,
                address_1: (this.data_obj || {}).address_1,
                address_2: (this.data_obj || {}).address_2,
                tel_1: (this.data_obj || {}).tel_1,
                tel_2: (this.data_obj || {}).tel_2,
                member_assets: (this.data_obj || {}).member_assets,
            }
            await this.attach_have_license()
        },
        async setSalesObj(){
            console.log('setSalesObj')
            if(
                typeof (this.application_obj || {}).sales === 'object'
                && Object.keys(this.application_obj.sales).length > 0
            ){
                const tmps = {...(this.application_obj || {}).sales};
                this.sales_obj = {...tmps};

                if(
                    (this.sales_obj || {}).charge_order_id.endsWith('K')
                ){
                    console.log('setSalesObj cash')
                    this.selected_charge_type = 'cash'
                }
                if(
                    this.sales_obj?.cash_konbini_code && this.sales_obj.cash_receipt_number && this.sales_obj.cash_confirmation_number
                ){
                    const receipt_tmp = {
                        konbiniCode: this.sales_obj?.cash_konbini_code,
                        receiptNumber: this.sales_obj?.cash_receipt_number,
                        confirmationNumber: this.sales_obj?.cash_confirmation_number
                    }
                    this.receipt = {...receipt_tmp}
                }
                this.$forceUpdate();
                
            }
        },
        // setApplicationで同じことをやっている
        async setDraftOrderObj(){
            if(
                typeof (this.application_obj || {}).draft_orders === 'object'
                && Object.keys(this.application_obj.draft_orders).length > 0
            ){
                const tmps = [...(this.application_obj || {}).draft_orders];
                this.draft_order_obj = [...tmps];
                // 年度
                const tmp_year = [];

                await Promise.all(tmps.map(async (draft_order) => {
                    if(
                        tmp_year.includes(parseInt(this.get_list_key(draft_order.sports_year, this.race_sports_year_obj))) === false
                    ){
                        tmp_year.push(
                            parseInt(this.get_list_key(draft_order.sports_year, this.race_sports_year_obj))
                        )
                    }
                    return await this.sel_variant_year(draft_order.variant_id, draft_order.sports_year, true)
                }))
                this.selected_year = tmp_year;
                // this.attach_license_sel_category(0, 3);
                this.$forceUpdate();
                
            }
        },
        async loadApplicationObj(){
            const params = {};
            params['stat'] = [1];
            params['m_id'] = this.$store.state.auth.userId;
            params['application_type'] = 1300;
            params['not_application_check_types'] = [500, 900];
            await this.basicPosts_S(
                config.BASE_API_URL + '/member_application/lists',
                params
            )
            .then(
                async (response) => {
                console.log('loadApplicationObj', response);
                this.application_obj = (response.data && response.data.body && response.data.body[0])? response.data.body[0]: {};
                }
            )
            .catch(
                async (err) => {
                    console.log(err);
                    this.application_obj = {};
                    })
            .finally(async () => {
                await this.setApplicationObj();
                // await this.setDraftOrderObj();
                await this.setSalesObj();
                this.$forceUpdate();
            });
        },
        async setApplicationObj(){
            console.log('!!!setApplicationObj')
            const tmps = (
                typeof this.application_obj === 'object'
                && Object.keys(this.application_obj).length > 0
            )? this.application_obj
            :  {
                id: 0,
                application_check_type: '',
                application_type: '',
                application_date: ''
            }
            this.application_obj = tmps;

            this.communication_obj = (this.application_obj && this.application_obj.communications && this.application_obj.communications[0])? this.application_obj.communications[0]: {};
            this.communication_checked = (this.application_obj && this.application_obj.checked)? this.application_obj.checked: [];

            // pagemode
            console.log('setApplicationObj tmps', tmps);
            // 決済(application_check_type:400-423)
            if (
                (this.application_obj || {}).application_check_type == 400 &&
                parseInt((this.application_obj || {}).m_id) > 0
            ) {
                this.page_mode = 'charge'
                await this.mp_token_mount()
            }
            else if (
                (this.application_obj || {}).application_check_type == 200 &&
                parseInt((this.application_obj || {}).m_id) > 0
            ) {
                this.page_mode = 'check'
                await this.mp_token_mount()
            }
            // 決済エラー
            else if(
                (this.application_obj || {}).application_check_type == 403 &&
                parseInt((this.application_obj || {}).m_id) > 0
            ){
                // ここでもう一度appをチェックして、決済もチェックして問題ないか確認させる
                const sales = (
                typeof (this.application_obj || {}).sales === 'object' &&
                    Object.keys(this.application_obj.sales).length > 0
                )? { ...(this.application_obj || {}).sales }: false
                const sales_id = (sales)? sales.id: 0
                const sparams = {};
                sparams['stat'] = [1];
                sparams['id'] = sales_id;
                const success_check = await this.basicPosts_S(
                    config.BASE_API_URL + '/sales/success_check',
                    sparams
                )
                .then(
                    async (response) => {
                        console.log('success_check response', response)
                        return (response?.data?.body)? response.data.body: false
                    }
                )
                .catch(
                    async (err) => {
                    console.log(err);
                    return false
                })
                console.log('403 check success_check', success_check);
                if(!success_check){
                    await this.update_app_check(400)
                    this.page_mode = 'charge'
                    this.message_class = 'danger'
                }else if(success_check){
                    const chkTYpe = (sales.charge_type == 'CREDIT')
                    ? 423
                    : (sales.charge_type == 'KONBINI')?
                        426
                    : 423;
                    await this.update_app_check(chkTYpe)
                    return
                }
            }
            // 決済成功
            else if(
                (
                (this.application_obj || {}).application_check_type == 426 || 
                (this.application_obj || {}).application_check_type == 423) &&
                parseInt((this.application_obj || {}).m_id) > 0
            ){

                const tmps = [...(this.application_obj || {}).draft_orders];
                this.draft_order_obj = [...tmps];

                this.page_mode = 'charge'
                await this.member_license_update()
                await this.update_app_check(430)
                // return;
            }
            // 決済成功して戻ってくる
            else if(
                (this.application_obj || {}).application_check_type == 430 &&
                parseInt((this.application_obj || {}).m_id) > 0
            ){
                this.page_mode = 'success'
                await this.update_app_check(500)
            }
            // コンビニ申し込み、支払い町
            else if(
                (this.application_obj || {}).application_check_type == 420 &&
                parseInt((this.application_obj || {}).m_id) > 0
            ){
                this.page_mode = 'cash_unpaid'
            }
            // コンビニ申し込み、支払い町
            else if(
                (this.application_obj || {}).application_check_type == 100 &&
                parseInt((this.application_obj || {}).m_id) > 0
            ){
                this.page_mode = 'check'
            }
            // 差し戻しがある
            else if(
                (this.application_obj || {}).application_check_type == 600 &&
                parseInt((this.application_obj || {}).m_id) > 0
            ){
                this.page_mode = 'remand'
            }

            // selected_variant取得
            if(
                (this.charge_mode || this.remand_mode)
                && typeof (this.application_obj || {}).draft_orders === 'object'
            ){
                // 一旦クリアする
                this.selected_variants = []
                this.draft_order_obj = [...this.application_obj.draft_orders];
                console.log('!!!draft_order_obj', this.draft_order_obj)
                await Promise.all(this.application_obj.draft_orders.map(async (order, idx) => {
                    console.log('!!!selected idx', idx, order.variant_id, order.sports_year_add)
                    await this.sel_variant_year(order.variant_id, order.sports_year_add, true);
                }));
                console.log('!!!selected', this.selected_variants)
            }

            this.app_form_obj = {
                name_1: ((this.get_target_app_item('mfj_member_data@name_1') || {}).application_value)
                    ? (this.get_target_app_item('mfj_member_data@name_1') || {}).application_value
                    : '',
                name_1_message: ((this.get_target_app_item('mfj_member_data@name_1') || {}).message)
                    ? (this.get_target_app_item('mfj_member_data@name_1') || {}).message
                    : '',
                yomi_1: ((this.get_target_app_item('mfj_member_data@yomi_1') || {}).application_value)
                    ? (this.get_target_app_item('mfj_member_data@yomi_1') || {}).application_value
                    : '',
                yomi_1_message: ((this.get_target_app_item('mfj_member_data@yomi_1') || {}).message
                    )? (this.get_target_app_item('mfj_member_data@yomi_1') || {}).message
                    : '',
                path1: ((this.get_target_app_item('mfj_member_assets@path1') || {}).application_value)
                    ? (this.get_target_app_item('mfj_member_assets@path1') || {}).application_value
                    : '',
                path2: ((this.get_target_app_item('mfj_member_assets@path2') || {}).application_value)
                    ? (this.get_target_app_item('mfj_member_assets@path2') || {}).application_value
                    : '',
                path3: ((this.get_target_app_item('mfj_member_assets@path3') || {}).application_value)
                    ? (this.get_target_app_item('mfj_member_assets@path3') || {}).application_value
                    : '',
                path4: ((this.get_target_app_item('mfj_member_assets@path4') || {}).application_value)
                    ? (this.get_target_app_item('mfj_member_assets@path4') || {}).application_value
                    : '',
                path5: ((this.get_target_app_item('mfj_member_assets@path5') || {}).application_value)
                    ? (this.get_target_app_item('mfj_member_assets@path5') || {}).application_value
                    : '',
                path6: ((this.get_target_app_item('mfj_member_assets@path6') || {}).application_value)
                    ? (this.get_target_app_item('mfj_member_assets@path6') || {}).application_value
                    : '',
                path10: ((this.get_target_app_item('mfj_member_assets@path10') || {}).application_value)
                    ? (this.get_target_app_item('mfj_member_assets@path10') || {}).application_value
                    : '',
                path11: ((this.get_target_app_item('mfj_member_assets@path11') || {}).application_value)
                    ? (this.get_target_app_item('mfj_member_assets@path11') || {}).application_value
                    : '',
                path_message: ((this.get_target_app_item('mfj_member_assets@path') || {}).message)
                    ? (this.get_target_app_item('mfj_member_assets@path') || {}).message
                    : '',
                path_type: ((this.get_target_app_item('mfj_outdb_data@path_type') || {}).application_value)
                    ? (this.get_target_app_item('mfj_outdb_data@path_type') || {}).application_value
                    : '',
            }
            // this.page_mode = 'success'
                // this.$forceUpdate();
        },
        async loadCircuitList () {
            const params = {};
            params['stat'] = [1];
            await this.basicPosts_S(
                config.BASE_API_URL + '/circuit/lists',
                params
            )
            .then(
                async (response) => {
                    console.log('loadCircuitList response', response);
                    this.circuit_obj = (response.data && response.data.body)? response.data.body: [];
                }
            )
            .catch(
                async (err) => {
                console.log(err);
                this.circuit_obj = [];
            })
            .finally(async () => {
                this.$forceUpdate();
            });
        },
        async loadRaceSportsYear(){
            const params = {};
            await this.basicPosts_S(
                config.BASE_API_URL + '/race_sports_year/lists',
                params
            )
            .then(
                async (response) => {
                    console.log('loadRaceSportsYear response', response);
                    this.race_sports_year_obj = (response.data && response.data.body)? response.data.body: [];
                }
            )
            .catch(
                async (err) => {
                console.log(err);
                this.race_sports_year_obj = [];
            })
            .finally(async () => {
                this.$forceUpdate();
            });
        },
        async loadTermYear(){
            const params = {};
            await this.basicPosts_S(
                config.BASE_API_URL + '/term_year/lists',
                params
            )
            .then(
                async (response) => {
                    console.log('loadTermYear response', response);
                    this.term_year_obj = (response.data && response.data.body)? response.data.body: [];
                }
            )
            .catch(
                async (err) => {
                console.log(err);
                this.term_year_obj = [];
            })
            .finally(async () => {
                this.$forceUpdate();
            });
        },
        async loadTermDate(){
            const params = {};
            await this.basicPosts_S(
                config.BASE_API_URL + '/term_date/lists',
                params
            )
            .then(
                async (response) => {
                    console.log('loadTermDate response', response);
                    this.term_date_obj = (response.data && response.data.body)? response.data.body: [];
                }
            )
            .catch(
                async (err) => {
                console.log(err);
                this.term_date_obj = [];
            })
            .finally(async () => {
                this.$forceUpdate();
            });
        },
        async loadNowDate(){
            const params = {};
            await this.basicPosts_S(
                config.BASE_API_URL + '/now_date/lists',
                params
            )
            .then(
                async (response) => {
                    console.log('loadNowDate response', response);
                    this.now_date_obj = (response.data && response.data.body)? response.data.body: [];
                }
            )
            .catch(
                async (err) => {
                console.log(err);
                this.now_date_obj = [];
            })
            .finally(async () => {
                this.$forceUpdate();
            });
        },
        async loadArticleVariant(){
            const params = {};
            // params['article_id'] = 89;
            params['stat'] = [1];
            params['article_stat'] = [1];
            params['article_category_ids'] = [1, 2, 3, 4, 5];
            // params['license_ids'] = [2,3];
            // params['license_event_cd'] = 1;
            // params['article_category_ids'] = [1, 2, 3];
            // params['article_']
            // カテゴリ
            await this.basicPosts_S(
                config.BASE_API_URL + '/article_variant/lists',
                params
            )
            .then(
                async (response) => {
                    console.log('loadArticleVariant response', response);
                    this.article_variant_obj = (response.data && response.data.body)? response.data.body: [];
                }
            )
            .catch(
                async (err) => {
                console.log(err);
                this.article_variant_obj = [];
            })
            .finally(async () => {
                this.$forceUpdate();
            });
        },
        async loadArticle(){
            const params = {};
            params['stat'] = [1];
            params['category_ids'] = [1, 2, 4, 5];
            // params['category_ids'] = [1, 2, 3];
            await this.basicPosts_S(
                config.BASE_API_URL + '/article/lists',
                params
            )
            .then(
                async (response) => {
                    console.log('loadArticle response', response);
                    this.article_obj = (response.data && response.data.body)? response.data.body: [];
                }
            )
            .catch(
                async (err) => {
                console.log(err);
                this.article_obj = [];
            })
            .finally(async () => {
                this.$forceUpdate();
            });
        },
        async loadLicenseCdList () {
            const params = {};
            params['stat'] = [1];
            await this.basicPosts_S(
                config.BASE_API_URL + '/license_cd/lists',
                params
            )
            .then(
                async (response) => {
                    console.log('loadLicenseCdList response', response);
                    this.license_cd_obj = (response.data && response.data.body)? response.data.body: [];
                }
            )
            .catch(
                async (err) => {
                console.log(err);
                this.license_cd_obj = [];
            })
            .finally(async () => {
                this.$forceUpdate();
            });
        },
        async confirm_chk(){
            if(
                !this.app_form_obj.path1
            ){
                alert('証明する書類ファイルを添付して下さい。');
                return true;
            }
            else if(
                !this.app_form_obj.path_type
            ){
                alert('証明する書類ファイルの種類を選択して下さい。');
                return true;
            }
            else if(
                !this.app_form_obj.name_1
                || !this.app_form_obj.yomi_1
            ){
                alert('変更する名前を入力して下さい。');
                return true;
            }
            // else if(
            //     this.change_login_id != '' && this.confirm_login_id != ''
            //     && this.change_login_id != this.confirm_login_id
            // ){
            //     alert('入力されたログインIDが一致しません');
            //     return true;

            // }
            this.change_mode_value = true;
        },
        async update_app(){
            const member = { ...this.data_obj }
            console.log('component methods', this.data_obj);

            const post_url_path = (this.application_obj.id > 0)? '/member_application/edit/' + this.application_obj.id: '/member_application/add';
        
            const check_type = (
                // 更新がある
                // 更新時顔写真を変えている    
                (
                    typeof this.app_form_obj === 'object'
                    && this.app_form_obj.path3
                )  
                // 継続(追加も原則上必要かもしれない)未成年者であれば年度継続者はMFJ承認が必要
                || (
                    this.chk_parent_accept
                )
                // 追加がある
                // 追加時、3時間証明が必要
                || (
                    this.chk_license_join_3hour
                )
                // 追加時、対面講習会を選択している。
                || (
                    this.chk_order_license_join_seminar_circuit
                )
                // 決済が0円
                || (
                    this.selected_variants?.map(item => parseInt(item.price)).reduce((acc, curr) => acc + curr, 0) === 0
                )
            )
            ? 100
            : (this.data_obj?.trans_period == 1)
            ? 100 
            : (this.data_obj?.continue_app_type == 1)
            ? 100
            : 400;
            console.log('update_app check_type', check_type);

            const params = {
                application_check_type: check_type,
                application_type: 1300,
                m_id: this.$store.state.auth.userId,
                // application_type: this.upd_obj.application_check_type,
                // application_check_type: this.upd_obj.application_check_type,
                // items: [],
            };

            const item_column = { 
                path3: 'mfj_member_assets@path3'
            };
            if(
                this.chk_license_join_3hour
            ){
                item_column['path10'] = 'mfj_member_assets@path10'
                item_column['path11'] = 'mfj_member_assets@path11'
            }

            const items = [];
            Object.keys(item_column).forEach(item_key => {
                items.push({
                    id: (this.get_target_app_item(item_column[item_key]))? (this.get_target_app_item(item_column[item_key]) || {}).id: 0,
                    application_id: this.application_obj.id,
                    application_key: item_column[item_key],
                    application_value: this.app_form_obj[item_key]
                });
            })
            params.items = (typeof items === 'object' && items.length > 0)? items: false;

            // draft_order
            const draft_orders = [];
            if(
                typeof this.selected_variants === 'object'
                && this.selected_variants.length > 0
            ){
                this.selected_variants.forEach(variant => {
                    draft_orders.push({
                        id: variant.draft_order_id,
                        stat: 1,
                        application_id: 0,
                        variant_id: variant.variant_id,
                        sports_year: variant.sports_year,
                        unit_price: variant.price,
                        num: 1,
                        list_price: parseInt(variant.price) * 1,
                        join_3hour_circuit_id: variant.join_3hour_circuit_id,
                        join_3hour_circuit_license_no: variant.join_3hour_circuit_license_no,
                        join_3hour_circuit_license_file: variant.join_3hour_circuit_license_file,
                        join_discount_check_file: variant.join_discount_check_file,
                        join_seminar_type: variant.join_seminar_type,
                        join_seminar_place: variant.join_seminar_place,
                        join_seminar_date: variant.join_seminar_date

                    });
                })
            }
            console.log('draft_orders', draft_orders)
            params.draft_orders = draft_orders;

            let app_id = (this.application_obj.id > 0)? this.application_obj.id: 0;
            
            await this.basicPosts_I(
                config.BASE_API_URL + post_url_path,
                params
            )
            .then(
                async (response) => {
                    console.log('update', response);
                    app_id = (response.data && response.data.body && response.data.body[0] && response.data.body[0].id)? response.data.body[0].id: 0;
                }
            )
            .catch(
                async (err) => {
                console.log(err);
                this.page_mode = 'error';
            })
            .finally(async () => {
                // await this.loadApplicationObj();
                this.change_mode_value = false;
                this.message = '申請しました。';
                // 更新がある
                // 更新時顔写真を変えている
                if(
                    check_type == 100
                ){
                    // console.log('update_app check this.app_form_obj', this.app_form_obj);
                    // console.log('update_app check this.chk_license_join_3hour', this.chk_license_join_3hour);
                    // console.log('update_app check this.chk_license_join_seminar_circuit', this.chk_license_join_seminar_circuit);
                    this.page_mode = 'check';
                    await this.loadApplicationObj();
                }
                //それ以外はチェック
                else{
                    this.page_mode = 'charge';
                    app_id = (app_id)? app_id: this.application_obj.id;
                    // console.log('update_app charge app_id', app_id);
                    await this.app_order(member.id, app_id, draft_orders);
                    await this.loadApplicationObj();
                }
                this.$forceUpdate();
            });
        },
        // sales登録
        // すぐに決済の場合
        async app_order(m_id, app_id, draft_orders) {
            console.log('app_order m_id, app_id', m_id, app_id);
            const post_url = config.BASE_API_URL + '/sales/add';
            const params = {
            id: 0,
            m_id: m_id,
            application_id: app_id,
            sales_type: 'request',
            regist_id: 0,
            amount: 0,
            sales_orders: [],
            };

            if (typeof draft_orders === 'object') {
            Object.values(draft_orders).forEach((draft_order) => {
                params.sales_orders.push({
                id: 0,
                sales_id: 0,
                application_id: app_id,
                variant_id: draft_order.variant_id,
                sports_year: draft_order.sports_year,
                unit_price: draft_order.unit_price,
                num: draft_order.num,
                list_price: draft_order.list_price,
                });
                params.amount += parseInt(draft_order.list_price);
            });
            }
            console.log('app_order params', params);

            let result = false;
            await this.basicPosts_I(post_url, params)
            .then(async (response) => {
                if (
                typeof response === 'object' &&
                response.data &&
                response.data &&
                response.data.status == '200'
                ) {
                    console.log('app_order response 200', response);
                result = true;
                } else if (
                typeof response === 'object' &&
                typeof response.data === 'object' &&
                response.data.status == '500'
                ) {
                console.log(
                    'app_order response 500',
                    response.data.alert.error_obj
                );
                result = false;
                } else {
                console.log('app_order response 200 error');
                result = false;
                }
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(async () => {
                if (result) console.log('result');
                this.$forceUpdate();
            });
        },
        back_input(){
            this.change_mode_value = false;
        },
        go_dashboard(){
            this.$router.go({ path: '/', force: true });
        },
        back(){
            this.$router.go(-1);
        },
        back_mode(mode){
            this.page_mode = mode;
        },
        async mp_token_mount(){
            // MpTokenに必要な情報を設定
            const mulpay = await this.handlePaymentPageProps({
            instance: axios.create({
                baseURL: config.BASE_API_URL,
                headers: {
                'Content-Type': 'application/json',
                },
            }),
            apiKey: config.GMO_MP_TOKEN_API_KEY_MFJ,
            pubKey: config.GMO_MP_TOKEN_PUB_KEY_MFJ,
            productionMode: config.GMO_SHOP_OP_MODE,
            })
            console.log('mounted !!', mulpay);

            const elements = mulpay.createElements()

            const cardNumberInputElement = elements.create('cardNumber', {
                style: cardInputStyle,
                placeholder: 'Card Number',
            })
            const expiryInputElement = elements.create('cardExpiry', {
                style: expiryInputStyle,
            })
            const cvcInputElement = elements.create('cardCvc', {
                style: cvcInputStyle,
            })

            const cardNumberWrapperElement = document.getElementById('card-number')
            const cardExpiryWrapperElement = document.getElementById('expiry-month')
            const cardCvcWrapperElement = document.getElementById('security-code')

            cardNumberInputElement.mount(cardNumberWrapperElement)
            expiryInputElement.mount(cardExpiryWrapperElement)
            cvcInputElement.mount(cardCvcWrapperElement)

            this.setElements(
                cardNumberInputElement,
                expiryInputElement,
                cvcInputElement
            )
        }
    },
    computed: {
        mfj_user_chk: function(){
            if(
                typeof this.data_obj === 'object' && 
                this.data_obj?.affiliation == 'other'
            ){
                return false
            }
            return true
        },
        get_app_list_label: function () {
            // const lists = this.application_key_list(1000)
            const get_list = this.get_list_label
            const lists = {
                m_join_seminar: '受講',
                m_join_3hour: '走行証明',
                m_photo: '顔写真',
                m_idcard_photo: '身分証明書'
            }
            return function (key) {
            const target_list = []
            Object.keys(lists).forEach(list_key => {
                target_list.push({
                key: list_key,
                label: lists[list_key]
                })
            })
            return get_list(key, target_list)
            }
        },
        get_variant: function(){
            const variants = this.article_variant_obj
            return function(variant_id, year_add){
            return variants.find(variant => variant.variant_id == variant_id && parseInt(variant.sports_year_add) == parseInt(year_add))
            }
        },
        get_selected_variants: function(){
            const selected_variants = this.selected_variants
            return function(license_id){
            if(
                selected_variants.find(variant => variant.license_id == license_id)
            ){
                return [
                selected_variants.find(variant => variant.license_id == license_id && parseInt(variant.sports_year_add) == parseInt(0)),
                selected_variants.find(variant => variant.license_id == license_id && parseInt(variant.sports_year_add) == parseInt(1))
                ]
            }
            return false
            }
        },
        get_year_label: function(){
            const race_sports_year_list = this.race_sports_year_list
            return function(year_key){
            return race_sports_year_list.find(year => year.key == year_key)? race_sports_year_list.find(year => year.key == year_key)?.label: ''
            }
        },
        chk_confirm_btn: function(){
            let confirm_chk = true
            // 追加ロードライセンスがある場合,3時間
            if(
                this.chk_license_add && this.chk_license_join_3hour
            ){ 
                if(
                    // 追加商品(category=4)で、join_3hourが1のがあるかチェック
                    this.selected_variants.find(variant => variant.join_3hour == 1 && variant.category ==4) 
                ){
                    let chk = true
                    // 一つだけで良いから
        
                    const tmps = []
                    const license_ids = []

                    this.get_license_selected_variants_filter({
                        join_3hour: 1,
                    }).map(license_id => {
                        if(
                            !license_ids.includes(license_id)
                        ){
                            license_ids.push(license_id)
                        }
                    })
                    license_ids.map(license_id => {
                        this.get_selected_variants_filter({
                            join_3hour: 1,
                        }, {
                            license_id: license_id,
                        }, 1).map(vrnt => {
                            if(
                                !tmps.find(vr => vr.variant_id == vrnt.variant_id)
                            ){
                                tmps.push(vrnt)
                            }
                        })
                    })

                    tmps.filter(variant => variant.join_3hour == 1 && variant.category ==4).map(variant => {
                        // circuit選択は必須
                        if(variant.join_3hour_circuit_id == ''){
                            chk = false
                        }
                        // MFJの場合,証明書が必須となる
                        if(
                            this.chk_3hour_circuit_mfj(variant.join_3hour_circuit_id)
                            && typeof this.app_form_obj === 'object' 
                            && this.app_form_obj.path11 == ''
                        ){
                            chk = false
                        }
                        else{
                            chk = (chk)? chk: false
                        }
                        // MFJ以外に送る場合
                        if(
                            !this.chk_3hour_circuit_mfj(variant.join_3hour_circuit_id)
                             && (
                                // ライセンス番号必須
                                variant.join_3hour_circuit_license_no == '' 
                                || 
                                this.app_form_obj.path10 == ''
                            )
                        ){
                            chk = false
                        }
                        else{
                            chk = (chk)? chk: false
                        }
                    }) 
                    confirm_chk = (confirm_chk)? chk: false
                }
                else{
                    confirm_chk = false
                }
            }
            // 追加:セミナーがある場合
            if(
                this.chk_license_add && this.chk_license_join_seminar 
            ){

                const tmpss = []
                const license_ids_ = []
                // セミナーがONになってるvariantを抽出
                this.get_license_selected_variants_filter({
                        join_seminar_web: 1,
                        join_seminar_circuit: 1,
                }).map(license_id => {
                    if(
                        !license_ids_.includes(license_id)
                    ){
                        license_ids_.push(license_id)
                    }
                })
                license_ids_.map(license_id => {
                    this.get_selected_variants_filter({
                        join_seminar_web: 1,
                        join_seminar_circuit: 1,
                    }, {
                        license_id: license_id,
                    }, 1).map(vrnt => {
                        if(
                            !tmpss.find(vr => vr.variant_id == vrnt.variant_id)
                        ){
                            tmpss.push(vrnt)
                        }
                    })
                })
                
                // セミナー必須のvariantsの数と、circuit/webを選択した数が一致しているかチェック
                if(
                    // seminar受講が必要なライセンスを選択しており、
                    tmpss.find(variant => ['web', 'circuit'].includes(variant.join_seminar_type)) 
                    // その選択必要なライセンス数と、webかcircuitを選択しているライセンス数が一致している
                    && (tmpss.filter(variant => (variant.join_seminar_web == 1 || variant.join_seminar_circuit == 1)).length == tmpss.filter(variant => ['web', 'circuit'].includes(variant.join_seminar_type)).length)
                ){
                    confirm_chk = (confirm_chk)? true: false
                }
                else{
                    confirm_chk = false
                }
                // セミナーで、circuitを選択している場合(join_seminar_type=='circuit'),
                // そのvariantに、join_seminar_place,join_seminar_dateが設定されていること
                if(
                    tmpss.find(variant => variant.join_seminar_type == 'circuit') 
                ){
                    let chk = true
                    tmpss.filter(variant => variant.join_seminar_type == 'circuit').map(variant => {
                        if(
                            variant.join_seminar_place == '' || variant.join_seminar_date == ''
                        ){
                            chk = false
                        }
                    }) 
                    confirm_chk = (confirm_chk)? chk: false
                }
            }
            // 継続の場合
            if(
                this.chk_license_continue //ここに顔写真が変更されている場合のフォームチェック()
            ){

                confirm_chk= (confirm_chk)? true: false
            }

            return confirm_chk
        },
        // 継続・追加申請時に資料が必要か否か
        chk_tsuika_siryou: function(){
            return true
        },
        chk_3hour_circuit_mfj_total: function(){
            if(this.selected_variants.find(variant => this.chk_3hour_circuit_mfj(variant.join_3hour_circuit_id))){
            return true
            }
            return false
        },
        chk_3hour_circuit_mfj: function(){
            const circuit_list = this.circuit_list
            return function(circuit_id){
            if(
                typeof circuit_list === 'object' && circuit_list.find(circuit => circuit.key == circuit_id)
                && circuit_list.find(circuit => circuit.key == circuit_id)?.circuit_cd && typeof circuit_list.find(circuit => circuit.key == circuit_id)?.circuit_cd === 'string' && circuit_list.find(circuit => circuit.key == circuit_id)?.circuit_cd.startsWith('MFJ')
            ){
                return true
            }
            return false
            }
        },
        // 現年度ライセンスを保持しているか
        chk_obtainable: function(){
            if(
                typeof this.obtainable_license === 'object' && this.obtainable_license.length > 0
            ){
                return this.obtainable_license.find(license => this.chk_article_variant_filter_obtainable(license.license_cd))
            }
            return false
            
        },
        is_valid_license_chk: function(){
            let chk = false
            if(
                typeof this.is_valid_license === 'object' && this.is_valid_license.length > 0 && 
                this.is_valid_license.find(license => parseInt(license.license_year) >= parseInt(this.get_term_year))
            ){
                chk = true
            }
            return chk
        },
        has_receipt_data: function () {
            return this.receipt !== undefined
        },
        have_and_get_license: function(){
            const tmps = []
            if(typeof this.selected_variants === 'object'){
                this.selected_variants.forEach(variant => {
                    if(
                        parseInt(variant.license_id) > 0 && 
                        !tmps.includes(parseInt(variant.license_id))
                    ){
                        // tmps.push(parseInt(variant.license_id))
                        tmps.push({
                            license_id: parseInt(variant.license_id),
                            sports_year_add: parseInt(variant.sports_year_add)
                        })
                    }
                })
            }
            if(typeof this.have_license === 'object'){
                this.have_license.forEach(license => {
                    if(
                        parseInt(license.license_cd) > 0 && 
                        !tmps.includes(parseInt(license.license_cd))
                    ){
                        // tmps.push(parseInt(license.license_id))
                        const sports_year_add = (parseInt(this.get_term_year) == parseInt(license.license_year))? 0: 1
                        tmps.push({
                            license_id: parseInt(license.license_cd),
                            sports_year_add: parseInt(sports_year_add)
                        })
                    }
                })
            }
            // if(typeof this.have_variants === 'object'){
            //     this.have_variants.forEach(variant => {
            //         if(
            //             parseInt(variant.license_id) > 0 && 
            //             !tmps.includes(parseInt(variant.license_id))
            //         ){
            //             tmps.push(parseInt(variant.license_id))
            //         }
            //     })
            // }
            return tmps
        },
        is_valid_license: function(){
            return (typeof (this.data_obj || {}).member_license === 'object')?
            // (this.data_obj || {}).member_license.filter(license => license.is_valid == 1).sort((a, b) => {
            (this.data_obj || {}).member_license.filter(license => parseInt(license.license_year) >= parseInt(this.get_term_year)).sort((a, b) => {
                    if(parseInt(a.license_category) < parseInt(b.license_category)){
                        return -1;
                    }else{
                        return 1;
                    }
                })
            : []
        },
        is_valid_license_past: function(){
            return (typeof this.get_past_valid_license === 'object')?
            // (this.data_obj || {}).member_license.filter(license => license.is_valid == 1).sort((a, b) => {
            [...this.get_past_valid_license].sort((a, b) => {
                    if(parseInt(a.license_category) < parseInt(b.license_category)){
                        return -1;
                    }else{
                        return 1;
                    }
                })
            : []
        },
        get_past_valid_license: function(){
            // ライセンスIDごとに最新の年のオブジェクトを保持するためのマップ
            const latestMap = {};

            if(typeof this.data_obj?.member_license === 'object'){
                // this.data_obj.member_license.forEach(license => {
                this.data_obj.member_license.filter(license => license.license_to_id==0)
                    .forEach(license => {
                    // const { license_year, license_cd } = license;
                    // まだライセンスIDが登録されていない場合、または新しい年の場合に更新
                    // if (!latestMap[license_cd] || latestMap[license_cd].license_year < license_year) {
                    // latestMap[license_cd] = license;
                    // const { license_year, license_event_cd } = license;
                    const { license_term_start_date, license_event_cd, license_category } = license;
                    const start = (typeof license_term_start_date === 'string')? license_term_start_date.replaceAll("-", ""): 0
                    const key = license_event_cd + '-' + license_category
                    // まだ競技が登録されていない場合、または新しい年の場合に更新
                    if (!latestMap[key] || parseInt(latestMap[key].start) < parseInt(start)) {
                        license.start = start
                        latestMap[key] = license;
                    }
                });
            }
            // 最新のオブジェクトのみを含む配列を返す
            return Object.values(latestMap);
        },

        obtainable_race_year: function(){
            const tmps = []
            if(
                this.selected_year.length > 0
            ){
                this.selected_year.forEach(year_add => {
                    if(this.race_sports_year_obj.find(year => (year.key == year_add))){
                        tmps.push(parseInt(this.race_sports_year_obj.find(year => (year.key == year_add)).label))
                    }
                })
            }
            return tmps
        },
        obtainable_license: function(){
            // const tmps = [];
            if(
                typeof this.is_valid_license === 'object'
                && typeof this.selected_year === 'object'
                && this.selected_year.length > 0
            ){
                return this.is_valid_license_past.filter(license => {
                    const tmp_valid = this.is_valid_license_past.filter(lc => lc.id != license.id)
                    if(
                        !this.obtainable_race_year.includes(parseInt(license.license_year)) 
                        && typeof this.obtainable_race_year.filter(year => year > parseInt(license.license_year)) === 'object'
                        // && this.obtainable_race_year.filter(year => year > parseInt(license.license_year)).length === this.obtainable_race_year.length
                        && (
                            (!tmp_valid || (typeof tmp_valid === 'object' && tmp_valid.length == 0))
                            || 
                            !tmp_valid.filter(vlicense => (vlicense.license_cd == license.license_cd && this.obtainable_race_year.includes(parseInt(vlicense.license_year))))
                            || 
                            tmp_valid.filter(vlicense => (vlicense.license_cd == license.license_cd && this.obtainable_race_year.includes(parseInt(vlicense.license_year))))?.length == 0
                        )
                    ){
                        return true
                    }
                })
            }else{
                // console.log('error license errror');
                return []
            }
        },
        // 継続・更新申請
        chk_article_variant_filter_obtainable: function(){
            const get_func = this.article_variant_filter_obtainable;
            return function(license_cd){
                if(
                    typeof get_func(license_cd) === 'object'
                    && get_func(license_cd).length > 0
                ){
                    return true;
                }
                return false;
            }
        },
        article_variant_filter_obtainable: function(){
            const tmps = this.article_variant_filter_obtainable_active;
            // console.log('chk variant data article_variant_filter_obtainable_active', tmps);
            return function(license_cd){
                // return tmps.filter(tmp => tmp.license_category == category_id);
                return tmps.filter(tmp => parseInt(tmp.license_id) == parseInt(license_cd)).sort((a, b) => {
                    if(parseInt(a.license_category) < parseInt(b.license_category)){
                        return -1;
                    }
                    else if(parseInt(a.default_price) < parseInt(b.default_price)){
                        return -1;
                    }else{
                        return 1;
                    }
                })
            };
        },
        // 継続(現役/有効なライセンス持ち)
        article_variant_filter_obtainable_active: function(){
            return this.article_variant_filter_lc_and_year(5)
            // .filter(variant => variant.category==5)
            .filter(variant => {
                if(
                    typeof this.obtainable_license === 'object'
                    && this.obtainable_license.find(license => {
                        if(
                            // 競技一致
                            license.license_event_cd == variant.license_event_cd
                            && license.license_category == variant.license_category
                            // 年度
                            && (
                                license.license_year < (parseInt(variant.sports_year_add) + parseInt(this.get_term_year)) 
                                // || 
                                // license.license_year != (parseInt(variant.sports_year_add) + parseInt(this.get_term_year)) 
                            )
                        ){
                            return true
                        }
                        return false
                    })
                    // typeof this.have_license === 'object'
                    // && this.have_license.find(lc => {
                    //     if(
                    //         // ライセンスが一致
                    //         lc.license_event_cd == variant.license_event_cd
                    //         // 年度が違う必要がある
                    //         && lc.license_year < (parseInt(variant.sports_year_add) + parseInt(this.get_now_date_year))
                    //     ){
                    //         return true
                    //     }
                    //     return false;
                    // })
                ){
                    return true;
                }
                return false;
            });
        },

        ...mapState({
        }),
        get_payer: function(){
            const payer_obj = {}
            // payer_obj.name = (this.data_obj || {}).name_1 + (this.data_obj || {}).name_2
            payer_obj.name = (this.data_obj || {}).yomi_1 + (this.data_obj || {}).yomi_2
            payer_obj.nameKana = (this.data_obj || {}).yomi_1 + (this.data_obj || {}).yomi_2
            payer_obj.phoneNumber = (this.data_obj || {}).tel_1
            const phone_tmp = {}
            phone_tmp.number = (this.data_obj || {}).tel_1
            phone_tmp.countryCode = '81'
            if(
            typeof (this.data_obj || {}).tel_1 === 'string' && 
            (
                (this.data_obj || {}).tel_1.startsWith('090') || 
                (this.data_obj || {}).tel_1.startsWith('080') || 
                (this.data_obj || {}).tel_1.startsWith('070')
            )
            ){
            phone_tmp.type = 'MOBILE'
            }
            else{
            phone_tmp.type = 'MOBILE'
            }
            payer_obj.phones = []
            payer_obj.phones.push(phone_tmp)
            return payer_obj
        },
        get_merchant: function () {
            const merchant_obj = { ...this.merchant_obj }
            merchant_obj.callbackUrl = config.GMO_CALLBACK_URL + '/request/3ds'
            merchant_obj.webhookUrl = config.GMO_WEBHOOK_URL

            return merchant_obj
        },
        get_order: function(){
            const order = {
            orderId: (this.sales_obj || {}).charge_order_id,
            amount: (this.sales_obj || {}).amount,
            currency: 'JPY',
            // clientFields: [],
            // items: [],
            }
            if(
            typeof (this.sales_obj || {}).sales_orders === 'object' && 
            (this.sales_obj || {}).sales_orders.length > 0
            ){
            order.items = []
            this.sales_obj.sales_orders.map(sales_order => {
                order.items.push({
                name: sales_order.sub_label,
                quantity: sales_order.num,
                type: 'DIGITAL',
                price: sales_order.list_price,
                })
            }) 
            }
            return order
        },
        charge_btn_display: function () {
            if (this.charge_mode && (this.data_obj || {}).id > 0) {
            return true
            }
            return false
        },
        credit_expire_y_list: function () {
            const nowY = parseInt(Common.getNowYear())
            const maxY = nowY + 15
            const tmps = []
            for (let y = nowY; y <= maxY; y++) {
            tmps.push({
                key: y,
                label: y,
            })
            }
            return tmps
        },
      birth_date_month_list: function () {
        const tmps = []
        for (let i = 1; i <= 12; i++) {
          tmps.push({
            key: i,
            label: i,
          })
        }
        return tmps
      },

        get_class_charge_type: function () {
            const selected_charge_type = this.selected_charge_type
            return function (charge_type) {
            return selected_charge_type == charge_type
                ? 'btn btn-warning'
                : 'btn btn-light btn-outline-secondary'
            }
        },
        // 顔写真を変えてる
        // 
        // 顔写真のURL
        photoImageUrl() {
            console.log('this.app_form_obj', this.app_form_obj)
            const path = this.app_form_obj['path' + 3];
            return path ? `${this.BASE_ASSETS_URL}${path.replace('https://localhost/mfj_renewal/api/assets', '')}` : '';
        },
        // 運転免許証のURL
        licenseImageUrl() {
            const path = this.app_form_obj['path' + 4];
            return path ? `${this.BASE_ASSETS_URL}${path.replace('https://localhost/mfj_renewal/api/assets', '')}` : '';
        },
        get_target_com_item: function(){
            const communication = this.communication_obj;
            return function(app_chk_cd){
                if(
                    typeof communication === 'object'
                    && typeof communication.items === 'object'
                ){
                    return communication.items.find(com => com.application_check_cd == app_chk_cd);
                }
                return {
                    item_comment: '',
                    check_flg: 0,
                };
            }
        },
        get_target_com_msg: function(){
            const communication = this.get_target_com_item;
            return function(app_chk_cd){
                if(
                    (communication(app_chk_cd) || {}).item_comment
                ){
                    return (communication(app_chk_cd) || {}).item_comment;
                }
                return false;
            }
        },
        get_target_app_item: function(){
            const application = this.application_obj;
            return function(app_key){
                if(
                    typeof application === 'object'
                    && typeof application.items === 'object'
                ){
                    return application.items.find(app => app.application_key == app_key);
                }
                return {
                    application_value: '',
                };
            }
        },
        get_sports_age: function(){
            const now_year = parseInt(this.get_now_date_num_y)
            const now_mon = parseInt(this.get_now_date_num_m)
            const birth_year = parseInt(this.data_obj?.birth_date_year)
            return function(year_add){
                // 4-12月は年度に応じて
                if(birth_year > 0 && now_mon>=4 && now_mon<=12){
                    return (now_year + year_add) - birth_year
                }
                else if(birth_year > 0 && now_mon<=3 && now_mon>=1){
                    return (now_year) - birth_year
                }
                return 0
            }
        },
        get_school_age: function () {
            const term_year = parseInt(this.get_term_year)
            const birth_date = moment([
                this.data_obj.birth_date_year,
                parseInt(this.data_obj.birth_date_month) - 1,
                this.data_obj.birth_date_day,
            ]).format('YYYY-MM-DD')
            return function(year_add){
            const set_year = parseInt(term_year) + parseInt(year_add)
            const birth = new Date(birth_date)
            const target = new Date(set_year + '-04-01')
            let age = target.getFullYear() - birth.getFullYear()
            const monthDiff = target.getMonth() - birth.getMonth()
            const dayDiff = target.getDate() - birth.getDate()
            if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
                age--
            }
            return parseInt(age)
            }
        },
        get_age: function(){
            if(
                this.data_obj.birth_date_year 
                && this.data_obj.birth_date_month 
                && this.data_obj.birth_date_day 
            ){
                const birth_date = moment([this.data_obj.birth_date_year, (parseInt(this.data_obj.birth_date_month) - 1), this.data_obj.birth_date_day]).format('YYYY-MM-DD');
                return this.calc_sports_age(birth_date);
            }
            return '';
        },
        chk_selected_variants: function(){
            const selected_variants = this.selected_variants;
            return function(variant_id){
                return selected_variants.find(variant => variant.variant_id == variant_id)
            }
        },
        
        get_selected_variants_filter: function () {
            const selected_variants = this.selected_variants
            return function (params, r_params, limit) {
                if (typeof selected_variants === 'object') {
                    const tmpss = selected_variants.filter((variant) => {
                    let ret = false
                    if (typeof params === 'object') {
                        Object.keys(params).forEach((key) => {
                        if (variant[key] == params[key]) {
                            ret = true
                        }
                        })
                    }
                    return ret
                    })
                    // console.log('get_selected_variants_filter r_params', r_params)
                    const tmps = (typeof r_params === 'object' && Object.keys(r_params).length > 0)? tmpss.filter((variant) => {
                    let ret = true
                    Object.keys(r_params).forEach((key) => {
                        // console.log('get_selected_variants_filter key', key)
                        // console.log('get_selected_variants_filter variant[key]', variant[key])
                        // console.log('get_selected_variants_filter r_params[key]', r_params[key])
                        if (variant[key] != r_params[key]) {
                        ret = false
                        }
                    })
                    return ret
                    }): tmpss
                    if (
                    limit !== false &&
                    typeof tmps === 'object' &&
                    tmps.length >= limit
                    ) {
                    return tmps.splice(0, limit)
                    } else {
                    return tmps
                    }
                }
                return false
            }
        },

        get_license_selected_variants_filter: function(){
            const get_filter = this.get_selected_variants_filter
            return function(params){
                const tmps = []
                get_filter(params, {}, false).forEach(variant => {
                    if(variant.license_id > 0 && !tmps.includes(parseInt(variant.license_id))){
                    tmps.push(parseInt(variant.license_id))
                    }
                })
                return tmps
            }
        },
        get_message: function(){
            if(
                this.license_mode
            ){
                return 'ライセンス追加・継続を申請できます。';
            }
            else if(
                this.form_mode
            ){
                return 'ライセンス申請に必要な項目を入力してください。';
            }
            else if(
                this.remand_mode
            ){
                return '差し戻しがありました。';
            }
            else if(
                this.confirm_mode
            ){
                return '下記項目で問題なければ申請を行ってください。';
            }
            else if(
                this.check_mode
            ){
                return '現在MFJ事務局で申請内容を確認中です。3営業日中に返答いたします。';
            }
            else if(
                this.charge_mode
            ){
                return 'ライセンス申請料金をお支払いください';
            }
            else if(
                this.error_mode
            ){
                return 'エラーがあります';
            }
            else if(
                this.success_mode
            ){
                return 'ライセンス継続もしくは追加の申請手続きが完了いたしました。マイページのHOMEページでライセンスをご確認下さい。';
            }
            else if(
                this.cash_unpaid
            ){
                return 'コンビニでのお支払い後、マイページのHOMEでライセンスをご確認下さい。';
            }
            return '';
        },

        disable_go_app: function(){
            if(
                this.selected_variants.length > 0
            ){
                return false
            }
            return true;
        },
        disable_go_confirm: function(){
            return false;
        },
        disable_go_update: function(){
            return false;
        },
        disable_go_charge: function(){
            return false;
        },

        // 親権者
        parent_frm_disp: function () {
            if (
                typeof this.selected_variants === 'object' &&
                this.selected_variants.find((variant) => variant.join_parent_frm == 1)
            ) {
                return true
            }
            return false
        },
        // 未成年者
        // 参加承諾書
        chk_parent_accept: function () {
            if (
            typeof this.selected_variants === 'object' &&
            this.selected_variants.find(
                (variant) => parseInt(variant.join_parent_accept) >= 1 && parseInt(variant.join_parent_accept) >= this.get_sports_age(parseInt(variant.sports_year_add))
            )
            ) {
            return true
            }
            return false
        },
        // 継続が入っているか
        chk_license_continue: function(){
            if(
                typeof this.selected_variants === 'object'
                && this.selected_variants.length > 0
                && this.selected_variants.find(variant => variant.category == 5)
            ){
                return true;
            }
            return false;
        },
        // 追加が入っているか
        chk_license_add: function(){
            if(
                typeof this.selected_variants === 'object'
                && this.selected_variants.length > 0
                && this.selected_variants.find(variant => variant.category == 4)
            ){
                return true;
            }
            return false;
        },
        // 3時間走行証明(追加のみ)
        chk_license_join_3hour: function(){
            if(
                typeof this.selected_variants === 'object'
                && this.selected_variants.length > 0
                && this.selected_variants.find(variant => variant.join_3hour == 1 && variant.category == 4)
            ){
                return true;
            }
            return false;
        },
        // 講習会ありか
        chk_license_join_seminar: function(){
            if(
                this.chk_license_join_seminar_web == true 
                || this.chk_license_join_seminar_circuit == true
            ){
                return true;
            }
            return false;
        },
        // Web講習会
        chk_license_join_seminar_web: function(){
            if(
                typeof this.selected_variants === 'object'
                && this.selected_variants.length > 0
                && this.selected_variants.find(variant => variant.join_seminar_web == 1)
            ){
                return true;
            }
            return false;
        },
        // 対面講習会
        chk_license_join_seminar_circuit: function(){
            if(
                typeof this.selected_variants === 'object'
                && this.selected_variants.length > 0
                && this.selected_variants.find(variant => variant.join_seminar_circuit == 1)
            ){
                return true;
            }
            return false;
        },
        // Webを選択しているか
        chk_order_license_join_seminar_web: function(){
            if(
                this.chk_license_join_seminar_web
                && typeof this.selected_variants === 'object'
                && this.selected_variants.length > 0
                && this.selected_variants.find(variant => variant.join_seminar_type == 'web')
            ){
                return true;
            }
            return false;
        },
        // 対面を選択しているか
        chk_order_license_join_seminar_circuit: function(){
            if(
                this.chk_license_join_seminar_circuit
                && typeof this.selected_variants === 'object'
                && this.selected_variants.length > 0
                && this.selected_variants.find(variant => variant.join_seminar_type == 'circuit')
            ){
                return true;
            }
            return false;
        },


        get_list_label: function(){
            return function(key, lists){
            if(
                typeof lists === 'object'
                && typeof lists.find(list => list.key == key) === 'object'
            ){
                return lists.find(list => list.key == key).label;
            }
            return key;
            }
        },
        get_list_key: function(){
            return function(label, lists){
            if(
                typeof lists === 'object'
                && typeof lists.find(list => list.label == label) === 'object'
            ){
                return lists.find(list => list.label == label).key;
            }
            return label;
            }
        },
        circuit_list: function(){
            const lists = [...this.circuit_obj];
            return lists.map(
                (list) => {
                list.key = list.id;
                // list.class_label = this.get_list_param('license_class', list.license_class, 'label');
                // list.category_label = this.get_category_label('license_category', list.license_category, 'label');
                return list;
                }
            ).sort((a, b) => {
                if(parseInt(a.sort) < parseInt(b.sort)){
                    return -1;
                }else{
                    return 1;
                }
            });
        },
        // 下の強化版-同一ライセンスをチェック
        get_license_sel_variant_f: function () {
            const selected = this.selected_variants
            return function (variant_id, year_key, license_id) {
            if (
                typeof selected === 'object' &&
                selected.find(
                (select) =>
                    (select.variant_id == variant_id || select.license_id == license_id) &&
                    parseInt(select.sports_year_add) == parseInt(year_key)
                )
            ) {
                return true
            }
            return false
            }
        },
        get_license_sel_variant: function(){
            const selected = this.selected_variants;
            return function(variant_id, year_key){
                if(
                    typeof selected ==='object'
                    && selected.find(select => (select.variant_id == variant_id && select.sports_year_add == year_key))
                ){
                    return true;
                }
                return false;
            }
        },
        get_license_sel_variant_class: function(){
            const check = this.get_license_sel_variant;
            return function(variant_id){
                if(
                    check(variant_id)
                ){
                    return 'btn btn-warning'
                }
                return 'btn btn-light btn-outline-secondary';
            }
        },
        chk_license_sel_variant: function(){
            const selected_variants = this.selected_variants;
            // const have_license = this.have_license;
            const license_cd_obj = this.license_cd_obj;
            // const now_year = this.get_now_date_year;
            // console.log('chk_license_sel_variant', license_cd_obj);
            return function(license_id, license_event_cd){
                const license_cd = license_cd_obj.find(license => license.id == license_id)
                if(
                    typeof selected_variants === 'object'
                    && selected_variants.find(variant => variant.license_id == license_id)
                ){
                    return 1
                }
                if(
                    typeof license_cd == 'object'
                    && typeof selected_variants === 'object'
                    && (
                        selected_variants.find(variant => (
                            variant.license_event_cd == license_event_cd
                            && variant.license_category == license_cd.license_category
                        ))
                        // || 
                        // have_license.find(lc => (
                        //     lc.license_event_cd == license_event_cd
                        //     && lc.license_year == (parseInt(variant.sports_year_add) + parseInt(this.get_now_date_year))
                        //     && lc.license_category == license_cd.license_category
                        // ))
                    )
                ){

                    return 10
                }
                return false;
            }
        },
        get_class_variant_license: function(){
            const check = this.chk_license_sel_variant;
            return function(license_id, license_event_cd){
                const chk = check(license_id, license_event_cd);
                if(chk == 10){
                    return 'btn btn-secondary'
                }
                else if(
                    chk == 1
                ){
                    return 'btn btn-warning'
                }
                return 'btn btn-light btn-outline-secondary';
            }
        },
        get_now_date: function(){
            return (
                typeof this.now_date_obj === 'object' 
                && typeof this.now_date_obj[0] === 'object'
            )? this.now_date_obj[0]: false;
        },
        get_now_date_num: function(){
            return (
                typeof this.get_now_date === 'object'
            )? this.get_now_date.date_num: parseInt(moment().format('YYYYMMDD'))
        },
        get_now_date_year: function(){
            return (
                typeof this.get_now_date === 'object'
            )? this.get_now_date.y: parseInt(moment().format('YYYY'))
        },
        get_now_date_num_y: function () {
            return typeof this.get_now_date === 'object'
            ? this.get_now_date.y
            : parseInt(moment().format('YYYY'))
        },
        get_now_date_num_m: function () {
            return typeof this.get_now_date === 'object'
            ? this.get_now_date.m
            : parseInt(moment().format('M'))
        },
        get_now_date_num_d: function () {
            return typeof this.get_now_date === 'object'
            ? this.get_now_date.m
            : parseInt(moment().format('D'))
        },

        get_term_year: function(){
            return (
                typeof this.term_year_obj === 'object' 
                && typeof this.term_year_obj[0] === 'object'
                && this.term_year_obj[0]['label']
            )? parseInt(this.term_year_obj[0]['label']): parseInt(Common.getNowYear()); 
        },
        get_term_date: function(){
            return (
                typeof this.term_date_obj === 'object' 
                && typeof this.term_date_obj[0] === 'object'
            )? this.term_date_obj[0]: false;
        },
        get_term_date_param: function(){
            const term_date = this.get_term_date;
            return function(param){
                if(
                    term_date
                ){
                    return parseInt(term_date[param]);
                }
                return 0;
            }
        },
        get_article_variant_insurance: function () {
            return this.article_variant_obj.filter(
            (variant) => variant.category == 1
            )
        },
        get_article_variant_insurance_age: function () {
            return this.article_variant_obj.filter(
            (variant) => variant.category == 1
            )
        },
        get_article_variant_commision: function () {
            return this.article_variant_obj.filter(
            (variant) => variant.category == 2
            )
        },
        get_article_variant_commision_year: function () {
            return this.article_variant_obj.filter(
            (variant) =>
                variant.category == 2 &&
                this.selected_year.includes(parseInt(variant.sports_year))
            )
        },
        license_cd_list: function(){
            const lists = [...this.license_cd_obj];
            return lists.map(
                (list) => {
                list.key = list.license_cd;
                // list.class_label = this.get_list_param('license_class', list.license_class, 'label');
                // list.category_label = this.get_category_label('license_category', list.license_category, 'label');
                return list;
                }
            ).sort((a, b) => {
                if(parseInt(a.sort) < parseInt(b.sort)){
                    return -1;
                }else{
                    return 1;
                }
            });
        },
        article_variant_filter_year: function(){
            // const term_year_add = this.get_term_date_param('year_add');
            // const term_md = this.get_term_date_param('md');
            // const term_year =  this.get_term_year;
            const selected_year = this.selected_year;
            const variant_obj = this.article_variant_obj;
            const get_now_date_num = this.get_now_date_num;
            const selected_package_flg = this.selected_package_flg;
            // const get_age = this.get_age;
            const get_sports_age = this.get_sports_age
            const get_school_age = this.get_school_age
            return function(article_category){
                return variant_obj
                .filter(variant => variant.category == article_category)
                .filter(variant => {
                    const target_age = [1,2].includes(parseInt(article_category))
                    ? get_school_age(parseInt(variant.sports_year_add))
                    : get_sports_age(parseInt(variant.sports_year_add))
                    if(
                        selected_year.includes(parseInt(variant.sports_year_add))
                        && (
                            variant.over_age == 0
                            || (
                                variant.over_age > 0
                                && variant.over_age <= target_age
                            )
                        )
                        && (
                            variant.under_age == 0
                            || (
                                variant.under_age > 0
                                && variant.under_age >= target_age
                            )
                        )
                        && parseInt(get_now_date_num) >= parseInt(variant.start_date_num)
                        && parseInt(get_now_date_num) <= parseInt(variant.end_date_num)
                        && (
                            (
                                parseInt(selected_package_flg) == 0
                                && parseInt(variant.special_cd) != 100
                            ) || (
                                parseInt(selected_package_flg) == 1
                                && parseInt(variant.special_cd) == 100
                            )
                        )
                    ){
                        return true;
                    }
                    return false;
                });
            }
        },
        // article_variant_filter_year_and_not_exclude: function(){
        //     const variant_obj = this.article_variant_filter_year;
        //     return variant_obj
        //     .filter(variant => {
        //         if(
        //             variant.get_condition_flg == 0
        //             || (
        //                 variant.get_condition_flg == 1
        //                 && typeof variant.get_condition === 'object'
        //                 && variant.get_condition.find(cond => {
        //                     if(
        //                         this.have_variants.find(vrnt => vrnt.license_id == cond.license_id)
        //                     ){
        //                         return true;
        //                     }
        //                     return false;
        //                 })
        //             )
        //         ){
        //             return true;
        //         }
        //         return false;
        //     })
        //     .filter(variant => {
        //         const license_cd = this.license_cd_obj.find(license => license.id == variant.license_id);
        //         console.log('license_id license_id license_id ', license_cd,variant, this.license_cd_obj);
        //         if(!license_cd){
        //             return true;
        //         }
        //         else if(
        //             typeof license_cd.license_exclude !== 'object'
        //             || (typeof license_cd.license_exclude === 'object' && license_cd.license_exclude.length <= 0)
        //         ){
        //             return true;
        //         }
        //         else if(
        //             typeof license_cd.license_exclude === 'object'
        //             && license_cd.license_exclude.find(license_exclude => {
        //                 if(
        //                     this.have_variants.find(vrnt => vrnt.license_id == license_exclude.target_id)
        //                 ){
        //                     return true
        //                 }
        //                 return false;
        //             })
        //         ){
        //             return false;
        //         }
        //         else{
        //             return true;
        //         }

        //     })
        //     .sort((a, b) => {
        //         if(parseInt(a.default_price) < parseInt(b.default_price)){
        //             return -1;
        //         }else{
        //             return 1;
        //         }
        //     });
        // },

        article_variant_filter_year_and_have_variants: function(){
            const variant_obj = this.article_variant_filter_year;
            const have_variants = this.have_variants;
            // const have_license = this.have_license || [];
            const license_cd_obj = this.license_cd_obj || [];
            const have_and_get_license = this.have_and_get_license || []
            return function (article_category){
                const variant_obj_ = [...variant_obj(article_category)];
                const variant_obj_f = variant_obj_
                    .filter(variant => {
                        if(
                            variant.get_condition_flg == 0
                            || (
                                variant.get_condition_flg == 1
                                && typeof variant.get_condition === 'object'
                                && variant.get_condition.find(cond => {
                                    if(
                                        // have_variants.find(vrnt => vrnt.license_id == cond.license_id)
                                        // have_license.find(license => license.license_cd == cond.license_id)
                                        // have_and_get_license.includes(parseInt(cond.license_id))
                                        typeof have_and_get_license.find(obj => 
                                        (
                                            parseInt(obj.license_id) == parseInt(cond.license_id) 
                                            && parseInt(obj.sports_year_add) == parseInt(variant.sports_year_add)
                                        )) === 'object'
                                    ){
                                        return true;
                                    }
                                    return false;
                                })
                            )
                        ){
                            return true;
                        }
                        return false;
                    });
                const variant_obj_f_f = variant_obj_f
                    .filter(variant => {
                        const license_cd = license_cd_obj.find(license => license.id == variant.license_id);
                        // console.log('license_id license_id license_id ', license_cd,variant, license_cd_obj);
                        if(!license_cd){
                            return true;
                        }
                        else if(
                            typeof license_cd.license_exclude !== 'object'
                            || (typeof license_cd.license_exclude === 'object' && license_cd.license_exclude.length <= 0)
                        ){
                            return true;
                        }
                        else if(
                            typeof license_cd.license_exclude === 'object'
                            && license_cd.license_exclude.find(license_exclude => {
                                if(
                                    have_variants.find(vrnt => vrnt.license_id == license_exclude.target_id)
                                ){
                                    return true
                                }
                                return false;
                            })
                        ){
                            return false;
                        }
                        else{
                            return true;
                        }
                    })
                const variant_obj_f_f_s = variant_obj_f_f
                    .sort((a, b) => {
                        // if(parseInt(a.sort) < parseInt(b.sort)){
                        if(parseInt(a.default_price) < parseInt(b.default_price)){
                            return -1;
                        }else{
                            return 1;
                        }
                    });
                return variant_obj_f_f_s;
            }
        },

        article_variant_filter_lc_and_year: function(){
            const variant_obj = this.article_variant_filter_year_and_have_variants
            const selected_variants = this.selected_variants;
            const selected_package_flg = this.selected_package_flg;
            return function(article_category){
                const tmpstmps = [];
                variant_obj(article_category)
                // .filter(variant => variant.category == article_category)
                .forEach(variant_ => {
                    const variant = {...variant_}
                    if(
                        !tmpstmps.find(tmp => tmp.license_id == variant.license_id)
                        && (
                            // variants 配列じゃない
                            typeof selected_variants !== 'object' 
                            || (
                                // variants 配列 だけど空
                                typeof selected_variants === 'object' 
                                && selected_variants.length <= 0
                            ) 
                            // variants 配列で既に何か入ってる
                            || (
                                typeof selected_variants === 'object'
                                && selected_variants.length > 0
                                // そして、一致している
                                && (
                                    (
                                        !selected_variants.find(selv => selv.license_id == variant.license_id)
                                    ) || (
                                        selected_variants.find(selv => selv.license_id == variant.license_id) 
                                        && selected_variants.find(selv => selv.license_id == variant.license_id && variant.id == selv.variant_id)
                                    )
                                )
                            )
                        )
                    ){
                        const price_list = [0, 0];
                        price_list[variant.sports_year_add] = variant.default_price;
                        const package_variant_ids = [0, 0];
                        package_variant_ids[variant.sports_year_add] = variant.id;
                        const package_special_cd = [0, 0];
                        package_special_cd[variant.sports_year_add] = variant.special_cd;
                        tmpstmps.push({
                            variant_id: variant.id,
                            license_label: variant.license_label,
                            license_id: variant.license_id,
                            license_event_cd: variant.license_event_cd,
                            license_category: variant.license_category,
                            category: variant.category,
                            default_price: variant.default_price,
                            price_list: price_list,
                            package_variant_ids: package_variant_ids,
                            package_special_cd: package_special_cd,
                            sports_year_add: variant.sports_year_add,
                            sort: variant.sort,
                            description: variant.description,
                        })
                    }
                    else if(
                        tmpstmps.find(tmp => tmp.license_id == variant.license_id)
                    ){
                        const tmpsIndex = tmpstmps.findIndex(tmp => tmp.license_id == variant.license_id);
                        
                        if(
                            selected_variants.find(sval => sval.variant_id == variant.variant_id)
                        ){
                            tmpstmps[tmpsIndex].price_list[variant.sports_year_add] = parseInt(selected_variants.find(sval => sval.variant_id == variant.variant_id)?.price)
                        }
                        else if(
                            !selected_variants.find(sval => sval.variant_id == variant.variant_id) && 
                            parseInt(variant.default_price) > 0
                            && tmpstmps[tmpsIndex].price_list[variant.sports_year_add] == 0
                        ){
                            tmpstmps[tmpsIndex].price_list[variant.sports_year_add] = variant.default_price;
                        }
                        if(
                            parseInt(variant.id) > 0
                            && tmpstmps[tmpsIndex].package_variant_ids[variant.sports_year_add] == false
                        ){
                            tmpstmps[tmpsIndex].package_variant_ids[variant.sports_year_add] = variant.id;
                        }
                    }

                });

                //
                const tmptmp = (selected_package_flg > 0)? 
                tmpstmps.filter(tmp => {
                        if(
                            tmp.package_variant_ids.length > 1
                            && tmp.package_variant_ids.filter(vid => parseInt(vid) > 0).length > 1
                        ){
                            return true;
                        }
                        return false;
                    }): tmpstmps;
                    // console.log('article_variant_filter_lc_and_year return ', article_category, tmptmp)
                return [...tmptmp];
            }
        },

        chk_article_variant_filter_lc_and_year_and_acate: function () {
            const get_func = this.article_variant_filter_lc_and_year_and_acate
            return function (category_id) {
            if (
                typeof get_func(category_id) === 'object' &&
                get_func(category_id).length > 0
            ) {
                return true
            }
            return false
            }
        },
        article_variant_filter_lc_and_year_and_acate: function () {
            const article_variant_filter_year = this.article_variant_filter_year
            // console.log('article_variant_filter_lc_and_year_and_acate tmps', tmps)
            return function (category_id) {
            // console.log(
            //     'article_variant_filter_lc_and_year_and_acate function category_id',
            //     category_id
            // )
            // console.log(
            //     'article_variant_filter_lc_and_year_and_acate function tmps',
            //     tmps.filter((tmp) => tmp.category == category_id)
            // )
                const tmps = article_variant_filter_year(category_id);
            return tmps.filter((tmp) => tmp.category == category_id)
            }
        },
        
        chk_article_variant_filter_renewal: function(){
            const get_func = this.article_variant_filter_renewal;
            return function(category_id){
                if(
                    typeof get_func(category_id) === 'object'
                    && get_func(category_id).length > 0
                ){
                    return true;
                }
                return false;
            }
        },
        // 継続・更新申請
        article_variant_filter_renewal: function(){
            const tmps = this.article_variant_filter_renewal_active;
            console.log('chk variant data article_variant_filter_renewal', tmps);
            return function(category_id){
                if(category_id == 1) console.log('article_variant_filter_renewal', category_id, tmps);
                // return tmps.filter(tmp => tmp.license_category == category_id);
                return tmps.filter(tmp => parseInt(tmp.license_category) == parseInt(category_id)).sort((a, b) => {
                    if(parseInt(a.default_price) < parseInt(b.default_price)){
                        return -1;
                    }else{
                        return 1;
                    }
                })
            };
        },
        // 継続(現役/有効なライセンス持ち)
        article_variant_filter_renewal_active: function(){
            return this.article_variant_filter_lc_and_year(5)
            // .filter(variant => variant.category==5)
            .filter(variant => {
                if(
                    typeof this.have_license === 'object'
                    && this.have_license.find(lc => {
                        if(
                            // ライセンスが一致
                            lc.license_event_cd == variant.license_event_cd
                            // 年度が違う必要がある
                            && lc.license_year < (parseInt(variant.sports_year_add) + parseInt(this.get_now_date_year))
                        ){
                            return true
                        }
                        return false;
                    })
                ){
                    return true;
                }
                return false;
            });
        },

        // 追加article.category==4
        chk_article_variant_filter_add: function(){
            const get_func = this.article_variant_filter_add;
            return function(category_id){
                if(
                    typeof get_func(category_id) === 'object'
                    && get_func(category_id).length > 0
                ){
                    return true;
                }
                return false;
            }
        },
        // article_variant_filter_obtainable_v2: function(){
        //     const obtainable_lc = this.article_variant_filter_obtainable
        //     return function(license_cd){
        //         return obtainable_lc(license_cd)
        //     }
        // },
        article_variant_filter_add: function(){
            const have_lc = this.have_license;
            // article_variant_filter_obtainable(license.license_cd)
            const obtainable_lc = this.article_variant_filter_obtainable
            const article_variant_filter_lc_and_year = this.article_variant_filter_lc_and_year(4)
            const tmps = article_variant_filter_lc_and_year
            .filter(variant => {
                // 既に持っている種目を除外(追加のため)
                if(
                    typeof have_lc === 'object'
                    && have_lc.find(lc => {
                        // フレッシュマン(license_cd:2)保持で、フレッシュマン商品はNG
                        // フレッシュマン→国内以降用スクリプト下
                        // これに加え、charge_finishを修正する必要有
                        if(
                            (lc.license_cd == 2)
                            && 
                            (
                                (
                                    variant.license_id != lc.license_cd
                                    && variant.license_id == 3 
                                    && lc.license_event_cd == variant.license_event_cd 
                                    && lc.license_category == variant.license_category
                                    && lc.year_add == variant.sports_year_add
                                )
                            )
                        ){
                            return false
                        }
                        else if(
                            (lc.license_cd == 2 || lc.license_cd == 3)
                            && 
                            (
                                variant.license_id == lc.license_cd
                                || 
                                (
                                    variant.license_id != lc.license_cd
                                    && lc.license_event_cd == variant.license_event_cd 
                                    && lc.license_category == variant.license_category
                                    && lc.year_add == variant.sports_year_add
                                )
                            )
                        ){
                            return true
                        }
                        // フレッシュマン保持で、他ロードは同一年度はNGの
                        // else if(
                        //     lc.license_cd == 2 
                        //     && variant.license_id != lc.license_cd
                        //     && lc.license_event_cd == variant.license_event_cd 
                        //     && lc.license_category == variant.license_category
                        //     && lc.year_add == variant.sports_year_add
                        // ){
                        //     return true
                        // }
                        else if(
                            // ロード以外
                            lc.license_cd != 2 && lc.license_cd != 3
                            && 
                            lc.license_event_cd == variant.license_event_cd 
                            && lc.license_category == variant.license_category
                        ){
                            return true
                        }
                        return false;
                    })
                ){
                    return false;
                }
                // 継続に入っているものも除外
                const rdnat_flg = (Array.isArray(this.get_past_valid_license) && this.get_past_valid_license.find(license => license.license_cd == 3))? true: false
                const rdint_flg = (Array.isArray(this.get_past_valid_license) && this.get_past_valid_license.find(license => license.license_cd == 4))? true: false
                if(
                    typeof obtainable_lc(variant.license_id) === 'object'
                    && obtainable_lc(variant.license_id).find(lc => {
                        // console.log('article_variant_filter_add==', lc.license_id, obtainable_lc(variant.license_id))
                        if(
                            (
                                (lc.license_id == 2 && rdnat_flg == false)
                                ||
                                (lc.license_id == 3 && rdnat_flg == true)
                                ||
                                (lc.license_id == 3 && rdint_flg == true)
                            )
                            && (
                                variant.license_id == lc.license_id 
                            )
                        ){
                            return true
                        }
                        else if(
                            variant.license_id != 2 && variant.license_id != 3 && lc.license_event_cd != 1 && 
                            lc.license_event_cd == variant.license_event_cd 
                            && lc.license_category == variant.license_category
                        ){
                            return true
                        }
                        return false;
                    })
                ){
                    return false;
                }
                
                return true;
            })
            // .filter((variant) => {
            //     if (
            //         variant.get_condition_flg == 0 ||
            //         (
            //             variant.get_condition_flg == 1 &&
            //             typeof variant.get_condition === 'object' &&
            //             variant.get_condition.find((cond) => {
            //                 if (
            //                     this.have_and_get_license.includes(parseInt(cond.license_id))
            //                     // this.have_variants.find(
            //                     //     (vrnt) => vrnt.license_id == cond.license_id
            //                     // )
            //                 ) {
            //                     return true
            //                 }
            //                 return false
            //             })
            //         )
            //     ) {
            //         return true
            //     }
            //     return false
            // })
            return function(category_id){
                if(category_id == 3 && typeof have_lc === 'object' && have_lc.find(lc => lc.license_category == 1)){
                    return []
                }
                return tmps
                .filter(tmp => tmp.license_category == category_id).sort((a, b) => {
                    if(parseInt(a.sort) < parseInt(b.sort)){
                        return -1;
                    }else{
                        return 1;
                    }
                })
            };
        },


        chk_article_variant_filter_lc_and_year_and_lcate: function(){
            const get_func = this.article_variant_filter_lc_and_year_and_lcate;
            return function(category_id, article_category){
                if(
                    typeof get_func(category_id, article_category) === 'object'
                    && get_func(category_id, article_category).length > 0
                ){
                    return true;
                }
                return false;
            }
        },
        article_variant_filter_lc_and_year_and_lcate: function(){
            const article_variant_filter_lc_and_year = this.article_variant_filter_lc_and_year;
            return function(category_id, article_category){
                return article_variant_filter_lc_and_year(article_category).filter(tmp => tmp.license_category == category_id).sort((a, b) => {
                    if(parseInt(a.sort) < parseInt(b.sort)){
                        return -1;
                    }else{
                        return 1;
                    }
                })
            };
        },



        get_license_sel_package_class: function(){
            return (this.selected_year.length >= 2)? 'btn btn-warning': 'btn btn-light btn-outline-secondary';
        },
        get_license_sel_year_class: function(){
            const selected = this.selected_year;
            return function(year_add){
                if(
                    selected.includes(year_add)
                ){
                    return 'btn btn-warning'
                }
                else{
                    return 'btn btn-light btn-outline-secondary';
                }
            }
        },


        license_mode: function(){
            return (this.page_mode == 'sel_license')? true: false;
        },
        // 差し戻し
        remand_mode: function(){
            return (this.page_mode == 'remand')? true: false;
        },
        form_mode: function(){
            return (this.page_mode == 'form')? true: false;
        },
        confirm_mode: function(){
            return (this.page_mode == 'confirm')? true: false;
        },
        check_mode: function(){
            return (this.page_mode == 'check')? true: false;
        },
        charge_mode: function(){
            return (this.page_mode == 'charge')? true: false;
        },
        success_mode: function(){
            return (this.page_mode == 'success')? true: false;
        },
        error_mode: function(){
            return (this.page_mode == 'error')? true: false;
        },
        cash_unpaid_mode: function(){
            return (this.page_mode == 'cash_unpaid')? true: false;
        },

        change_mode: function(){
            return (this.chk_editable)? this.change_mode_value: true;
        },
        chk_editable: function(){
            if(
                typeof this.application_obj !== 'object'
                || (
                    typeof this.application_obj === 'object'
                    && this.application_obj.id == 0
                )
            ){
                return true;
            }
            return [0, 600].includes(parseInt((this.application_obj || {}).application_check_type));
        },
        get_assets: function(){
            const form_obj = this.form_obj;
            return function(type_cd, sort_key){
                if(
                    typeof form_obj === 'object'
                    && typeof form_obj.member_assets === 'object' 
                    && form_obj.member_assets.find(asset => asset.type == type_cd) !== false
                    && typeof form_obj.member_assets.filter(asset => asset.type == type_cd) === 'object'
                    && typeof form_obj.member_assets.filter(asset => asset.type == type_cd)[sort_key] === 'object'
                ){
                // console.log('get_assets', form_obj.member_assets.filter(asset => asset.type == type_cd));
                    return form_obj.member_assets.filter(asset => asset.type == type_cd)[sort_key]
                }
                return {};
            }
        },
        get_document_label: function(){
            const doc_list = this.member_document_cd_list;
            return function(key){
                if(
                    typeof doc_list.find(doc => doc.key == key) === 'object'
                ){
                    return doc_list.find(doc => doc.key == key).label;
                }
                return key;
            }
        },
        get_pref_label: function(){
            const pref_list = this.pref_list;
            return function(key){
                if(
                    typeof pref_list.find(pref => pref.key == key) === 'object'
                ){
                    return pref_list.find(pref => pref.key == key).label;
                }
                return key;
            }
        },
        get_request_cd_label: function(){
            const lists = this.request_type_list;
            return function(key){
                if(
                    typeof lists.find(list => list.key == key) === 'object'
                ){
                    return lists.find(list => list.key == key).label;
                }
                return key;
            }
        },
        request_type_list: function(){
            return this.request_type_cd_obj;
        },
        pref_list: function(){
            const lists = [...this.pref_obj];
            return lists.sort((a, b) => {
                if(parseInt(a.sort) < parseInt(b.sort)){
                    return -1;
                }else{
                    return 1;
                }
            });
        },
        application_type_list: function(){
            return this.application_type_obj;
        },
        application_check_type_list: function(){
            return this.application_check_type_obj;
        },
        member_document_cd_list: function(){
            return this.member_document_cd_obj;
        },

        request_type_cd_list: function(){
            return this.request_type_cd_obj;
        },
        race_sports_year_list: function(){
            return this.race_sports_year_obj;
        },

        application_filter: function(){
            const app_list = this.application_list;
            return function(app_type, app_chk_list){
                return app_list.filter(app => {
                    if(
                        app.application_type == app_type
                        && app_chk_list.includes(app.application_check_type) !== false
                    ){
                        return true;
                    }
                    return false;
                });
            }
        },
        get_target_application: function(){
            const member_application = this.application_list;
            return function(app_type){
                return member_application.find(app => app.application_type == app_type);
            }
        },
        get_target_application_btn_class: function(){
            const get_func = this.get_target_application;
            const application_btn_class = this.application_btn_class;
            return function(app_type){
                const filtered = get_func(app_type);
                console.log('get_target_application_btn_class', app_type, filtered)
                return application_btn_class(
                    ((filtered || {}).application_check_type)? filtered.application_check_type: false
                );
            }
        },

        get_member_data: function(){
            return {
                name_1: this.data_obj.name_1,
                name_2: this.data_obj.name_2,
                yomi_1: this.data_obj.yomi_1,
                yomi_2: this.data_obj.yomi_2,
                birth_date: this.data_obj.birth_date,
                postal_cd: this.data_obj.birth_date,
                pref_cd: this.data_obj.birth_date,
                address_1: this.data_obj.address_1,
                address_2: this.data_obj.address_2,
                tel_1: this.data_obj.tel_1,
                tel_2: this.data_obj.tel_2,
                mail_1: this.data_obj.mail_1,
                mail_2: this.data_obj.mail_2,
                mail_flg: this.data_obj.mail_flg,
            }
        },
        
        chk_app_checked: function(){
            const checked = this.communication_checked;
            return function(key){
                if(
                    checked.includes(key)
                ){
                    return true;
                }
                return false;
            }
        },
        input_class: function(){
            const page_mode = this.page_mode;
            const checked = this.chk_app_checked;
            return function(key){
                if(
                    page_mode == 'confirm'
                ){
                    return 'form-control-plaintext';
                }
                else if(key != '' && checked(key)){
                    return 'form-control-plaintext';
                }
                else{
                    return 'form-control';
                }
            }
        },
        input_readonly: function(){
            const page_mode = this.page_mode;
            const checked = this.chk_app_checked;
            return function(key){
                if(
                    page_mode == 'confirm'
                ){
                    return true;
                }
                else if(checked(key)){
                    return true;
                }
                else{
                    return false;
                }
            }
        }
    },
    async created () {
        this.message = this.base_message;
        await this.loadCircuitList();
        await this.loadConfigObj('request_type_cd');
        await this.loadConfigObj('identify_type_cd');
        await this.loadConfigObj('member_document_cd');
        await this.loadConfigObj('application_type');
        await this.loadConfigObj('application_check_type');
        await this.loadConfigObj('license_category');
        await this.loadMerchantObj('mfj')
        await this.loadPrefList();
        await this.loadLicenseCdList();
        await this.loadArticle();
        await this.loadArticleVariant();
        await this.loadTermYear();
        await this.loadTermDate();
        await this.loadNowDate();
        await this.loadRaceSportsYear();

        await this.setUserObj();
        await this.loadUserObj();
        await this.setApplicationObj();
        await this.loadApplicationObj();
        this.message = '年度を選択し、ライセンスを選んで申請を進めて下さい。';
    },
    mounted: async function () {
        console.log('mounted !' );
        
    },
    unmounted() {
      this.clear()
    },
    props: {
    }
}
</script>
